/* ------------------------------------------------------------------- */
/* #28b661 (40,182,97)
---------------------------------------------------------------------- */
.numbered.color ol > li::before {
  border: 1px solid #28b661;
  color: #28b661;
}
.numbered.color.filled ol > li::before {
  border: 1px solid #28b661;
  background-color: #28b661;
}
.radio input[type="radio"]:checked + label .radio-label {
  background-color: #28b661;
  border-color: #28b661;
}
.checkbox input:checked + label span.checkbox-icon {
  background-color: #28b661;
  border-color: #28b661;
}
.slider-handle {
  border: 2px solid #28b661;
}
.infoBox-close:hover {
  color: #fff;
  background-color: #28b661;
}
.message-bubble.me .message-text:before {
  border-left: 6px solid #28b661;
}
a,
.header-widget .log-in-button:hover i,
.notification-text span.color,
.header-notifications-content .notification-text span.color,
ul.user-menu-small-nav li a:hover,
ul.user-menu-small-nav li a:hover i,
#navigation ul li:hover a:after,
#navigation ul li a.current,
#navigation ul li a.current:after,
#navigation ul li:hover a,
#breadcrumbs ul li a:hover,
#breadcrumbs.white ul li a:hover,
.single-page-header .header-details li a:hover,
.blog-post-content h3 a:hover,
#posts-nav li a:hover,
.task-tags span,
.freelancer-detail-item a:hover,
.list-4 li:before,
.list-3 li:before,
.list-2 li:before,
.list-1 li:before,
.share-buttons-content span strong,
.keyword,
.banner-headline strong.color,
.category-box-icon,
.recommended .pricing-plan-label,
.recommended .pricing-plan-label strong,
.boxed-widget.summary li.total-costs span,
.testimonial-box:before,
.single-counter h3,
.popup-tabs-nav li.active a,
.dialog-with-tabs .forgot-password:hover,
.dropdown-menu > li > a:hover,
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.bootstrap-select .dropdown-menu li.selected a span.check-mark:before,
.bootstrap-select .dropdown-menu li.selected:hover a span.check-mark:before,
.boxed-list-headline h3 i,
.boxed-list-item .item-details .detail-item a:hover,
.job-overview .job-overview-inner ul li i,
.dashboard-nav ul:before,
.dashboard-nav ul li.active-submenu a i,
.dashboard-nav ul li.active a i,
.dashboard-nav ul li:hover a i,
.dashboard-nav ul li.active-submenu a,
.dashboard-nav ul li:hover a,
.dashboard-nav ul li.active a,
.dashboard-nav ul li.active-submenu a:after,
.dashboard-nav ul li.active a:after,
.dashboard-nav ul li:hover a:after,
.dashboard-box .headline h3 i,
.help-icon,
.header-widget .log-in-button:hover,
.header-widget .log-in-button:hover i {
  color: #28b661;
}
#backtotop a,
.header-notifications-trigger span,
a.header-notifications-button,
#navigation ul ul.dropdown-nav ul.dropdown-nav li:hover a:after,
#navigation ul ul.dropdown-nav li:hover a:after,
.mm-menu em.mm-counter,
.language-switcher.bootstrap-select.btn-group.open button,
.language-switcher.bootstrap-select.btn-group button:hover,
.footer-links ul li a span:before,
.newsletter button,
.pagination ul li a.current-page,
a.blog-post-info:hover,
#posts-nav li a:hover span,
.comment-by a.reply:hover,
.contact-address-headline:after,
.enable-filters-button span,
.enable-filters-button i,
.job-listing.with-apply-button:hover .list-apply-button,
.letters-list a.current,
span.button,
button.button,
input[type="button"],
input[type="submit"],
a.button,
.list-1 li:before,
mark.color,
table.basic-table th,
.copy-url .copy-url-button,
.keyword-input-container .keyword-input-button,
.tags-container input[type="checkbox"]:checked + label,
input:checked + .switch-button,
.radio input[type="radio"] + label .radio-label:after,
.uploadButton .uploadButton-button:hover,
.pricing-plan .button:hover,
.pricing-plan.recommended .button,
.pricing-plan .recommended-badge,
.payment-tab-trigger > input:checked ~ label::after,
.breathing-icon,
.icon-box-check,
.testimonial-author span,
.qtyInc:hover,
.qtyDec:hover,
#sign-in-dialog .mfp-close:hover,
#small-dialog-1 .mfp-close:hover,
#small-dialog-2 .mfp-close:hover,
#small-dialog-3 .mfp-close:hover,
#small-dialog-4 .mfp-close:hover,
#small-dialog .mfp-close:hover,
.slider-selection,
.cluster-visible,
.marker-container,
.custom-zoom-in:hover,
.custom-zoom-out:hover,
#geoLocation:hover,
#streetView:hover,
#scrollEnabling:hover,
#scrollEnabling.enabled,
a.apply-now-button,
div.apply-now-button,
a.attachment-box:hover,
.freelancer-indicators .indicator-bar span,
.dashboard-nav ul li span.nav-tag,
.dashboard-box .button.dark:not(.ico):hover,
.messages-inbox ul li:before,
.message-by h5 i,
.message-bubble.me .message-text {
  background-color: #28b661;
}
.transparent-header #navigation > ul li:hover ul li:hover a:after,
.transparent-header #navigation > ul li:hover a:after,
.transparent-header #navigation > ul li a.current:after,
.compact-list-layout .job-listing:before,
.tasks-list-container.compact-list .task-listing:before,
.compact-list.freelancers-list-layout .freelancer:before,
.accordion-header,
.tabs-header,
.intro-search-field .field-title,
.category-box:hover,
.photo-box:hover:before,
.photo-box.small .photo-box-content span,
.slick-prev:hover,
.slick-next:hover,
.bootstrap-select.btn-group .bs-actionsbox div button:hover {
  background: #28b661;
}
.uploadButton .uploadButton-button,
.pricing-plan .button {
  color: #28b661;
  border: 1px solid #28b661;
}
.payment-tab-trigger > input:checked ~ label::before,
.dashboard-nav ul li.active-submenu,
.dashboard-nav ul li.active,
.dashboard-nav ul li:hover {
  border-color: #28b661;
}

#navigation ul ul.dropdown-nav ul.dropdown-nav li:hover a:after,
#navigation ul ul.dropdown-nav li:hover a:after,
#navigation ul li:hover a:after {
  background: rgba(40, 182, 97, 0.13);
}

#navigation ul li a.current:after {
  background: rgba(40, 182, 97, 0.13);
}
.pagination ul li a.current-page {
  box-shadow: 0 2px 8px rgba(40, 182, 97, 0.25);
}
.sidebar-search-button-container button {
  box-shadow: 0 4px 12px rgba(40, 182, 97, 0.1);
}
.sidebar-search-button-container button:hover {
  box-shadow: 0 4px 12px rgba(40, 182, 97, 0.25);
}
.job-listing.with-apply-button:hover .list-apply-button {
  box-shadow: 0 4px 12px rgba(40, 182, 97, 0.15);
}
.task-listing-bid-inner .button {
  box-shadow: 0 4px 12px rgba(40, 182, 97, 0.15);
}
.task-listing-bid-inner .button:hover {
  box-shadow: 0 4px 12px rgba(40, 182, 97, 0.25);
}
.task-tags span {
  background-color: rgba(40, 182, 97, 0.07);
}
.freelancers-grid-layout .freelancer-details a.button {
  box-shadow: 0 4px 12px rgba(40, 182, 97, 0.1);
}
.freelancer-details a.button:hover {
  box-shadow: 0 4px 12px rgba(40, 182, 97, 0.2);
}
.freelancers-list-layout .freelancer-details a.button {
  box-shadow: 0 4px 12px rgba(40, 182, 97, 0.15);
}
.freelancers-list-layout .freelancer-details a.button:hover {
  box-shadow: 0 4px 12px rgba(40, 182, 97, 0.25);
}
.letters-list a.current {
  box-shadow: 0 2px 8px rgba(40, 182, 97, 0.25);
}
span.button,
button.button,
input[type="button"],
input[type="submit"],
a.button {
  box-shadow: 0 4px 12px rgba(40, 182, 97, 0.15);
}
.move-on-hover {
  box-shadow: 0 4px 12px rgba(40, 182, 97, 0.1);
}
.move-on-hover:hover {
  box-shadow: 0 4px 12px rgba(40, 182, 97, 0.2);
}
.keyword {
  background-color: rgba(40, 182, 97, 0.07);
}
@keyframes switch-shadow-color {
  0% {
    box-shadow: 0 0 0 0 rgba(40, 182, 97, 0.6);
  }
  100% {
    box-shadow: 0 0 0px 10px rgba(0, 0, 0, 0);
  }
}
@keyframes radius-shadow {
  0% {
    box-shadow: 0 0 0 0 rgba(40, 182, 97, 0.4);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
}
.uploadButton .uploadButton-button {
  box-shadow: 0 3px 10px rgba(40, 182, 97, 0.1);
}
.uploadButton .uploadButton-button:hover {
  box-shadow: 0 4px 12px rgba(40, 182, 97, 0.15);
}
.category-box:hover {
  box-shadow: 0 4px 12px rgba(40, 182, 97, 0.2);
}
.photo-box:hover {
  box-shadow: 0 4px 12px rgba(40, 182, 97, 0.25);
}
.recommended .pricing-plan-label {
  background-color: rgba(40, 182, 97, 0.06);
}
.pricing-plan .button:hover,
.pricing-plan.recommended .button {
  box-shadow: 0 4px 12px rgba(40, 182, 97, 0.15);
}
.pricing-plan .button {
  box-shadow: 0 4px 12px rgba(40, 182, 97, 0.1);
}
.pricing-plan .button:hover {
  box-shadow: 0 4px 12px rgba(40, 182, 97, 0.15);
}
.breathing-icon {
  box-shadow: 0 0 0 15px rgba(40, 182, 97, 0.07);
}
@keyframes bicokAnim {
  0%,
  100% {
    box-shadow: 0 0 0 15px rgba(40, 182, 97, 0.07);
  }
  50% {
    box-shadow: 0 0 0 28px rgba(40, 182, 97, 0.12);
  }
}
.icon-box-check {
  box-shadow: 0 0 0 5px rgba(40, 182, 97, 0.1);
}
@keyframes checkAnim {
  0%,
  100% {
    box-shadow: 0 0 0 5px rgba(40, 182, 97, 0.15);
  }
  50% {
    box-shadow: 0 0 0 8px rgba(40, 182, 97, 0.15);
  }
}
.testimonial-author span {
  box-shadow: 0 2px 10px rgba(40, 182, 97, 0.25);
}
.bidding-widget .button {
  box-shadow: 0 4px 12px rgba(40, 182, 97, 0.15);
}
.bidding-widget .button:hover {
  box-shadow: 0 4px 12px rgba(40, 182, 97, 0.25);
}
.dialog-with-tabs .button {
  box-shadow: 0 4px 12px rgba(40, 182, 97, 0.15);
}
.slider-handle:after {
  box-shadow: 0 0 0px 6px rgba(40, 182, 97, 0.12);
}
.slider-handle:hover:after {
  box-shadow: 0 0 0px 8px rgba(40, 182, 97, 0.12);
}
.dropdown-menu > li > a:hover,
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover {
  background-color: rgba(40, 182, 97, 0.07);
}
@keyframes clusterAnimation {
  0%,
  100% {
    box-shadow: 0 0 0 6px rgba(40, 182, 97, 0.15);
  }
  50% {
    box-shadow: 0 0 0 10px rgba(40, 182, 97, 0.15);
  }
}
@keyframes markerAnimation {
  0%,
  100% {
    box-shadow: 0 0 0 6px rgba(40, 182, 97, 0.15);
  }
  50% {
    box-shadow: 0 0 0 8px rgba(40, 182, 97, 0.15);
  }
}
a.apply-now-button {
  box-shadow: 0 2px 8px rgba(40, 182, 97, 0.15);
}
a.apply-now-button:hover {
  box-shadow: 0 2px 8px rgba(40, 182, 97, 0.35);
}
.dashboard-nav ul li.active-submenu,
.dashboard-nav ul li.active,
.dashboard-nav ul li:hover {
  background-color: rgba(40, 182, 97, 0.04);
}
.dashboard-nav ul li.active-submenu a:after,
.dashboard-nav ul li.active a:after,
.dashboard-nav ul li:hover a:after {
  background: rgba(40, 182, 97, 0.13);
}
.dashboard-box-list .button {
  box-shadow: 0 3px 8px rgba(40, 182, 97, 0.15);
}
.dashboard-box .button.dark:not(.ico):hover {
  box-shadow: 0 3px 8px rgba(40, 182, 97, 0.15);
}

#navigation ul ul.dropdown-nav ul.dropdown-nav li:hover a:after,
#navigation ul ul.dropdown-nav li:hover a:after {
  background-color: #28b661;
}

.leaflet-container a.leaflet-popup-close-button:hover,
.leaflet-control-zoom-in:hover,
.leaflet-control-zoom-out:hover,
.marker-cluster-small div,
.marker-cluster-medium div,
.marker-cluster-large div {
  background-color: #28b661;
}

.marker-cluster div:before {
  border-color: #28b661;
  box-shadow: inset 0 0 0 4px #28b661;
}
