@charset "UTF-8";

/*
* Author: Vasterad
* URL: http://purethemes.net
*/

/* -------------------------------------------------------------- */
/* Table of Contents
-----------------------------------------------------------------

01. Import Section ----------------------------------------------

02. Layout Components -------------------------------------------
  # Forms Reset & Styles
  # Header
  # Header Notifications
  # User Menu
  # Navigation
  # Mega Menu
  # Sticky Header
  # Transparent Header
  # mmenu.js - mobile menu
  # Footer
  # Pagination
  # Sidebar Styles
  # Notify Box
  # Titlebar
  # Single Page Header
  # Blog Post
  # Contact Page

03. Listings Styles ---------------------------------------------
  # Full Width Page Layout
  # Job Listing - List Layout
  # Job Listing - Compact List Layout
  # Job Listing - Grid Layout
  # Tasks - List Layout
  # Tasks - Grid Layout
  # Freelancers - Grid Layout
  # Freelancers - List Layout
  # Browse Companies

04. User Interface Elements -------------------------------------
  # Buttons
  # Button with Sliding Icon
  # List Styles
  # Marks
  # Notification Boxes
  # Tables
  # Accordion
  # Tabs
  # Copy to clipboard
  # Share Buttons
  # Verified Badge
  # Star Rating
  # Leave Rating Stars
  # Ripple Effect
  # Input With Icon
  # Keywords Input
  # Clickable Tags
  # On/Off Switch
  # Radio Button
  # Checkbox
  # Custom Upload Button
  # Intro Banner
  # Category Boxes
  # Photo Box
  # Pricing Plans
  # Checkout Page
  # Icon Boxes
  # Testimonials
  # Text Banner
  # Counters
  # Countdown
  # Bidding Widget

05. Scripts Styles ----------------------------------------------
  # Slick Slider
  # Simlpebar.js (custom scrollbar)
  # Tippy.js Tooltips
  # Magnific Popup
  # Popup tabs
  # Boostrap Slider
  # Bootstrap-select
  # Snackbar
  # Google Autocomplete
  # Google Maps
  # Leaflet Maps

06. Single Pages ------------------------------------------------
  # Single Job Page
  # Single Freelancer Profile

07. Dashboard ---------------------------------------------------
  # Dashboard Navigation
  # Dashboard Elements
  # Dashboard Messages

08. Others ------------------------------------------------------
  # Common Styles
  # Icons Cheatsheet Styles
  # Offsets

*/

/* -------------------------------------------------------------- */
/* 01. Import Section
----------------------------------------------------------------- */
@import url("./bootstrap-grid.css"); /* Default Reset, Responsive Grid*/
@import url("./icons.css"); /* Default Reset, Responsive Grid*/
@import url("https://fonts.googleapis.com/css?family=Nunito:300,400,600,700,800&subset=latin-ext");

/* -------------------------------------------------------------- */
/*  02. Layout Components
----------------------------------------------------------------- */

/* ---------------------------------- */
/* Forms Reset & Styles
------------------------------------- */
input,
input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"],
textarea,
select {
  height: 48px;
  line-height: 48px;
  padding: 0 20px;
  outline: none;
  font-size: 16px;
  color: #808080;
  margin: 0 0 16px 0;
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
  display: block;
  background-color: #fff;
  font-weight: 500;
  opacity: 1;
  border-radius: 4px;
  border: none;
  box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.12);
}

select {
  padding: 15px 18px;
  cursor: pointer;
}

input {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

input:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
textarea:focus {
  color: #808080;
  transition: box-shadow 0.2s !important;
  opacity: 1;
  border: none;
  box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.12);
}

input:-webkit-autofill:focus,
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #fff inset !important;
  -webkit-text-fill-color: #808080 !important;
}

/* With Border */
input.with-border,
input[type="text"].with-border,
input[type="password"].with-border,
input[type="email"].with-border,
input[type="number"].with-border,
textarea.with-border,
select.with-border {
  border: 1px solid #e0e0e0;
  box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.05);
}
input.with-border:focus,
input[type="text"].with-border:focus,
input[type="password"].with-border:focus,
input[type="email"].with-border:focus,
input[type="number"].with-border:focus,
textarea.with-border:focus {
  border: 1px solid #e0e0e0;
  box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.08);
}

input[type="submit"] {
  border: none;
  padding: 11px 18px;
  width: auto;
}

input[type="checkbox"] {
  display: inline;
}

input[type="radio"] {
  width: 15px;
  height: 15px;
  cursor: pointer;
  box-shadow: none;
}

/* Input Placeholder Color */
::placeholder {
  color: #808080;
  opacity: 1;
}

textarea {
  height: auto;
  line-height: 27px;
  padding: 20px;
  min-height: 130px;
  transition: none !important;
  min-width: 100%;
}

label,
legend {
  display: block;
  font-weight: bold;
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 8px;
}

label span,
legend span {
  font-weight: normal;
  font-size: 14px;
  color: #444;
}

fieldset {
  padding: 0;
  border: none;
}

button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  cursor: pointer;
}

a {
  color: #66676b;
  transition: 0.3s;
}
a,
button {
  outline: none !important;
}
a:focus,
a:hover {
  text-decoration: none;
  color: #333;
}
img {
  max-width: 100%;
}

/* ---------------------------------- */
/* Header
------------------------------------- */
#logo {
  display: inline-block;
  border-right: 0px solid #e0e0e0;
  height: 100%;
  vertical-align: top;
  padding-right: 35px;
  margin-right: 20px;
}

#logo img {
  height: 95px;
  width: auto;
  top: 50%;
  position: relative;
  transform: translate3d(0, -50%, 0);
}

#header {
  position: relative;
  z-index: 999;
  padding: 0;
  box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.12);
  font-size: 16px;
  height: 82px;
  background-color: #fff;
}

#header .container,
#header .right-side,
#header .left-side {
  height: 100%;
}

/* Full Width Header */
header.fullwidth .container {
  max-width: 100%;
  margin: 0;
  padding: 0 35px;
}

/* Boxed Styles*/
#header .container {
  position: relative;
}

#header .left-side {
  float: left;
  width: 100%;
  display: inline-block;
  position: relative;
  flex: 1;
}

#header .right-side {
  float: right;
  text-align: right;
  width: auto;
  display: inline-block;
  position: absolute;
  right: 25px;
  background-color: #fff;
  flex: 1;
  top: 0;
}

/* Full Width Styles */
.full-width #header .left-side {
  float: left;
  width: 100%;
  display: inline-block;
  position: relative;
}

.full-width #header .right-side {
  float: right;
  text-align: right;
  width: auto;
  display: inline-block;
  position: absolute;
  right: 25px;
  background-color: #fff;
}

/* Header Widget
------------------------------------- */
.header-widget {
  display: inline-block;
  height: 100%;
  border-left: 1px solid #e0e0e0;
  padding: 0 30px;
  text-align: left;
  float: left;
}

.fullwidth .header-widget:last-of-type {
  padding-right: 5px;
  float: right;
  border-right: none;
}

.header-widget:last-of-type {
  padding-right: 0;
  margin-right: -5px;
}
.fullwidth .header-widget:last-of-type {
  padding-right: 5px;
  margin-right: 0;
}

/* Notification Avatar*/
.header-notifications-content .notification-avatar {
  height: 42px;
  width: 42px;
  max-width: 42px;
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;
}

/* Sign In Button */
.header-widget .log-in-button {
  padding: 10px 0;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.3s;
  color: #666;
  display: block;
  margin: 0 -3px;
}

.header-widget .log-in-button i {
  font-size: 18px;
  line-height: 0;
  position: relative;
  top: 2px;
  margin-right: 4px;
  margin-left: -1px;
  color: #777;
  transition: 0.3s;
}

.header-widget .log-in-button:hover,
.header-widget .log-in-button:hover i {
  color: #66676b;
}

.transparent-header .header-widget .log-in-button,
.transparent-header .header-widget .log-in-button i {
  color: #fff;
}

/* Sign In Button Media Queries */
@media (max-width: 768px) {
  .header-widget .log-in-button span {
    display: none;
  }

  .header-widget .log-in-button i {
    font-size: 20px;
    top: 3px;
  }

  .header-widget .log-in-button i,
  .header-widget .log-in-button i:hover {
    color: #555;
  }

  .header-widget .log-in-button {
    background-color: #f0f0f0;
    border-radius: 4px;
    height: 46px;
    width: 46px;
    text-align: center;
  }

  .transparent-header .header-widget .log-in-button i,
  .transparent-header .header-widget .log-in-button {
    background-color: #fff;
    color: #333;
  }
}

/* ---------------------------------- */
/* Header Notifications
------------------------------------- */
.header-notifications {
  height: 100%;
  display: inline-block;
  padding-right: 15px;
  position: relative;
}

.header-notifications:last-child {
  margin-right: -15px;
}

.header-notifications-trigger,
.header-notifications-trigger a {
  font-size: 22px;
  color: #333;
  position: relative;
  top: 55%;
  transform: translate3d(0, -50%, 0);
}

/* IE 11 Fixes */
@media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
  .header-notifications-trigger,
  .header-notifications-trigger a {
    height: 40px;
  }
  .header-notifications.user-menu {
    top: -8px;
  }
}
/* IE 11 Fixes - End */

.header-notifications-trigger span {
  position: absolute;
  display: inline-block;
  top: -15px;
  margin-left: -10px;
  font-weight: 700;
  height: 19px;
  width: 19px;
  line-height: 19px;
  text-align: center;
  color: #fff;
  font-size: 11px;
  background-color: #66676b;
  border-radius: 50%;
  position: relative;
}

/* Header User Menu Dropdown */
.header-notifications-dropdown {
  width: 360px;
  border-radius: 4px;
  /*	box-shadow: 0px 0 8px 0px rgba(0,0,0,0.10);*/
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  padding: 0;
  position: absolute;
  top: calc(100% - 10px);
  right: -15px;
  text-align: left;
  z-index: 100;
  transform: scale(0.95);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  pointer-events: none;
}

.header-notifications.active .header-notifications-dropdown {
  transform: scale(1);
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.header-notifications-dropdown:before {
  content: "";
  position: absolute;
  right: 43px;
  top: -6px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #d7d7d7;

  /* Arrow with shadow */
  /*	top: 0;
	right: 33px;
    border: 5px solid black;
    border-color: transparent transparent #fff #fff;
    transform-origin: 0 0;
    transform: rotate(135deg);
    box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.05);*/
}

/* Headline */
.header-notifications-headline {
  padding: 17px 25px;
  padding-top: 18px;
  line-height: 24px;
  border-bottom: 1px solid #e6e6e6;
}

.header-notifications-headline h4,
.header-notifications-headline span {
  display: inline-block;
}

button.mark-as-read {
  float: right;
  position: relative;
  border-radius: 4px;
  height: 32px;
  width: 32px;
  text-align: center;
  line-height: 36px;
  top: -4px;
  right: -8px;
  color: #888;
  transition: 0.25s;
}

button.mark-as-read:hover {
  background-color: #f2f2f2;
  color: #888;
}

/* Content */
.header-notifications-content {
  display: block;
  width: 100%;
}

.header-notifications-scroll {
  display: block;
  overflow: hidden;
  height: auto;
}

.header-notifications-content ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.header-notifications-content ul li {
  border-bottom: 1px solid #e6e6e6;
  transition: 0.25s;
}

.header-notifications-content ul li:hover {
  background-color: #fafafa;
}
.header-notifications-content ul li:hover .notification-icon {
  background-color: #eee;
}
.header-notifications-content ul li:last-child {
  border-bottom: none;
}

.header-notifications-content ul li a {
  color: #666;
  position: relative;
  padding: 22px 25px;
  display: block;
}

.header-notifications-content p {
  margin: 0;
  padding: 0;
}

.header-notifications-content ul li a {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.header-notifications-content .notification-icon,
.header-notifications-content .notification-text {
  flex: 1;
}

/* Notification Icon */
.header-notifications-content .notification-icon i {
  position: relative;
}

.notification-icon,
.header-notifications-content .notification-icon {
  font-size: 24px;
  height: 40px;
  width: 40px;
  max-width: 40px;
  display: inline-block;
  background-color: #f2f2f2;
  border-radius: 4px;
  line-height: 42px;
  text-align: center;
  color: #a0a0a0;
  transition: 0.25s;
  position: relative;
}

/* Adjustments for some icons :( */
.notification-icon .icon-material-outline-autorenew {
  font-size: 22px;
}
.notification-icon .icon-material-outline-gavel {
  font-size: 19px;
  top: -2px;
  left: 1px;
}

/* Notification Avatar*/
.header-notifications-content .notification-avatar {
  height: 42px;
  width: 42px;
  max-width: 42px;
  display: inline-block;
  border-radius: 50%;
  overflow: visible;
  align-self: flex-start;
}

.header-notifications-content .notification-avatar img {
  width: 100%;
  image-rendering: -webkit-optimize-contrast;
  border-radius: 50%;
}

/* Notification Texts */
.notification-text,
.header-notifications-content .notification-text {
  padding-left: 20px;
  padding-right: 5px;
  display: inline-block;
  line-height: 23px;
  font-size: 14.7px;
}

.header-notifications-content .notification-msg-text {
  display: inline-block;
  line-height: 23px;
  font-size: 14.7px;
  margin: 4px 0;
}

.notification-text strong,
.header-notifications-content .notification-text strong {
  font-weight: 600;
  color: #333;
}

.notification-text span.color,
.header-notifications-content .notification-text span.color {
  color: #66676b;
  display: inline-block;
}

.notification-text span.color {
  display: block;
}

/* Button */
a.header-notifications-button {
  border-radius: 0 0 4px 4px;
  font-weight: 600;
  color: #fff;
  font-size: 14.7px;
  padding: 12px 16px;
  background-color: #66676b;
  width: 100%;
  display: block;
  text-align: center;
  transition: 0.3s;
}

a.header-notifications-button i {
  font-size: 22px;
  line-height: 0;
  top: 4px;
  position: relative;
  max-width: 0;
  display: inline-block;
  margin-left: 7px;
}

/* ---------------------------------- */
/* User Menu
------------------------------------- */
.user-menu {
  padding: 0;
}

.header-notifications.user-menu {
  margin: 0 -5px;
}

.user-menu .header-notifications-trigger,
.user-menu .header-notifications-trigger a {
  top: 50%;
}

.user-menu .header-notifications-dropdown {
  top: calc(100% - 8px);
  width: 290px;
  right: -10px;
}

.user-menu .header-notifications-dropdown:before {
  right: 23px;
}

/* User Status Dot */
.notification-avatar {
  position: relative;
}

.status-icon,
.notification-avatar:after,
.user-avatar:after {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  background-color: #c0c0c0;
  bottom: 0;
  right: 0;
  display: block;
  border: 2px solid #fff;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
}

.notification-avatar:after {
  width: 11px;
  height: 11px;
}

/* Online */
.status-icon.status-online,
.status-online:after {
  background-color: #38b653;
}

/* Offline */
.status-icon.status-offline,
.status-offline:after {
  background-color: #c0c0c0;
}

/* Avatar */
.user-avatar {
  display: inline-block;
  border-radius: 50%;
  width: 42px;
  height: 42px;
  position: relative;
}

.user-avatar img {
  width: 100%;
  display: inline-block;
  border-radius: 50%;
  image-rendering: -webkit-optimize-contrast;
}

/* User Menu Small Nav */
ul.user-menu-small-nav {
  list-style: none;
  padding: 0;
  margin: 0;
  padding: 20px 25px;
}

ul.user-menu-small-nav li {
  margin: 0;
  padding: 0;
}

ul.user-menu-small-nav li a {
  display: block;
  padding: 2px 0;
  font-size: 16px;
  color: #666;
  transition: 0.3s;
}

ul.user-menu-small-nav li a i {
  font-size: 16px;
  position: relative;
  top: 1px;
  margin-right: 2px;
  color: #666;
  -webkit-font-smoothing: antialiased;
  transition: 0.3s;
}

ul.user-menu-small-nav li a:hover,
ul.user-menu-small-nav li a:hover i {
  color: #66676b;
}

/* User Status Section*/
.user-status {
  padding: 25px;
  border-bottom: 1px solid #e6e6e6;
}

/* User Menu - Details */
.user-details {
  display: flex;
}

.user-name {
  font-weight: 500;
  color: #333;
  line-height: 20px;
  padding: 2px 0 0 15px;
}

.user-details span {
  display: block;
  font-size: 14.7px;
  color: #888;
  font-weight: 500;
}

/* User Status Switch */
.status-switch {
  background-color: #eee;
  width: 100%;
  display: block;
  border-radius: 4px;
  height: 36px;
  padding: 0;
  position: relative;
  zoom: 1;
  margin-top: 20px;
}

.status-switch:before,
.status-switch:after {
  content: " ";
  display: table;
}

.status-switch label {
  float: left;
  width: 50%;
  position: relative;
  z-index: 2;
  line-height: 36px;
  cursor: pointer;
  color: #888;
  font-size: 14.7px;
  text-align: center;
  transition: 0.3s;
  margin: 0;
}

.status-switch span.status-indicator {
  position: relative;
  z-index: 1;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  display: block;
  border-radius: 4px 0 0 4px;
  background-color: #38b653;
  box-shadow: 0 0 5px 1px rgba(56, 182, 83, 0.3);
  transition: all 0.4s cubic-bezier(0.3, -0.41, 0.19, 1.3),
    background-color 0.2s linear;
}

.status-switch span.right {
  left: 50%;
  border-radius: 0 4px 4px 0;
  background-color: #333;
  box-shadow: none;
  color: #fff;
}

.status-switch label.current-status {
  color: #fff;
}

/* ---------------------------------- */
/* Navigation
------------------------------------- */
#navigation {
  position: relative;
  display: inline-block;
  margin-top: 22px;
}

#navigation ul {
  list-style: none;
  position: relative;
  float: left;
  margin: 0;
  padding: 0;
}

#navigation ul a {
  display: block;
  text-decoration: none;
  padding: 5px 0;
}

#navigation ul li {
  position: relative;
  float: left;
  margin: 0;
  padding: 0;
}

#navigation ul li ul {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 100%;
  left: 9px;
  padding: 0;
  z-index: 99999;
}

#navigation ul ul li {
  float: none;
}

#navigation ul ul ul {
  top: 0;
  left: 100%;
  margin: 0 0 0 15px;
}

#navigation ul ul {
  margin: 0;
}

#navigation ul ul ul {
  pointer-events: none;
}
#navigation ul ul li:hover > ul {
  pointer-events: all;
}

#navigation ul ul:before {
  content: "";
  position: absolute;
  left: 0;
  top: -10px;
  background: rgba(255, 255, 255, 0);
  width: 100%;
  height: 10px;
}

#navigation ul ul ul:after {
  content: "";
  position: absolute;
  width: 15px;
  height: 100%;
  left: -15px;
  top: 0;
  opacity: 0;
}

#navigation ul li:hover > ul {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

/* Indicator Arrow */
#navigation ul li a:after,
#navigation ul ul li a:after {
  font-family: "Material-Icons";
  content: "\e914";
  opacity: 1;
  font-size: 16px;
  transition: 0.2s;
  opacity: 1;
  line-height: 17px;
  width: 17px;
  height: 17px;
  background-color: #f0f0f0;
  color: #a8a8a8;
  display: inline-block;
  border-radius: 3px;
  margin-left: 7px;
  text-indent: 0;
  top: 1px;
  position: relative;
}

#navigation ul ul.dropdown-nav ul.dropdown-nav li:hover a:after,
#navigation ul ul.dropdown-nav li:hover a:after,
#navigation ul li:hover a:after {
  color: #66676b;
  background: rgba(102, 103, 107, 0.13);
  opacity: 0.8;
}

#navigation ul ul.dropdown-nav ul.dropdown-nav li a:after {
  background-color: #f0f0f0;
  color: #a8a8a8;
  opacity: 1;
}

/* Dropdown Indicator */
#navigation ul ul.dropdown-nav ul.dropdown-nav li:hover a:after,
#navigation ul ul.dropdown-nav li:hover a:after {
  color: #fff !important;
  background-color: #66676b;
  opacity: 1;
}

#navigation ul ul.dropdown-nav ul.dropdown-nav li a:after,
#navigation ul ul.dropdown-nav li a:after {
  content: "\e917";
  position: absolute;
  left: auto;
  right: 20px;
  top: 6px;
  background-color: rgba(255, 255, 255, 0.1);
  color: #bbb;
  opacity: 1;
}

/* Current Indicator */
#navigation ul li a.current {
  color: #66676b;
}
#navigation ul li a.current:after {
  color: #66676b;
  background: rgba(102, 103, 107, 0.13);
  opacity: 0.8;
}

#navigation ul li a:only-child:after,
#navigation ul ul li a:only-child:after {
  content: "";
  display: none;
}

#navigation ul ul li:hover a:after {
  opacity: 1;
  transform: translateX(0);
}

#navigation ul li {
  transition: all 0.25s;
  margin: 0 5px;
  border-radius: 4px;
}

#navigation ul li a,
#navigation ul ul li a {
  color: #666;
  cursor: pointer;
}

#navigation ul a {
  line-height: 23px;
  padding: 7px 9px;
  border-right: 4px;
}

#navigation ul li:hover a {
  color: #66676b;
}

/* Dropdown Styles
------------------------------------- */
#navigation ul ul {
  background-color: #303030;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

#navigation ul ul li {
  border-radius: 4px;
}
#navigation ul ul li:last-child {
  border-bottom: none;
}

#navigation ul li:hover ul a,
#navigation ul ul a {
  padding: 4px 40px 4px 15px !important;
  line-height: 22px !important;
}

#navigation ul li:hover ul a:only-child,
#navigation ul a:only-child {
  padding: 4px 15px 4px 15px !important;
}

#navigation ul ul:after {
  content: "";
  position: absolute;
  left: 25px;
  top: -5px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #303030;
}

body #navigation ul ul {
  padding: 14px 0;
  box-sizing: border-box;
  margin-top: 12px;
}

body #navigation ul ul ul {
  margin-top: 0;
}

#navigation ul ul:before {
  content: ".";
  position: absolute;
  width: 100%;
  height: 12px;
  top: -12px;
  opacity: 0;
  opacity: 0;
}

#navigation ul ul ul:before {
  width: calc(100% + 15px);
  left: -15px;
}

#navigation ul ul li {
  width: 208px;
}

#navigation ul ul li:hover {
  border-radius: 0;
}

#navigation ul ul li:first-child {
  border-radius: 4px 4px 0 0;
}
#navigation ul ul li:last-child {
  border-radius: 0 0 4px 4px;
}

#navigation ul ul {
  opacity: 0;
  visibility: hidden;
  transition: all 0.25s;
  transform: translate3d(0, 15px, 0);
}

#navigation ul ul ul {
  transform: translate3d(15px, 0, 0);
}

#navigation ul li ul {
  z-index: 10;
}
#navigation ul li:hover ul {
  z-index: 20;
}

#navigation ul li:hover > ul {
  opacity: 1;
  visibility: visible;
  transform: translate3d(0, 0, 0);
}

#navigation ul ul li {
  margin: 0;
  padding: 0 6px;
  border-radius: 0;
  font-size: 15.7px;
}

#navigation ul ul.dropdown-nav ul.dropdown-nav ul.dropdown-nav li a,
#navigation ul ul.dropdown-nav ul.dropdown-nav li a,
#navigation ul ul.dropdown-nav li a {
  transition: 0.3s;
  color: #aaa;
}

#navigation ul ul.dropdown-nav ul.dropdown-nav ul.dropdown-nav li:hover a,
#navigation ul ul.dropdown-nav ul.dropdown-nav li:hover a,
#navigation ul ul.dropdown-nav li:hover a {
  color: #fff;
}

/* ---------------------------------- */
/* Mega Menu
------------------------------------- */
.mega-menu {
  opacity: 0;
  visibility: hidden;
  transition: all 0.25s;
  position: absolute;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  font-size: 15px;
  display: flex;
  top: 100%;
  left: 0;
  padding: 20px 0;
  box-sizing: border-box;
  font-size: 14px;
  margin-top: -10px;
  background-color: #303030;
  width: 200px;
  z-index: 99;
  transform: translate3d(-42%, 36px, 0);
}

.mega-menu:before {
  content: "";
  position: absolute;
  top: -12px;
  left: 0;
  display: block;
  width: 100%;
  height: 12px;
  opacity: 0;
}

#navigation ul li .mega-menu ul {
  opacity: 0;
  visibility: hidden;
  transform: translate3d(0, 0, 0);
  position: relative;
  display: inline-block;
  flex: 1;
  padding: 0;
  margin: 0;
  box-shadow: none;
  border-radius: 0;
  top: auto;
  left: auto;
  background-color: transparent;
}

#navigation ul li:hover .mega-menu {
  opacity: 1;
  visibility: visible;
  transform: translate3d(-42%, 23px, 0);
}

#navigation ul li:hover .mega-menu ul {
  opacity: 1;
  visibility: visible;
}

#navigation .mega-menu ul,
#navigation .mega-menu ul li {
  width: 100%;
}

.mega-menu.two-columns {
  width: 429px;
}
.mega-menu.three-columns {
  width: 643px;
}
.mega-menu.four-columns {
  width: 857px;
}

#navigation ul .mega-menu ul a {
  color: #bbb !important;
  padding: 0 !important;
  margin: 0 !important;
  display: block;
  margin-left: 0;
  -wekkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

#navigation ul .mega-menu ul a:hover {
  color: #fff !important;
}

#navigation ul li:hover .mega-menu ul a,
#navigation ul .mega-menu ul a {
  padding: 4px 0 !important;
  line-height: 22px !important;
  transform: translate3d(0, 0, 0) !important;
}

#navigation .mega-menu ul li {
  padding: 0 26px;
}

#navigation .mega-menu:after {
  content: "";
  position: absolute;
  left: calc(50% - 10px);
  top: -5px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #303030;
}

#navigation ul li a i {
  font-size: 14px;
  position: relative;
  top: 0;
  padding-right: 5px;
}

.mega-menu-section {
  display: inline-block;
  float: left;
  width: 214px;
  padding: 0;
  border-right: 1px solid #444;
}

.mega-menu .mega-menu-section:last-child {
  border: none;
}

#navigation ul .mega-menu ul ul:after {
  display: none;
}

.mega-menu-headline {
  color: #fff;
  opacity: 1 !important;
  font-weight: 600;
  display: block;
  padding: 8px 30px !important;
  letter-spacing: 0;
  font-size: 14px;
  line-height: 21px;
}

/* Some mega menu styles for mobile nav */
.mobile-styles .mm-listview {
  margin: 0px -20px !important;
}
.mobile-styles .mm-listview .mega-menu-headline {
  color: #fff;
  padding: 15px 20px !important;
  background: #222 !important;
}
.mobile-styles .mm-listview li a i {
  padding-right: 5px;
}
.mobile-styles.mm-panel:before {
  height: 10px !important;
}

/* Flickering Fix */
#navigation ul li .mega-menu ul,
#navigation ul li ul {
  z-index: 100;
}
#navigation ul li:hover .mega-menu,
#navigation ul li:hover ul {
  z-index: 200;
}

/* ---------------------------------- */
/* Sticky Header
------------------------------------- */
#header-container {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1000;
}

#wrapper.wrapper-with-transparent-header #header .right-side {
  background-color: transparent;
}
#header {
  transition: background 0.3s;
}
#wrapper {
  padding-top: 82px;
}
#wrapper.wrapper-with-transparent-header {
  padding-top: 0 !important;
}

@media (max-width: 1099px) {
  #wrapper {
    padding-top: 0 !important;
  }
  #header-container {
    position: fixed !important;
  }
  #header-container.transparent-header {
    position: absolute;
  }
}

/* ---------------------------------- */
/* Transparent Header
------------------------------------- */
#header-container.transparent-header {
  position: absolute;
  width: 100%;
}

.transparent-header-spacer {
  height: 82px;
}

.transparent-header #header {
  background-color: transparent;
  box-shadow: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.transparent-header #header .right-side {
  background-color: transparent;
}

.transparent-header #logo,
.transparent-header #header .right-side .header-widget {
  border-color: rgba(255, 255, 255, 0.2);
}
.transparent-header #header .right-side .header-widget:first-of-type {
  border-right-color: transparent;
}
.transparent-header #header .right-side .header-widget:last-of-type {
  border-color: rgba(255, 255, 255, 0.2);
}

body .transparent-header #navigation > ul li a,
.transparent-header .header-notifications-trigger,
.transparent-header .header-notifications-trigger a {
  color: #fff;
}

body .transparent-header #navigation > ul ul li a {
  color: #aaa;
}

.transparent-header .header-notifications-dropdown:before {
  border-bottom-color: #fff;
}

.transparent-header .mmenu-trigger {
  background-color: #fff;
}

.transparent-header #navigation > ul li a:after {
  background-color: rgba(255, 255, 255, 0.15);
  color: rgba(255, 255, 255, 0.8);
  opacity: 1;
  transition: 0.3s;
}

.transparent-header #navigation > ul li:hover ul li:hover a:after,
.transparent-header #navigation > ul li:hover a:after,
.transparent-header #navigation > ul li a.current:after {
  color: #fff;
  background: #66676b;
}

.transparent-header #navigation > ul li:hover ul a:after,
.transparent-header #navigation > ul ul li a:after {
  background-color: rgba(255, 255, 255, 0.1);
  color: #bbb;
}

/* ---------------------------------- */
/* mmenu.js - mobile menu
------------------------------------- */
.mm-hidden {
  display: none !important;
}

.mm-wrapper {
  overflow-x: hidden;
  position: relative;
}

.mm-menu {
  box-sizing: border-box;
  background: inherit;
  display: block;
  padding: 0;
  margin: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
}

.mm-panels,
.mm-panels > .mm-panel {
  background: inherit;
  border-color: inherit;
  box-sizing: border-box;
  margin: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
}

.mm-panels {
  overflow: hidden;
}

.mm-panel {
  -webkit-transform: translate(100%, 0);
  -ms-transform: translate(100%, 0);
  transform: translate(100%, 0);
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  -webkit-transition: -webkit-transform 0.4s ease;
  transition: -webkit-transform 0.4s ease;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease;
  -webkit-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left;
}

.mm-panel.mm-opened {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mm-panel.mm-subopened {
  -webkit-transform: translate(-30%, 0);
  -ms-transform: translate(-30%, 0);
  transform: translate(-30%, 0);
  -webkit-transform: translate3d(-30%, 0, 0);
  transform: translate3d(-30%, 0, 0);
}

.mm-panel.mm-highest {
  z-index: 1;
}

.mm-panel.mm-noanimation {
  -webkit-transition: none !important;
  transition: none !important;
}

.mm-panel.mm-noanimation.mm-subopened {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mm-panels > .mm-panel {
  -webkit-overflow-scrolling: touch;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 20px;
}

.mm-panels > .mm-panel.mm-hasnavbar {
  padding-top: 40px;
}

.mm-panels > .mm-panel:not(.mm-hidden) {
  display: block;
}

.mm-panels > .mm-panel:after,
.mm-panels > .mm-panel:before {
  content: "";
  display: block;
  height: 20px;
}

.mm-vertical .mm-panel {
  -webkit-transform: none !important;
  -ms-transform: none !important;
  transform: none !important;
}

.mm-listview .mm-vertical .mm-panel,
.mm-vertical .mm-listview .mm-panel {
  display: none;
  padding: 10px 0 10px 10px;
}

.mm-listview .mm-vertical .mm-panel .mm-listview > li:last-child:after,
.mm-vertical .mm-listview .mm-panel .mm-listview > li:last-child:after {
  border-color: transparent;
}

.mm-vertical li.mm-opened > .mm-panel,
li.mm-vertical.mm-opened > .mm-panel {
  display: block;
}

.mm-listview > li.mm-vertical > .mm-next,
.mm-vertical .mm-listview > li > .mm-next {
  box-sizing: border-box;
  height: 40px;
  bottom: auto;
}

.mm-listview > li.mm-vertical.mm-opened > .mm-next:after,
.mm-vertical .mm-listview > li.mm-opened > .mm-next:after {
  -webkit-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  transform: rotate(225deg);
  right: 19px;
}

.mm-btn {
  box-sizing: border-box;
  width: 40px;
  height: 50px;
  position: absolute;
  top: 0;
  z-index: 1;
}

.mm-clear:after,
.mm-clear:before,
.mm-close:after,
.mm-close:before {
  content: "";
  border: 2px solid transparent;
  display: block;
  width: 5px;
  height: 5px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.mm-clear:before,
.mm-close:before {
  border-right: none;
  border-bottom: none;
  right: 18px;
}

.mm-clear:after,
.mm-close:after {
  border-left: none;
  border-top: none;
  right: 25px;
}

.mm-next:after,
.mm-prev:before {
  content: "";
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  display: block;
  width: 8px;
  height: 8px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
}

.mm-prev:before {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  left: 23px;
  right: auto;
}

.mm-next:after {
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
  right: 23px;
  left: auto;
}

.mm-navbar {
  border-bottom: 1px solid;
  border-color: inherit;
  text-align: center;
  line-height: 30px;
  height: 50px;
  padding: 0 40px;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.mm-navbar > * {
  display: block;
  padding: 10px 0;
}

.mm-navbar a,
.mm-navbar a:hover {
  text-decoration: none;
}

.mm-navbar .mm-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.mm-navbar .mm-btn:first-child {
  left: 0;
}

.mm-navbar .mm-btn:last-child {
  text-align: right;
  right: 0;
}

.mm-panel .mm-navbar {
  display: none;
}

.mm-panel.mm-hasnavbar .mm-navbar {
  display: block;
  background-color: #262626;
}

.mm-listview,
.mm-listview > li {
  list-style: none;
  display: block;
  padding: 0;
  margin: 0;
}

.mm-listview {
  font: inherit;
  font-size: 14px;
  line-height: 20px;
}

.mm-listview a,
.mm-listview a:hover {
  text-decoration: none;
}

.mm-listview > li {
  position: relative;
}

.mm-listview > li,
.mm-listview > li .mm-next,
.mm-listview > li .mm-next:before,
.mm-listview > li:after {
  border-color: inherit;
}

.mm-listview > li {
  border-bottom: 1px solid #404040;
}

.mm-listview > li > a,
.mm-listview > li > span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: inherit;
  display: block;
  padding: 14px 10px 14px 20px;
  margin: 0;
}

.mm-listview > li:not(.mm-divider):after {
  content: "";
  border-bottom-width: 1px;
  border-bottom-style: solid;
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
}

.mm-listview > li:not(.mm-divider):after {
  left: 20px;
}

.mm-listview .mm-next {
  background: rgba(3, 2, 1, 0);
  width: 50px;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
}

.mm-listview .mm-next:before {
  content: "";
  border-left-width: 1px;
  border-left-style: solid;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.mm-listview .mm-next + a,
.mm-listview .mm-next + span {
  margin-right: 50px;
}

.mm-listview .mm-next.mm-fullsubopen {
  width: 100%;
}

.mm-listview .mm-next.mm-fullsubopen:before {
  border-left: none;
}

.mm-listview .mm-next.mm-fullsubopen + a,
.mm-listview .mm-next.mm-fullsubopen + span {
  padding-right: 50px;
  margin-right: 0;
}

.mm-panels > .mm-panel > .mm-listview {
  margin: 20px -20px;
}

.mm-panels > .mm-panel > .mm-listview:first-child,
.mm-panels > .mm-panel > .mm-navbar + .mm-listview {
  margin-top: -10px;
}

.mm-menu {
  background: #2a2a2a;
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}

.mm-menu .mm-navbar a,
.mm-menu .mm-navbar > * {
  color: #fff;
  font-weight: 600;
  font-size: 16px;
}

.mm-menu .mm-btn:after,
.mm-menu .mm-btn:before {
  border-color: rgba(255, 255, 255, 0.8);
}

.mm-menu .mm-listview {
  border-color: #2a2a2a;
}

.mm-menu .mm-listview > li .mm-next:after {
  border-color: rgba(255, 255, 255, 0.8);
}

.mm-menu .mm-listview > li a:not(.mm-next) {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0.5);
  tap-highlight-color: rgba(255, 255, 255, 0.5);
}

.mm-menu .mm-listview > li.mm-selected > a:not(.mm-next),
.mm-menu .mm-listview > li.mm-selected > span {
  background: rgba(255, 255, 255, 0.05);
}

.mm-menu .mm-listview > li {
  transition: 0.2s;
}
.mm-menu .mm-listview > li:hover {
  background: rgba(255, 255, 255, 0.03);
}

.mm-menu .mm-listview > li.mm-opened.mm-vertical > .mm-panel,
.mm-menu .mm-listview > li.mm-opened.mm-vertical > a.mm-next,
.mm-menu.mm-vertical .mm-listview > li.mm-opened > .mm-panel,
.mm-menu.mm-vertical .mm-listview > li.mm-opened > a.mm-next {
  background: rgba(0, 0, 0, 0.05);
}

.mm-menu .mm-divider {
  background: rgba(0, 0, 0, 0.05);
}

.mm-page {
  box-sizing: border-box;
  position: relative;
}

.mm-slideout {
  -webkit-transition: -webkit-transform 0.4s ease;
  transition: -webkit-transform 0.4s ease;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease;
  z-index: 1;
}

html.mm-opened {
  overflow-x: hidden;
  position: relative;
}

html.mm-blocking {
  overflow: hidden;
}

html.mm-blocking body {
  overflow: hidden;
}

html.mm-background .mm-page {
  background: inherit;
}

#mm-blocker {
  background: rgba(3, 2, 1, 0);
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
}

html.mm-blocking #mm-blocker {
  display: block;
}

.mm-menu.mm-offcanvas {
  z-index: 0;
  display: none;
  position: fixed;
}

.mm-menu.mm-offcanvas.mm-opened {
  display: block;
}

.mm-menu.mm-offcanvas.mm-no-csstransforms.mm-opened {
  z-index: 10;
}

.mm-menu.mm-offcanvas {
  width: 80%;
  min-width: 140px;
  max-width: 440px;
}

html.mm-opening .mm-menu.mm-opened ~ .mm-slideout {
  -webkit-transform: translate(80%, 0);
  -ms-transform: translate(80%, 0);
  transform: translate(80%, 0);
  -webkit-transform: translate3d(80%, 0, 0);
  transform: translate3d(80%, 0, 0);
}

@media all and (max-width: 175px) {
  html.mm-opening .mm-menu.mm-opened ~ .mm-slideout {
    -webkit-transform: translate(140px, 0);
    -ms-transform: translate(140px, 0);
    transform: translate(140px, 0);
    -webkit-transform: translate3d(140px, 0, 0);
    transform: translate3d(140px, 0, 0);
  }
}

@media all and (min-width: 550px) {
  html.mm-opening .mm-menu.mm-opened ~ .mm-slideout {
    -webkit-transform: translate(440px, 0);
    -ms-transform: translate(440px, 0);
    transform: translate(440px, 0);
    -webkit-transform: translate3d(440px, 0, 0);
    transform: translate3d(440px, 0, 0);
  }
}

.mm-sronly {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  position: absolute !important;
}

em.mm-counter {
  font: inherit;
  font-size: 14px;
  font-style: normal;
  text-indent: 0;
  line-height: 20px;
  display: block;
  margin-top: -10px;
  position: absolute;
  right: 45px;
  top: 50%;
}

em.mm-counter + a.mm-next {
  width: 90px;
}

em.mm-counter + a.mm-next + a,
em.mm-counter + a.mm-next + span {
  margin-right: 90px;
}

em.mm-counter + a.mm-fullsubopen {
  padding-left: 0;
}

em.mm-counter + a.mm-fullsubopen + a,
em.mm-counter + a.mm-fullsubopen + span {
  padding-right: 90px;
}

.mm-listview em.mm-counter + .mm-next.mm-fullsubopen + a,
.mm-listview em.mm-counter + .mm-next.mm-fullsubopen + span {
  padding-right: 90px;
}

.mm-vertical > .mm-counter {
  top: 12px;
  margin-top: 0;
}

.mm-vertical.mm-spacer > .mm-counter {
  margin-top: 40px;
}

.mm-nosubresults > .mm-counter {
  display: none;
}

.mm-menu em.mm-counter {
  background-color: rgba(0, 0, 0, 0.3);
  height: 20px;
  width: 20px;
  text-align: center;
  border-radius: 50%;
  color: #fff;
  line-height: 21px;
  font-size: 10px;
  font-weight: 600;
}

.mm-menu em.mm-counter {
  background-color: #66676b;
}

/* Trigger Styles */
.mmenu-trigger {
  height: 46px;
  width: 46px;
  display: none;
  position: relative;
  margin: 0;
  background-color: #eee;
  border-radius: 4px;
  cursor: pointer;
}

/* Hamburger Icon */
.hamburger {
  padding: 0;
  top: 16px;
  left: 12px;
  transform: scale(0.67);
  -moz-transform: scale(0.7) translateY(4px);
  position: relative;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}

.hamburger-box {
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 33px;
  height: 4px;
  background-color: #555;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger-inner::before,
.hamburger-inner::after {
  content: "";
  display: block;
}

.hamburger-inner::before {
  top: -10px;
}
.hamburger-inner::after {
  bottom: -10px;
}

/* Animation */
.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse .hamburger-inner::after {
  top: -20px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
    opacity 0.1s linear;
}

.hamburger--collapse .hamburger-inner::before {
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
    transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--collapse.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
    opacity 0.1s 0.22s linear;
}

.hamburger--collapse.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-90deg);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
    transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.mmenu-trigger {
  display: none;
}

/* Media Queries Menu */
@media (max-width: 1099px) {
  /* Header Responsive Styles */
  #responsive {
    display: none;
  }
  .mmenu-trigger {
    display: inline-block !important;
  }

  #header {
    height: 76px;
  }

  #logo {
    border: none;
  }
  #logo img {
    border: none;
    max-width: 240px;
    height: auto;
  }

  header.fullwidth .container {
    padding: 0 20px;
  }

  .header-widget {
    float: left;
  }

  .fullwidth .header-widget:last-of-type,
  .header-widget:last-of-type {
    padding-right: 25px;
    float: left;
  }

  #header .right-side {
    position: absolute;
    width: auto;
    background-color: #fff;
    text-align: left;
  }

  .mmenu-trigger {
    margin: 0 -5px 0 23px;
    top: 15px;
    float: right;
  }

  #header .right-side .header-widget {
    border-right: 1px solid #e0e0e0;
    margin-right: -1px;
  }

  .header-widget {
    padding: 0 25px;
  }
  .header-notifications:first-child {
    margin-right: -5px;
  }

  /* #header-container.cloned {
    display: none;
  } */
}

@media (max-width: 768px) {
  .header-notifications {
    position: initial;
  }

  .header-notifications-dropdown:before {
    display: none;
  }
  .user-menu .header-notifications-dropdown,
  .header-notifications-dropdown {
    width: calc(100vw - 45px);
    right: -10px;
    top: calc(100% + 15px);
  }
}

@media (max-width: 480px) {
  .hide-on-mobile {
    display: none;
  }
}

/* ---------------------------------- */
/* Footer
------------------------------------- */
#footer {
  background-color: #303030;
  color: #c0c0c0;
}

#footer p {
  line-height: 26px;
}

.footer-top-section {
  border-bottom: 1px solid #484848;
}

.footer-top-section .footer-logo img {
  max-height: 46px;
}

.footer-rows-container {
  display: block;
  height: 96px;
  position: relative;
  z-index: 100;
}

.footer-row {
  display: inline-block;
  padding: 0 40px;
  border-right: 1px solid #484848;
  border-left: 1px solid #484848;
  float: left;
  height: 100%;
}

.footer-row-inner {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.footer-row-inner.footer-logo {
  position: relative;
  top: auto;
  transform: translateY(0);
  top: 50%;
  position: relative;
  height: 46px;
}

.footer-row-inner.footer-logo img {
  position: relative;
  top: 50%;
}

.footer-row:first-child {
  padding-left: 0;
  border-left: none;
}
.footer-row:last-child {
  padding-right: 0;
  border: none;
}

.footer-rows-right .footer-row:first-child {
  padding-left: 40px;
  border-left: 1px solid #484848;
}

.footer-rows-right {
  float: right;
  height: 100%;
}

/* Language Switcher */
.language-switcher.bootstrap-select.btn-group .dropdown-menu.inner {
  width: 100%;
}

.language-switcher.bootstrap-select:before {
  display: none;
}

.language-switcher.bootstrap-select .dropdown-menu {
  padding-top: 15px;
  top: calc(100% + 15px);
  width: 160px;
  left: auto;
  right: 0;
}

.language-switcher.bootstrap-select .dropdown-menu:before {
  position: absolute;
  top: -5px;
  content: "";
  right: 20px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #fff;
}

.language-switcher.bootstrap-select.btn-group button {
  height: 44px;
  padding-right: 35px;
  padding-left: 18px;
  color: #fff;
  background-color: #444;
  transition: 0.3s;
  box-shadow: none;
}

.language-switcher.bootstrap-select.btn-group .dropdown-toggle .filter-option {
  height: 44px;
  line-height: 44px;
}

.language-switcher.bootstrap-select.btn-group button:hover {
  background-color: #fff;
  color: #333;
}

.language-switcher.bootstrap-select.btn-group.open button,
.language-switcher.bootstrap-select.btn-group button:hover {
  background-color: #66676b;
  color: #fff;
}

.language-switcher.bootstrap-select.btn-group button .caret {
  transition: 0.3s;
}

.language-switcher.bootstrap-select.btn-group.open button .caret,
.language-switcher.bootstrap-select.btn-group button:hover .caret {
  border-top-color: #fff;
}

.language-switcher.bootstrap-select.btn-group .dropdown-toggle .caret {
  right: 18px;
}

/* Social Icons */
.footer-social-links {
  list-style: none;
  margin: 0 -10px;
  margin-top: 3px;
  padding: 0;
}

.footer-social-links li {
  display: inline-block;
  padding: 0;
  margin: 0;
  float: left;
}

.footer-social-links li a {
  font-size: 20px;
  padding: 0 10px;
  color: #fff;
  display: inline-block;
  transition: 0.3s;
  opacity: 0.5;
  transform: translate3d(0, -0.5px, 0);
}

.footer-social-links li a:hover {
  opacity: 1;
}

/* Middle Section */
.footer-middle-section {
  padding: 60px 0;
  position: relative;
  z-index: 10;
}

#footer h3,
#footer h2,
.footer-links h3 {
  font-size: 18px;
  color: #fff;
  margin: 0 0 15px 0;
  padding: 0;
  display: block;
}

#footer h3 i {
  position: relative;
  margin-right: 5px;
  top: 2px;
  font-size: 20px;
  font-weight: 400 !important;
}
.footer-links ul {
  list-style: none;
  text-align: left;
  margin: 0;
  padding: 0;
}

.footer-links ul li {
  margin-bottom: 0;
  display: block;
}

.footer-links ul li:first-child a {
  padding-top: 0;
}
.footer-links ul li:last-child a {
  padding-bottom: 0;
}

.footer-links ul li a {
  color: #c0c0c0;
  display: block;
  line-height: 25px;
  position: relative;
  z-index: 100;
  padding: 4px 0;
}

.footer-links ul li a span {
  display: inline-block;
  position: relative;
}

.footer-links ul li a:hover {
  color: #fff;
}

.footer-links ul li a span:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: #66676b;
  height: 2px;
  display: block;
  transition: all 0.25s, opacity 0.2s;
  z-index: -1;
  border-radius: 3px;
  opacity: 0.5;
  width: 0;
}

.footer-links ul li a:hover span:before {
  width: 100%;
  opacity: 1;
}

/* Newsletter */
.newsletter {
  display: flex;
  margin-top: 20px;
}

.newsletter input,
.newsletter button {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.newsletter input {
  background: #262626;
  box-shadow: none;
  margin-bottom: 0;
}

.newsletter input:focus {
  box-shadow: none;
}

.newsletter button {
  background-color: #66676b;
  border-radius: 4px;
  height: 48px;
  min-width: 48px;
  max-width: 48px;
  text-align: center;
  line-height: 48px;
  font-size: 18px;
  color: #fff;
  margin-left: 10px;
  transition: 0.3s;
}

.newsletter button:hover {
  background-color: #fff;
  color: #333;
}

.newsletter button i {
  width: 100%;
  line-height: 48px;
}

/* Copyrights */
.footer-bottom-section {
  padding: 25px 0;
  border-top: 1px solid #484848;
  text-align: center;
}

.footer-bottom-section strong {
  color: #fff;
  font-weight: 500;
}

/* Footer Media Queries*/
@media (max-width: 992px) {
  #footer h3 {
    margin-top: 45px;
  }
  .footer-middle-section {
    padding-top: 0;
    padding-bottom: 45px;
  }
}

@media (max-width: 768px) {
  .content-left-offset {
    padding-left: 15px;
  }
  .content-right-offset {
    padding-left: 15px;
  }

  .footer-rows-container {
    height: auto;
    padding: 20px 0 40px 0;
  }

  .footer-rows-left,
  .footer-rows-right {
    float: none;
    height: auto;
  }

  .footer-row-inner.footer-logo img {
    top: 0;
  }

  .footer-row-inner {
    position: relative;
    top: 0;
    transform: translateY(0);
  }

  .footer-row-inner.footer-logo {
    top: 0;
    position: relative;
    height: 46px;
  }

  #footer .footer-row {
    padding: 0;
    float: none;
    padding: 20px 0 0 0;
    display: block;
    width: 100%;
    border: none;
  }

  .footer-rows-container .footer-rows-left,
  .footer-rows-container .footer-rows-right {
    display: block;
  }
}

/* ---------------------------------- */
/* Pagination
------------------------------------- */
.pagination {
  margin: 0;
  text-align: center;
}

.pagination-next-prev {
  position: relative;
  top: -66px;
}

.pagination ul {
  margin: 0;
  padding: 0;
}

.pagination ul li {
  display: inline-block;
  margin: 0;
  padding: 0;
}

.pagination ul li a,
.pagination-next-prev ul li a {
  padding: 10px 0;
  border-bottom: none;
  display: inline-block;
  color: #333;
  background-color: transparent;
  font-weight: 700;
  margin: 0;
  line-height: 22px;
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  -ms-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
  font-size: 14px;
  float: left;
}

.pagination ul li a {
  border-radius: 4px;
  width: 44px;
  height: 44px;
  padding: 0;
  line-height: 44px;
}

.pagination ul li a i {
  line-height: 44px;
  font-size: 24px;
}

.pagination ul li.blank {
  color: #a0a0a0;
  padding: 0 6px;
}

.section.gray .pagination .pagination-arrow a:hover,
.pagination ul li a.current-page,
.pagination ul li a:hover {
  background-color: #333;
  color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.pagination ul li a.current-page {
  background-color: #66676b;
  color: #fff;
  box-shadow: 0 2px 8px rgba(102, 103, 107, 0.25);
}

.pagination .pagination-arrow a {
  background-color: #f0f0f0;
}
.section.gray .pagination .pagination-arrow a {
  background-color: #eaeaea;
}

/* ---------------------------------- */
/* Back to top
------------------------------------- */
#backtotop {
  position: fixed;
  right: 0;
  opacity: 0;
  visibility: hidden;
  bottom: 25px;
  margin: 0 25px 0 0;
  z-index: 999;
  transition: 0.35s;
  transform: translateY(10px);
}
#backtotop.visible {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
#backtotop a {
  text-decoration: none;
  border: 0 none;
  display: block;
  width: 46px;
  height: 46px;
  background-color: #66676b;
  opacity: 1;
  transition: all 0.3s;
  border-radius: 4px;
  text-align: center;
  font-size: 26px;
}

body #backtotop a {
  color: #fff;
}

#backtotop a:after {
  content: "";
  font-family: "Feather-Icons";
  position: relative;
  display: block;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media (max-width: 768px) {
  #backtotop {
    display: none;
  }
}

/* ---------------------------------- */
/* Sidebar Styles
------------------------------------- */
.sidebar-container {
  margin-bottom: 40px;
}
.full-page-container .sidebar-container {
  margin-bottom: 0px;
}

.sidebar-widget input {
  margin-bottom: 0;
}

.sidebar-widget {
  margin-bottom: 50px;
  display: block;
}

.sidebar-widget h3 {
  font-size: 20px;
  margin-bottom: 20px;
}

/* ---------------------------------- */
/* Notify Box
------------------------------------- */
.notify-box {
  display: block;
  width: 100%;
  background-color: #f2f2f2;
  border-radius: 4px;
  padding: 15px 25px;
  position: relative;
  line-height: 28px;
  min-height: 59px;
}

.notify-box label {
  margin-bottom: 0;
  cursor: pointer;
}

.notify-box .switch-button {
  margin-right: 10px;
}

.sort-by .bootstrap-select {
  position: relative;
  right: 0;
  top: 0;
}

.sort-by .dropdown-menu {
  box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.14);
}

.sort-by .bootstrap-select.btn-group button {
  flex: 1;
  position: relative;
  right: 0;
  top: 4px;
  font-weight: 600;
  margin-left: 10px;
  width: auto;
  padding: 0;
  padding-right: 12px;
  background-color: transparent;
  box-shadow: none;
  height: 20px;
  color: #333;
}

.sort-by
  .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: auto;
}

.sort-by .bootstrap-select.btn-group .dropdown-toggle .caret {
  right: 0;
}

.sort-by .bootstrap-select .dropdown-menu {
  padding-top: 15px;
  top: 40px;
  right: 0;
  left: auto;
  position: absolute;
  min-width: 160px !important;
  flex: 1;
}

.sort-by .bootstrap-select.open:before {
  display: none;
}

.sort-by {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  position: relative;
  float: right;
}

/* Notify Box Media Queries*/
@media (max-width: 768px) {
  .notify-box {
    margin-bottom: 90px;
  }
  .notify-box .sort-by {
    left: 0;
  }
  .notify-box .sort-by .bootstrap-select .dropdown-menu {
    left: 0;
    right: auto;
  }
}

/* ---------------------------------- */
/* Titlebar
------------------------------------- */
#titlebar {
  background-color: #f8f8f8;
  position: relative;
  padding: 70px 0;
  margin-bottom: 65px;
}

#titlebar.gradient {
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.03) 0%,
    rgba(255, 255, 255, 0)
  );
  padding-bottom: 0;
}

#titlebar.white {
  background: #fff;
  padding-bottom: 0;
}

#titlebar.transparent {
  background: transparent;
}

#titlebar span a,
#titlebar span {
  font-size: 20px;
  color: #888;
  margin-bottom: 3px;
  margin-top: 3px;
  display: block;
}

#titlebar #breadcrumbs span {
  font-size: 14px;
}

#titlebar h1,
#titlebar h2 {
  font-size: 30px;
  line-height: 40px;
  margin: 1px 0 3px 0;
}

/* Breadcrumbs */
#breadcrumbs {
  position: absolute;
  right: 15px;
  display: inline-block;
  font-size: 14.7px;
  top: 50%;
  transform: translateY(-51%);
  border-radius: 4px;
  font-weight: 600;
  color: #333;
  background-color: #f0f0f0;
}

#breadcrumbs ul {
  margin: 0;
  padding: 12px 22px;
  line-height: 23px;
}

#breadcrumbs ul li a {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  color: #666;
  font-weight: 500;
}

#breadcrumbs ul li a:hover {
  color: #66676b;
}

#breadcrumbs ul li {
  display: inline-block;
  list-style: none;
  margin: 0 0 0 7px;
}

#breadcrumbs ul li:first-child:before {
  display: none;
}
#breadcrumbs ul li:first-child {
  margin-left: 0;
}

#breadcrumbs ul li:before {
  content: "";
  display: inline-block;
  height: 0;
  width: 0;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 4px solid #aaa;
  font-size: 14px;
  margin-right: 12px;
}

#breadcrumbs ul li.home-icon a {
  opacity: 1;
  font-size: 24px;
  top: 4px;
  position: relative;
  line-height: 0;
  padding: 0;
  display: inline-block;
}

#breadcrumbs ul li.home-icon i {
  line-height: 0;
}

/* Dark Style */
#breadcrumbs.dark {
  color: rgba(255, 255, 255, 1);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
  background-color: #333;
}

#breadcrumbs.dark ul li a,
#breadcrumbs.dark ul li a:hover {
  color: rgba(255, 255, 255, 1);
}

#breadcrumbs.dark ul li:before {
  border-left: 4px solid rgba(255, 255, 255, 0.3);
}

/* White Style */
#breadcrumbs.white {
  color: #333;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

#breadcrumbs.white ul li a {
  color: #666;
}
#breadcrumbs.white ul li a:hover {
  color: #66676b;
}

#breadcrumbs.white ul li:before {
  border-left: 4px solid rgba(0, 0, 0, 0.3);
}

/* Adjustments for "Single Page Header" */
.single-page-header #breadcrumbs {
  position: relative;
  top: 0;
  left: 0;
  transform: none;
  margin-left: auto;
}

@media (max-width: 992px) {
  .single-page-header #breadcrumbs {
    margin: 30px 0 20px 0;
  }
}

/* ---------------------------------- */
/* Single Page Header
------------------------------------- */
.single-page-header {
  margin-bottom: 65px;
  padding: 60px 0;
  position: relative;
}

.single-page-header .container {
  z-index: 100;
  position: relative;
}

.single-page-header:after,
.single-page-header:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  display: block;
  top: 0;
  left: 0;
  z-index: 15;
  background: linear-gradient(
    to right,
    rgba(247, 247, 247, 1) 50%,
    rgba(247, 247, 247, 0.8) 70%,
    rgba(247, 247, 247, 0.8) 90%
  );
}

.single-page-header:after {
  z-index: 5;
  background-color: #f7f7f7;
}

.single-page-header .background-image-container {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 100% 50%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 50%;
  left: 50%;
  z-index: 10;
}

.single-page-header .single-page-header-inner {
  display: flex;
}

.single-page-header .left-side,
.single-page-header .right-side {
  flex: 1;
  display: flex;
  align-items: center;
}

.single-page-header .left-side {
  padding-right: 30px;
}

.single-page-header .right-side {
  max-width: 35%;
}

@media (min-width: 1365px) {
  .single-page-header .right-side {
    max-width: 30%;
  }
}

/* Image */
.single-page-header .header-image a {
  display: flex;
  height: 100%;
}

.single-page-header .header-image {
  height: 140px;
  flex: 0 0 140px;
  margin-right: 35px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  padding: 0 20px;
}

/* IE 11 Fixes */
@media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
  .single-page-header .header-image {
    max-width: 140px;
  }
}

.single-page-header.freelancer-header .header-image {
  background: #fff;
  border-radius: 50%;
  box-shadow: none;
  display: flex;
  padding: 0;
  overflow: hidden;
  height: 130px;
  flex: 0 0 130px;
  width: 130px;
}

.single-page-header .header-image img {
  align-self: center;
  transform: translate3d(0, 0, 0);
}

/* IE 11 FIX */
@media all and (-ms-high-contrast: none) {
  .single-page-header .header-image img {
    width: 100%;
  }
}

/* Details */
.single-page-header .header-details {
  flex: 1;
}

.single-page-header .header-details h3 {
  color: #333;
  font-size: 26px;
  margin: 0;
  padding: 0;
  margin-bottom: 12px;
  margin-top: 3px;
  line-height: 36px;
  position: relative;
  padding-right: 0;
  display: inline-block;
}

.single-page-header .header-details h3 span {
  color: #808080;
  display: block;
  font-size: 20px;
  font-weight: 300;
  margin-top: -3px;
}

.single-page-header .header-details h5 {
  font-size: 16px;
  font-weight: 600;
  color: #333;
}

/* Details */
.single-page-header .header-details h5 {
  font-size: 16px;
  font-weight: 600;
  color: #333;
}

.single-page-header .header-details ul {
  padding: 0;
  margin: 0;
  list-style: none;
  line-height: 24px;
  margin-bottom: -7px;
}

.single-page-header .header-details li {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 12px;
}

.single-page-header .header-details li:last-child {
  margin-right: 0;
}

.single-page-header .header-details li a {
  color: #666;
}
.single-page-header .header-details li a:hover {
  color: #66676b;
}

.single-page-header .header-details li i {
  font-size: 20px;
  position: relative;
  top: 2px;
  margin-right: 2px;
}

.single-page-header .header-details li .verified-badge {
  transform: scale(0.85);
  position: relative;
  top: 2px;
}

.single-page-header .header-details li .verified-txt {
  color: #299840;
}

.single-page-header .header-details li .star-rating {
  position: relative;
  top: 6px;
}

.single-page-header .header-details ul li img.flag {
  height: 16px;
  border-radius: 3px;
  position: relative;
  top: -1px;
  display: inline-block;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  margin-right: 5px;
  cursor: default;
}

/* Salary Box */
.single-page-header .salary-box {
  border-radius: 4px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.08);
  background-color: #fff;
  display: inline-block;
  padding: 20px 25px;
  margin-left: auto;
}

.salary-box .salary-type {
  color: #888;
  line-height: 20px;
  margin: 3px 0 8px 0;
}

.salary-box .salary-amount {
  color: #333;
  font-size: 28px;
  line-height: 36px;
  font-weight: 300;
}

/* Media Queries for Single Page Header */
@media (max-width: 1366px) {
  .single-page-header.freelancer-header .header-image {
    height: 130px;
    flex: 0 0 130px;
    width: 130px;
  }
}

@media (max-width: 1240px) {
  .single-page-header .header-details h3 {
    font-size: 26px;
  }
  .single-page-header {
    padding: 55px 0;
  }
  .salary-box .salary-amount {
    font-size: 26px;
    line-height: 32px;
  }
  .single-page-header .header-image {
    height: 120px;
    flex: 0 0 120px;
  }

  .single-page-header.freelancer-header .header-image {
    height: 120px;
    flex: 0 0 120px;
    width: 120px;
  }
}

@media (max-width: 992px) {
  .single-page-header .single-page-header-inner {
    display: flex;
    flex-direction: column;
  }
  .single-page-header .left-side {
    padding-right: 0;
  }
  .single-page-header .header-details h3 {
    font-size: 24px;
  }
  .single-page-header {
    padding: 35px 0;
  }
  .single-page-header .salary-amount {
    font-size: 22px;
    line-height: 24px;
  }
  .single-page-header .salary-box {
    padding: 0;
    margin-top: 15px;
    margin-bottom: 20px;
    background-color: transparent;
    box-shadow: none;
  }
  .salary-box .salary-type {
    font-weight: 600;
    color: #333;
  }
  .single-page-header .left-side,
  .single-page-header .right-side,
  .single-page-header-inner {
    display: block;
    max-width: 100%;
  }
  .single-page-header .header-image {
    height: 70px;
    width: auto;
    padding: 15px 20px;
    margin: 15px 0;
    display: inline-block;
  }
  .single-page-header .header-image img {
    height: 100%;
  }

  .single-page-header .header-details h5 {
    border-top: 1px solid #e0e0e0;
    padding-top: 25px;
  }

  .single-page-header.freelancer-header .header-image {
    height: 70px;
    flex: 0 0 70px;
    width: 70px;
    margin-bottom: -70px;
  }
  .single-page-header.freelancer-header .header-details h3 {
    margin-left: 90px;
  }
}

@media (max-width: 768px) {
  .single-page-header:after,
  .single-page-header:before {
    background: #f7f7f7;
  }

  .single-page-header.freelancer-header .header-image {
    height: 70px;
    flex: 0 0 70px;
    width: 70px;
    margin-bottom: -70px;
  }
  .single-page-header.freelancer-header .header-details h3 {
    margin-left: 90px;
  }
}

/* ---------------------------------- */
/* Blog Post
------------------------------------- */
.blog-post {
  display: flex;
  background: #fff;
  margin-bottom: 35px;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
  transition: 0.3s;
}

.blog-post:hover {
  box-shadow: 0 4px 18px rgba(0, 0, 0, 0.1);
  transform: translateY(-5px);
}

.blog-post-thumbnail {
  flex: 0 0 auto;
  max-width: none;
  max-height: 100%;
  overflow: hidden;
  position: relative;
  width: 260px;
}

.blog-post-thumbnail-inner {
  height: 100%;
  width: 100%;
  overflow: hidden;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15);
  transition: 0.3s;
}

.blog-post-thumbnail-inner img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.blog-post-thumbnail-inner .blog-item-tag {
  bottom: 20px;
  left: 20px;
  top: auto;
  margin: 0;
}

.blog-post-content {
  flex: 1;
  padding: 35px;
}

.blog-post-content h3,
.blog-post-content h3 a {
  font-size: 22px;
  line-height: 33px;
  color: #333;
  margin-bottom: 15px;
  display: block;
}

.blog-post-content h3 a:hover {
  color: #66676b;
}

a.blog-post-info,
.blog-post-info,
.blog-post-date {
  font-size: 14px;
  color: #888;
  margin-bottom: 10px;
  display: inline-block;
  background-color: #f0f0f0;
  border-radius: 4px;
  line-height: 20px;
  padding: 4px 10px;
  margin-right: 3px;
  transition: 0.3s;
}

a.blog-post-info:hover {
  color: #fff;
  background-color: #66676b;
}

.blog-post-content p {
  padding: 0;
  margin: 0;
  display: block;
  color: #808080;
}

.blog-post-content a.button i.icon-feather-arrow-right {
  font-size: 20px;
  top: 4px;
  left: -1px;
  position: relative;
  margin: 0 -5px;
}

.blog-post-info-list {
  display: block;
}

blockquote {
  padding: 10px 20px;
  margin: 0 20px;
  font-size: 17px;
  line-height: 30px;
  color: #888;
  border-left: 5px solid #eee;
}

/* Single Post Styles */
.blog-post.single-post {
  flex-direction: column;
}
.blog-post.single-post:hover {
  transform: none;
}
.blog-post.single-post .blog-post-thumbnail {
  width: 100%;
  height: 380px;
}
.blog-post.single-post p {
  margin-bottom: 20px;
}

/* Post Navigation */
#posts-nav {
  padding: 0;
  list-style: none;
  width: 100%;
  position: relative;
  margin: 0;
  display: inline-block;
  min-height: 60px;
}

#posts-nav li span {
  display: block;
  font-size: 14px;
  color: #888;
  margin-bottom: 10px;
  display: inline-block;
  background-color: #f0f0f0;
  border-radius: 4px;
  line-height: 20px;
  padding: 4px 10px;
  margin-right: 3px;
  transition: 0.3s;
}

#posts-nav li a:hover span {
  background-color: #66676b;
  color: #fff;
}

#posts-nav li {
  position: absolute;
  font-size: 18px;
  font-weight: 500;
  width: 50%;
}

#posts-nav li strong {
  display: block;
  font-weight: 500;
}

#posts-nav li a {
  transition: transform 0.3s, color 0.3s 0s;
  color: #333;
  display: inline-block;
  line-height: 28px;
}

#posts-nav li a:hover {
  color: #66676b;
}

#posts-nav li.next-post {
  right: 0;
  text-align: right;
}

#posts-nav li.prev-post {
  left: 0;
}

#posts-nav li.next-post a {
  padding-right: 50px;
}
#posts-nav li.prev-post a {
  padding-left: 50px;
}

#posts-nav li.prev-post a:before,
#posts-nav li.next-post a:before {
  font-family: "Feather-Icons";
  font-size: 30px;
  position: absolute;
  color: inherit;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  transition: transform 0.3s;
}

#posts-nav li.next-post a:before {
  right: 0;
  content: "\e930";
}

#posts-nav li.prev-post a:before {
  left: 0;
  content: "\e92f";
}

#posts-nav li.next-post a:hover:before {
  transform: translate3d(5px, -50%, 0);
}
#posts-nav li.prev-post a:hover:before {
  transform: translate3d(-5px, -50%, 0);
}

/* Comments */
.comments {
  margin: -10px 0 0 0;
}

.comments h4 {
  margin-bottom: 25px;
  font-size: 20px;
  font-weight: 500;
  color: #333;
}

.comments h4 span {
  display: inline-block;
  font-size: inherit;
}

.comment {
  font-size: 20px;
}

.comments .button {
  margin: 0 0 10px 0;
  padding: 7px 15px;
}

.comments.reviews .button {
  margin: 0;
}

.comments ul {
  padding-left: 0;
}
.comments ul li {
  display: block;
}

.comments ul li,
.comments ul li ul li,
.comments ul li ul li ul li,
.comments ul li ul li ul li {
  margin: 50px 0 0 0px;
}

.comments ul li ul {
  margin: 0 0 0 100px;
}

.comment-content p {
  margin: 3px 0 0 0;
  line-height: 26px;
}

.comment-content {
  color: #666;
  padding: 0 0 0 100px;
}

.comments ul li ul {
  border-left: 1px solid #e0e0e0;
  padding-left: 35px;
}

.comments ul li ul li:before {
  content: "";
  width: 15px;
  height: 1px;
  background-color: #e0e0e0;
  display: inline-block;
  position: absolute;
  top: 30px;
  left: -35px;
}

.comments ul li {
  position: relative;
}

.comment-content strong {
  padding-right: 5px;
  color: #666;
}

.comment-content span {
  color: #888;
}
body .comment-content p {
  padding: 5px 0;
}

.comments-amount {
  color: #888;
  font-weight: 500;
}

.avatar {
  display: inline-block;
  float: left;
  padding: 0 17px 0 0;
  position: absolute;
  left: 0px;
  top: -5px;
}

.avatar img {
  max-width: 70px;
  border-radius: 50%;
}

.comment-by {
  width: 100%;
  padding-bottom: 8px;
  padding-top: 5px;
  font-weight: 500;
  color: #333;
}

.comment-by h5 {
  margin: 0 0 7px 0;
}

.comment-by a.reply {
  transition: all 0.3s;
}

.comment-by a.reply {
  display: inline;
  background-color: #f0f0f0;
  padding: 5px 16px;
  line-height: 22px;
  font-size: 14px;
  color: #666;
  font-weight: 500;
  position: absolute;
  right: 0;
  top: 15px;
  border-radius: 4px;
}

.comment-by a.reply i {
  font-size: 12px;
  margin-right: 1px;
}

.comment-by a.reply:hover {
  background-color: #66676b;
  color: #fff;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.1);
}

.comment-by span.date {
  color: #888;
  font-weight: 500;
  margin: 0px 0 0 0;
  float: none;
  display: block;
  text-align: left;
}

@media (max-width: 768px) {
  /* Media Queries for Comments */
  .comments ul li ul {
    margin: 0;
  }
  .avatar {
    top: 3px;
  }

  .comment-content {
    color: #666;
    padding: 0 0 0 70px;
  }

  .avatar img {
    max-width: 50px;
    border-radius: 50%;
    top: 5px;
  }

  .comment-by a.reply {
    position: relative;
    margin: 10px 0 0 0;
    top: 0;
    display: inline-block;
  }

  /* Media Queries for Blog Post */
  .blog-post {
    flex-direction: column;
  }
  .blog-post-thumbnail {
    width: 100%;
    height: 220px;
  }
  #posts-nav li {
    position: relative;
    width: 100%;
  }

  #posts-nav li a {
    padding: 0 !important;
  }
  #posts-nav li a::before {
    display: none;
  }
  #posts-nav li.next-post {
    text-align: left;
    margin-bottom: 30px;
  }
}

/* Blog Widget Tabs */
.widget-tabs {
  padding: 0;
  list-style: none;
  margin-top: 25px;
  display: block;
}

.widget-content {
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  background-color: #fff;
  position: relative;
  width: 100%;
  overflow: hidden;
}

.widget-content:before {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  background: #fff;
  opacity: 1;
  transition: 0.35s ease-in-out;
}

.widget-content img {
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 9;
  opacity: 1;
  transition: 0.35s;
  border-radius: 5.5px;
}

.widget-text h5 {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin: 0 0 3px 0;
  color: #333;
  transition: 0.35s;
}

.widget-text span {
  font-size: 14px;
  color: #888;
  display: inline-block;
  background-color: #f0f0f0;
  border-radius: 4px;
  line-height: 20px;
  padding: 4px 10px;
  margin-top: 3px;
  transition: 0.35s;
}

.widget-text {
  display: block;
  padding: 0;
  position: relative;
  z-index: 20;
  padding: 30px;
}

.widget-tabs .widget-content {
  display: table;
}

.widget-tabs .widget-content .widget-text {
  display: table-cell;
  vertical-align: middle;
}

.widget-tabs li {
  margin-top: 20px;
}

.widget-content:hover:before,
.widget-content.active:before {
  opacity: 0.6;
  background: #333;
}

.widget-content:hover img,
.widget-content.active img {
  opacity: 1;
}

.widget-content:hover h5,
.widget-content.active h5 {
  color: #fff;
}

.widget-content:hover span,
.widget-content.active span {
  background: rgba(255, 255, 255, 0.25);
  color: #fff;
}

/* Blog Compact Item
------------------------------------- */
.blog-compact-item-container {
  display: block;
  height: 100%;
  width: 100%;
  border-radius: 4px;
  background: transparent;
  transform: translate3d(0, 0, 0);
  transition: transform 0.4s;
  margin-bottom: 30px;
}

.blog-compact-item-container:hover {
  transform: translateY(-10px);
}

.blog-compact-item {
  background: #ccc;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  height: 100%;
  display: block;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  height: 460px;
  z-index: 100;
  cursor: pointer;
  transition: 0.4s;
}

@media (max-width: 1366px) {
  .blog-compact-item {
    height: 420px;
  }
}

.blog-compact-item img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 4px;
}

.blog-compact-item:before {
  content: "";
  top: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 9;
  border-radius: 4px;
  background: linear-gradient(
    to top,
    rgba(51, 51, 51, 0.8) 0%,
    rgba(51, 51, 51, 0.7) 35%,
    rgba(51, 51, 51, 0.3) 60%,
    rgba(51, 51, 51, 0.3) 100%
  );
  transition: 0.4s;
}

.blog-compact-item-content {
  position: absolute;
  bottom: 32px;
  left: 0;
  padding: 0 34px;
  width: 100%;
  z-index: 50;
  box-sizing: border-box;
}

.blog-compact-item-content h3 {
  color: #fff;
  font-size: 20px;
  padding: 5px 0;
  font-weight: 500;
  margin: 2px 0 0 0;
  line-height: 30px;
}

.blog-compact-item-content span {
  font-size: 16px;
  font-weight: 300;
  display: inline-block;
  color: rgba(255, 255, 255, 0.7);
}

.blog-compact-item-content p {
  font-size: 16px;
  font-weight: 300;
  display: inline-block;
  color: rgba(255, 255, 255, 0.8);
  margin: 7px 0 0 0;
}

span.blog-item-tag {
  font-size: 14px;
  font-weight: 600;
  color: #333;
  background-color: #fff;
  border-radius: 4px;
  padding: 5px 12px;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 9px;
  position: absolute;
  top: 30px;
  left: 32px;
  z-index: 110;
  transition: 0.4s;
}

.blog-post-tags {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 0;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  opacity: 0.9;
}

.blog-post-tags li {
  display: inline-block;
  margin-right: 5px;
}

.blog-post-tags li i {
  margin-right: 3px;
  font-weight: normal;
  display: inline-block;
}

/* ---------------------------------- */
/* Contact Page
------------------------------------- */
.contact-location-info {
  display: flex;
  align-content: center;
  justify-content: center;
  background-color: #fff;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.03);
}

.contact-location-info .contact-address,
.contact-location-info #single-job-map-container {
  flex: 1;
}

.contact-location-info #single-job-map-container #singleListingMap {
  border-radius: 0 4px 4px 0;
}

.contact-location-info .contact-address {
  border: 1px solid #e0e0e0;
  border-right: 0;
  border-radius: 4px 0 0 4px;
}

.contact-location-info .contact-address {
  display: flex;
}

.contact-location-info .contact-address > ul {
  align-self: center;
  padding: 40px;
  margin: 0;
  list-style: none;
  font-size: 18px;
  color: #808080;
}

.contact-location-info .contact-address ul li {
  padding: 2px 0;
}

.contact-location-info .freelancer-socials {
  margin-top: 10px;
  margin-left: -8px;
  transform: scale(0.9);
}

.contact-address-headline {
  color: #333;
  font-weight: 600;
}

.contact-address-headline:after {
  content: "";
  height: 2px;
  width: 25px;
  background-color: #66676b;
  position: relative;
  display: block;
  margin: 15px 0;
  border-radius: 3px;
}

@media (max-width: 768px) {
  .contact-location-info {
    flex-direction: column;
  }
  .contact-location-info .contact-address {
    border: 1px solid #e0e0e0;
    border-bottom: none;
    border-radius: 4px 4px 0 0;
  }
  .contact-location-info #single-job-map-container #singleListingMap {
    border-radius: 0 0 4px 4px;
  }
}

#contact div input {
  margin-bottom: 22px;
}

/* ---------------------------------- */
/* Single Task / Job / Profile
------------------------------------- */
.single-page-section {
  margin-bottom: 55px;
  display: block;
}

.single-page-section p:last-of-type {
  padding-bottom: 0;
  margin-bottom: 0;
}

.single-page-section h3 {
  margin-bottom: 20px;
  font-size: 20px;
}
.single-page-section .job-listing h3.job-listing-title {
  margin-bottom: 0;
}

/* -------------------------------------------------------------- */
/*  03. Listings Styles
----------------------------------------------------------------- */

/* ---------------------------------- */
/* Full Width Page Layout
------------------------------------- */
.full-page-container {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.full-page-sidebar {
  flex: 0 0 360px;
  background-color: #fafafa;
  position: relative;
}

.full-page-sidebar .full-page-sidebar-inner {
  overflow: auto;
  height: 100%;
}

.full-page-sidebar .sidebar-container {
  padding: 40px;
}

.full-page-sidebar .simplebar-scrollbar {
  width: 5px;
}

.full-page-sidebar .sidebar-widget:last-of-type {
  margin-bottom: 80px;
}

.full-page-content-container {
  flex: 1;
  background-color: #fff;
  padding: 0;
  overflow: auto;
}

.full-page-content-inner {
  padding: 40px;
  padding-bottom: 0;
}

.full-page-content-inner .grid-layout {
  width: calc(100% + 30px);
}

.full-page-content-inner .grid-layout .job-listing {
  margin: 0 30px 30px 0;
  width: calc(100% * (1 / 3) - 30px);
}

/* Search Button */
.sidebar-search-button-container {
  position: absolute;
  width: calc(100% - 15px);
  bottom: 0;
  padding: 40px;
  padding-top: 10px;
  z-index: 100;
  background-color: #fafafa;
}

.sidebar-search-button-container button {
  width: calc(100% + 15px);
  line-height: 46px;
  height: 46px;
  transition: 0.3s;
  box-shadow: 0 4px 12px rgba(102, 103, 107, 0.1);
}

.sidebar-search-button-container button:hover {
  box-shadow: 0 4px 12px rgba(102, 103, 107, 0.25);
  transform: translateY(-2px);
}

/* Small Footer */
.small-footer {
  border-top: 1px solid #e0e0e0;
  padding-top: 0px;
  width: calc(100% + 80px);
  left: -40px;
  position: relative;
  padding: 25px 40px;
  display: flex;
}

.small-footer strong {
  color: #333;
}

.small-footer-copyrights {
  flex: 2;
}

.small-footer .footer-social-links {
  transform: none;
  flex: 1;
  text-align: right;
  float: right;
  display: inline-block;
  margin: 0 -5px 0 0;
  position: relative;
  top: 2px;
}

.small-footer .footer-social-links li {
  color: #333;
  transform: none;
  float: none;
  margin: 0 -3px;
}

.small-footer .footer-social-links li a {
  color: #333;
  transform: none;
}

/* Full Page With Map
------------------------------------- */
.full-page-map-container {
  flex: auto;
  position: relative;
  background-color: #e5e3df;
}

.full-page-map-container #map {
  position: relative;
  z-index: 30;
}

.full-page-container.with-map .full-page-content-container {
  flex: 0 0 50vw;
}

/* Sliding Sidebar */
.full-page-sidebar.hidden-sidebar {
  visibility: hidden;
  transition: 0.4s;
  position: relative;
  opacity: 0;
  max-width: 0;
}

/*.full-page-container.with-map .full-page-sidebar .sidebar-search-button-container,*/
.full-page-container.with-map .full-page-sidebar .full-page-sidebar-inner {
  width: 360px;
}

.full-page-sidebar.hidden-sidebar.enabled-sidebar {
  visibility: visible;
  opacity: 1;
  max-width: 360px;
}

/* Enable Filter Button */
.enable-filters-button {
  position: relative;
  left: 48px;
  top: 0px;
  z-index: 100;
  background-color: #fff;
  color: #333;
  border-radius: 0 4px 4px 0;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  height: 48px;
  line-height: 48px;
  padding: 0;
  transition: 0.3s;
  min-width: 126px;
  text-align: center;
}

.enable-filters-button span {
  position: relative;
  padding: 0 15px;
  background-color: #66676b;
  color: #fff;
  width: 100%;
  z-index: 100;
  border-radius: 0 4px 4px 0;
  transition: 0.3s;
}

/* Filter Button Container */
.filter-button-container {
  position: absolute;
  left: 40px;
  top: 20px;
  width: calc(100% - 40px);
  overflow-x: hidden;
  padding: 20px 0;
}

.filter-button-tooltip {
  position: absolute;
  width: auto;
  left: 15px;
  top: 24px;
  z-index: 100;
  border-radius: 4px;
  width: auto;
  animation: tooltip-bounce 2s infinite;
  margin-left: 15px;
  white-space: nowrap;
  opacity: 0;
  overflow: visible;
  transition: opacity 0.3s;
  pointer-events: none;
  padding: 0 20px;
  background-color: #fff;
  color: #555;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  font-size: 15.7px;
  line-height: 48px;
  top: 20px;
}

.filter-button-tooltip.tooltip-visible {
  opacity: 1;
}

.filter-button-tooltip:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: calc(50% - 3px);
  content: "";
  left: -3px;
  box-sizing: border-box;
  border: 5px solid black;
  border-color: transparent transparent #fff #fff;
  transform-origin: 0 0;
  transform: rotate(45deg) translateY(-50%);
  box-shadow: -2px 2px 2px 0 rgba(0, 0, 0, 0.04);
}

@keyframes tooltip-bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(14px);
  }
  60% {
    transform: translateX(7px);
  }
}

/* Hover & Active States */
.enable-filters-button:hover i,
.enable-filters-button.active i,
.enable-filters-button:hover span,
.enable-filters-button.active span {
  background-color: #333;
}

.enable-filters-button:hover i:after,
.enable-filters-button:hover i:before,
.enable-filters-button.active i:after,
.enable-filters-button.active i:before {
  background-color: rgba(0, 0, 0, 0.25);
}

.enable-filters-button i {
  position: absolute;
  z-index: 90;
  left: -48px;
  height: 100%;
  font-size: 20px;
  background-color: #66676b;
  color: #fff;
  line-height: 48px;
  text-align: center;
  width: 48px;
  border-radius: 4px 0 0 4px;
  font-style: normal;
  font-family: "Feather-Icons";
  transition: 0.3s;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.enable-filters-button i:after,
.enable-filters-button i:before {
  position: absolute;
  left: 0;
  right: 0;
  transition: 0.3s;
  border-radius: 4px 0 0 4px;
  background-color: rgba(0, 0, 0, 0.1);
}

.enable-filters-button i:after {
  content: "\e9d1";
  opacity: 1;
}

.enable-filters-button.active i:after {
  opacity: 0;
}

.enable-filters-button i:before {
  content: "\ea02";
  opacity: 0;
}
.enable-filters-button.active i:before {
  opacity: 1;
}

.enable-filters-button span.show-text,
.enable-filters-button.active span.hide-text {
  display: inline-block;
}

.enable-filters-button.active span.show-text,
.enable-filters-button span.hide-text {
  display: none;
}

/* Location Field on Map */
.location-field-on-map {
  position: absolute;
  top: 40px;
  right: 40px;
  width: 280px;
  max-width: 40%;
  z-index: 100;
}

.location-field-on-map input {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1) !important;
}

/* Full Page Media Queries
------------------------------------- */
@media (max-width: 3860px) {
  .full-page-content-inner .grid-layout .job-listing {
    width: calc(100% * (1 / 4) - 30px);
  }

  /* Full Page With Map */
  .full-page-container.with-map
    .full-page-content-inner
    .grid-layout
    .job-listing {
    width: calc(100% * (1 / 3) - 30px);
  }
}

@media (max-width: 2560px) {
  .full-page-content-inner .grid-layout .job-listing {
    width: calc(100% * (1 / 4) - 30px);
  }

  /* Full Page With Map */
  .full-page-container.with-map
    .full-page-content-inner
    .grid-layout
    .job-listing {
    width: calc(100% * (1 / 2) - 30px);
  }
}

@media (max-width: 1920px) {
  .full-page-content-inner .grid-layout .job-listing {
    width: calc(100% * (1 / 3) - 30px);
  }

  /* Full Page With Map */
  .full-page-container.with-map .full-page-sidebar {
    flex: 0 0 340px;
  }
  .full-page-container.with-map .full-page-sidebar .full-page-sidebar-inner {
    width: 340px;
  }
  .full-page-container.with-map
    .full-page-content-inner
    .grid-layout
    .job-listing {
    width: calc(100% * (1 / 2) - 30px);
  }
}

@media (max-width: 1600px) {
  .full-page-content-inner .grid-layout .job-listing {
    width: calc(100% * (1 / 2) - 30px);
  }

  .full-page-sidebar {
    flex: 0 0 340px;
  }

  /* Full Page With Map */
  .full-page-container.with-map .full-page-sidebar {
    flex: 0 0 320px;
  }
  .full-page-container.with-map .full-page-sidebar .full-page-sidebar-inner {
    width: 320px;
  }
  .full-page-container.with-map
    .full-page-content-inner
    .grid-layout
    .job-listing {
    width: calc(100% - 30px);
  }

  .full-page-container.with-map .full-page-content-container {
    flex: 0 0 55vw;
  }
}

@media (max-width: 1365px) {
  .location-field-on-map {
    left: 0px;
    right: auto;
    width: calc(100% - 80px);
    max-width: calc(100% - 80px);
    margin: 0 40px;
  }

  /* Notify Box Responsive Styles*/
  .full-page-container .notify-box {
    margin-bottom: 80px;
  }
  .full-page-container .notify-box .sort-by {
    position: absolute;
    margin-top: 40px;
    left: 0;
  }
  .full-page-container .notify-box .sort-by .bootstrap-select .dropdown-menu {
    left: 0;
    right: auto;
  }
}

@media (max-width: 1099px) {
  .full-page-content-inner .grid-layout .job-listing {
    width: calc(100% - 30px);
  }
  .full-page-sidebar {
    flex: 0 0 340px;
  }

  /* Important Responsive Styles */
  .full-page-map-container {
    order: -1;
    width: 100%;
    height: 400px;
    position: absolute;
    top: 0;
  }

  .full-page-container.with-map {
    padding-top: 400px;

    height: auto !important;
    width: 100%;
    position: relative;
  }

  .full-page-container.with-map .full-page-sidebar {
    max-width: 100%;
    visibility: visible;
    opacity: 1;
  }

  .filter-button-container {
    display: none;
  }

  .full-page-container.with-map .full-page-sidebar .full-page-sidebar-inner {
    position: relative;
    width: 100%;
  }

  .full-page-container.with-map .full-page-content-container {
    flex: 1;
  }

  .full-page-container,
  .full-page-sidebar-inner,
  .full-page-content-container,
  .full-page-container .full-page-sidebar {
    height: auto !important;
  }

  .full-page-container .simplebar-track {
    display: none;
  }
}

@media (max-width: 992px) {
  .location-field-on-map {
    width: calc(100% - 50px);
    max-width: calc(100% - 50px);
    margin: 0 25px;
    top: 25px;
  }

  .sidebar-search-button-container,
  .full-page-sidebar .sidebar-container,
  .full-page-content-inner {
    padding: 35px;
  }
  .full-page-content-inner {
    padding-bottom: 0;
  }

  .full-page-content-inner .grid-layout .job-listing {
    width: calc(100% - 30px);
  }
  .full-page-sidebar {
    flex: 0 0 320px;
  }

  .full-page-sidebar-inner,
  .full-page-content-container,
  .full-page-container .full-page-sidebar {
    height: auto !important;
  }
}

@media (max-width: 768px) {
  .location-field-on-map {
    width: calc(100% - 30px);
    max-width: calc(100% - 30px);
    margin: 0 15px;
  }

  .sidebar-search-button-container,
  .full-page-sidebar .sidebar-container,
  .full-page-content-inner {
    padding: 40px 15px;
  }
  .full-page-content-inner {
    padding-bottom: 0;
  }

  .full-page-content-inner .grid-layout .job-listing {
    width: calc(100% - 30px);
  }

  .full-page-sidebar {
    flex: 1;
    width: 100%;
  }

  .full-page-container {
    display: block;
    width: 100%;
    flex-wrap: wrap;
    height: auto;
  }

  .small-footer {
    display: block;
    text-align: center;
  }
  .small-footer .footer-social-links {
    float: none;
    left: -10px;
    margin-top: 10px;
  }
}

/* ---------------------------------- */
/* Job Listing - List Layout
------------------------------------- */
.job-listing {
  display: block;
  border-radius: 4px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.12);
  margin-bottom: 35px;
  background-color: #fff;
  transition: 0.3s;
  position: relative;
}

.job-listing:hover {
  box-shadow: 0 2px 18px rgba(0, 0, 0, 0.14);
  transform: translateY(-4px);
}

.job-listing-details {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 35px;
  padding-bottom: 32px;
}

.job-listing .job-listing-company-logo {
  flex: 1;
  max-width: 55px;
  margin-right: 25px;
  position: relative;
  top: 3px;
}

.job-listing .job-listing-company-logo img {
  border-radius: 4px;
  transform: translate3d(0, 0, 0);
}

.job-listing .job-listing-description {
  flex: 1;
  padding-top: 3px;
}

.job-listing .job-listing-description p {
  margin: 15px 0 0 0;
  padding: 0;
  color: #666;
}

.job-listing h4.job-listing-company {
  font-size: 16px;
  color: #808080;
}

.job-listing h3.job-listing-title {
  font-size: 20px;
  color: #333;
  line-height: 30px;
}

.job-listing .job-listing-footer {
  background-color: #f9f9f9;
  padding: 20px 35px;
  border-radius: 0 0 4px 4px;
  position: relative;
}

.job-listing .job-listing-footer ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.job-listing .job-listing-footer ul li {
  display: inline-block;
  margin-right: 14px;
  color: #777;
}

.job-listing .job-listing-footer ul li:last-child {
  margin-right: 0;
}

.job-listing .job-listing-footer ul li i.icon-material-outline-location-on {
  margin-right: 0;
}

.job-listing .job-listing-footer ul li i {
  position: relative;
  top: 1px;
  margin-right: 3px;
  color: #777;
}

/* Media Queries for Default List Layout*/
@media (max-width: 768px) {
  .job-listing .job-listing-company-logo {
    display: none;
  }
  .job-listing .job-listing-title {
    padding-right: 10%;
  }
}

/* ---------------------------------- */
/* Job Listing - Compact List Layout
------------------------------------- */
.compact-list-layout {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.12);
}

.compact-list-layout .job-listing {
  box-shadow: none;
  margin-bottom: 0;
  padding: 30px 35px;
  padding-right: 80px;
  border-radius: 0;
}

.compact-list-layout .job-listing:last-of-type {
  border-radius: 0 0 3px 3px;
  overflow: hidden;
}

.compact-list-layout .job-listing:first-of-type {
  border-radius: 3px 3px 0 0;
  overflow: hidden;
}

.compact-list-layout .job-listing h3 {
  font-size: 18px;
  line-height: 28px;
}

.compact-list-layout .job-listing:nth-child(2n) {
  background-color: #fafafa;
}

.compact-list-layout .job-listing:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 3px;
  height: 100%;
  display: block;
  background: #66676b;
  transition: 0.3s;
  opacity: 0;
}

.compact-list-layout .job-listing:hover:before {
  opacity: 1;
}

.compact-list-layout .job-listing:hover {
  transform: none;
}

.compact-list-layout .job-listing .job-listing-footer {
  background-color: transparent;
  padding: 0;
  margin-top: 3px;
}

.compact-list-layout .job-listing-details {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0;
  top: 0;
}

.compact-list-layout .job-listing .job-listing-company-logo {
  max-width: 50px;
  margin-right: 30px;
  top: 0;
}

.compact-list-layout .job-listing .verified-badge {
  transform: scale(0.85) translate(8px, 8px);
}

.compact-list-layout .job-listing h3 .verified-badge {
  transform: scale(0.85) translate(0, 0);
  font-weight: 500;
  top: 1px;
}

.compact-list-layout .job-listing h3 .verified-badge:before {
  top: -1px;
}

.compact-list-layout .job-listing span.bookmark-icon {
  top: 50%;
  transform: translateY(-51%);
}

/* List Layuot Apply Button */
.compact-list-layout .job-listing.with-apply-button {
  position: relative;
  padding-right: 35px;
}

.list-apply-button {
  position: absolute;
  right: 0;
  padding: 10px 20px;
  line-height: 24px;
  position: relative;
  font-size: 16px;
  font-weight: 500;
  display: inline-block;
  transition: all 0.3s;
  border-radius: 4px;
  background: #f0f0f0;
  color: #666;
  text-align: center;
}

.job-listing.with-apply-button:hover .list-apply-button {
  background-color: #66676b;
  color: #fff;
  box-shadow: 0 4px 12px rgba(102, 103, 107, 0.15);
}

/* Alt Styles for Single Company Profile */
.boxed-list .compact-list-layout {
  box-shadow: none;
  margin-top: 0;
}

.boxed-list .compact-list-layout .job-listing:hover {
  border-left: none;
}

.boxed-list .compact-list-layout .job-listing:first-of-type {
  border-radius: 0;
}

/* Media Queries for Grid Layout */
@media (max-width: 768px) {
  .list-apply-button {
    flex: auto;
    width: 100%;
    margin-top: 15px;
  }
}

/* ---------------------------------- */
/* Job Listing - Grid Layout
------------------------------------- */
.grid-layout {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 30px);
}

.grid-layout .job-listing {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 30px 30px 0;
  width: calc(100% * (1 / 2) - 30px);
  flex-direction: column;
}

.grid-layout .job-listing-details {
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 30px;
  padding-right: 40px;
}

/* IE 11 Fixes */
@media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
  .tasks-list-container.tasks-grid-layout .task-listing .task-listing-details,
  .grid-layout .job-listing-details {
    flex-basis: 100px;
  }
  .tasks-list-container.tasks-grid-layout .task-listing-bid {
    flex-basis: 100px;
  }
}
/* IE 11 Fixes - End */

.grid-layout .job-listing-footer {
  flex-grow: 0;
  padding: 20px 30px;
}

.grid-layout .job-listing h3.job-listing-title {
  font-size: 18px;
  line-height: 28px;
}

.grid-layout .job-listing-footer .bookmark-icon {
  top: 0;
  right: 25px;
  transform: translateY(-50%) scale(0.96);
}

.grid-layout .job-listing .job-listing-company-logo {
  max-width: 56px;
  margin-right: 24px;
  top: 1px;
  flex: 0 0 56px;
}

/* Media Queries for Grid Layout */
@media (max-width: 768px) {
  .full-page-content-inner .grid-layout .job-listing,
  .grid-layout .job-listing {
    margin: 0 0 30px 0;
    width: 100%;
  }
  .grid-layout,
  .full-page-content-inner .grid-layout {
    width: 100%;
  }

  .full-page-content-container .simplebar-content {
    overflow-x: hidden !important;
  }
  .full-page-container.with-map
    .full-page-content-inner
    .grid-layout
    .job-listing {
    width: 100%;
  }
}

/* ---------------------------------- */
/* Tasks - List Layout
------------------------------------- */
.task-listing {
  border-radius: 4px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.12);
  margin-bottom: 30px;
  background-color: #fff;
  transition: 0.3s;
  position: relative;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.task-listing:hover {
  box-shadow: 0 2px 18px rgba(0, 0, 0, 0.14);
  transform: translateY(-4px);
}

.task-listing-details {
  padding: 34px 38px;
  flex: 1;
}

.task-listing .task-listing-description {
  flex: 1;
  align-items: center;
  justify-content: center;
}

.task-listing .task-listing-description p {
  margin: 15px 0 0 0;
  padding: 0;
  color: #666;
}

.task-listing h3.task-listing-title a,
.task-listing h3.task-listing-title {
  font-size: 18px;
  color: #333;
  line-height: 28px;
  cursor: pointer;
  font-weight: 600;
  padding-top: 2px;
  transition: 0.3s;
}

.task-listing .task-icons {
  list-style: none;
  padding: 0;
  margin: 3px 0 0 -3px;
}

.task-listing .task-icons li {
  display: inline-block;
  padding: 0;
  margin: 0;
  margin-right: 10px;
  color: #808080;
}

.task-listing .task-icons i {
  color: #909090;
  font-size: 18px;
  position: relative;
  top: 1px;
}

.task-listing .task-icons i.icon-material-outline-desktop-windows {
  font-size: 19px;
  top: 2px;
}

.task-listing-bid {
  flex: 0 0 240px;
  background-color: #f8f8f8;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.task-listing-bid-inner {
  width: 100%;
  padding: 35px;
}

.task-listing-bid-inner .button {
  display: block;
  text-align: center;
  box-shadow: 0 4px 12px rgba(102, 103, 107, 0.15);
  width: 100%;
}

.task-listing-bid-inner .button:hover {
  box-shadow: 0 4px 12px rgba(102, 103, 107, 0.25);
}

.task-listing-bid .task-offers {
  margin-bottom: 15px;
  /*	text-align: center;*/
}

.task-listing-bid .task-offers strong {
  display: block;
  color: #333;
  font-weight: 600;
  line-height: 24px;
}

.task-listing-bid .task-offers span {
  display: block;
  color: #888;
  line-height: 24px;
}

/* Task Tags */
.task-tags {
  display: block;
  margin: 23px 0 3px 0;
}

.task-tags span {
  transition: 0.3s;
  font-size: 14.7px;
  border-radius: 4px;
  background-color: rgba(102, 103, 107, 0.07);
  color: #66676b;
  display: inline-block;
  padding: 6px 15px;
  margin: 2px 0;
  line-height: 24px;
}

/* Compact List */
.compact-list .task-listing {
  border-radius: 0;
  margin-bottom: 0;
  box-shadow: none;
}

.compact-list .task-listing:hover {
  transform: translateY(0);
}

.tasks-list-container.compact-list {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.12);
}

.tasks-list-container.compact-list .task-listing {
  align-items: center;
  justify-content: center;
}

.tasks-list-container.compact-list .task-listing:nth-child(2n) {
  background-color: #fafafa;
}

.tasks-list-container.compact-list .task-listing-bid {
  background-color: transparent;
}

.tasks-list-container.compact-list .task-listing:hover:before {
  opacity: 1;
}

.tasks-list-container.compact-list .task-listing:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 3px;
  height: 100%;
  display: block;
  background: #66676b;
  transition: 0.3s;
  opacity: 0;
}

.tasks-list-container.compact-list .task-listing:last-of-type {
  border-radius: 0 0 3px 3px;
  overflow: hidden;
}

.tasks-list-container.compact-list .task-listing:first-of-type {
  border-radius: 3px 3px 0 0;
  overflow: hidden;
}

.tasks-list-container.compact-list .task-listing .task-listing-details {
  padding-right: 0;
}

/* Section Styles */
.section .task-listing-details,
.section .task-listing-bid-inner {
  padding: 27px 35px;
}

/* Media Queries for Tasks List */
@media (max-width: 1200px) {
  .task-listing-bid {
    flex: 0 0 220px;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .task-listing-bid {
    flex: 1;
    padding: 10px 0;
  }

  .task-listing {
    flex-direction: column;
  }
  .task-listing-bid-inner {
    display: flex;
    margin: 20px 0 0 0;
  }
  .tasks-grid-layout .task-listing-bid-inner {
    margin: 0;
  }
  .task-listing-bid-inner .task-offers {
    flex: 1;
  }
  .task-listing-bid-inner .button {
    height: 100%;
    width: 30%;
  }

  .tasks-list-container.compact-list .task-listing .task-listing-details {
    padding-right: 35px;
    padding-bottom: 0;
  }

  .tasks-list-container.compact-list .task-listing .task-listing-bid {
    padding-bottom: 25px;
  }
  .task-listing-bid-inner {
    width: 100%;
    padding: 0 35px;
  }
}

/* Small Mobile Sizes */
@media (max-width: 768px) {
  .section .task-listing-details {
    padding: 25px 30px;
  }
  .section .task-listing-bid-inner {
    padding: 0 30px;
  }
}

/* Small Mobile Sizes */
@media (max-width: 480px) {
  .task-listing-bid {
    flex: 1;
    padding: 30px 0;
  }

  .task-listing {
    flex-direction: column;
  }

  .task-listing-bid-inner .task-offers {
    flex: 1;
  }
  .task-listing-bid-inner .button {
    height: 100%;
    width: 100%;
  }

  .tasks-list-container.compact-list .task-listing .task-listing-details {
    padding-right: 35px;
    padding-bottom: 0;
  }

  .tasks-list-container.compact-list .task-listing .task-listing-bid {
    padding-bottom: 35px;
  }
  .task-listing-bid-inner {
    width: 100%;
    padding: 0 35px;
  }
}

/* ---------------------------------- */
/* Tasks - Grid Layout
------------------------------------- */
.tasks-list-container.tasks-grid-layout {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 30px);
}

.tasks-list-container.tasks-grid-layout .task-listing {
  display: flex;
  justify-content: center;
  margin: 0 30px 30px 0;
  width: calc(100% * (1 / 2) - 30px);
  flex-direction: column;
}

.tasks-list-container.tasks-grid-layout .task-listing-bid {
  flex: 1;
  padding: 0px 0;
  display: block;
}

.tasks-list-container.tasks-grid-layout .task-listing {
  flex-direction: column;
}
.tasks-list-container.tasks-grid-layout .task-listing-bid-inner {
  display: flex;
  align-items: center;
  padding: 18px 32px;
}

.tasks-list-container.tasks-grid-layout .task-listing-bid-inner .task-offers {
  flex: 1;
  position: relative;
  top: 5px;
}

.tasks-list-container.tasks-grid-layout .task-listing-bid-inner .button {
  height: 100%;
  width: 45%;
}

.tasks-list-container.tasks-grid-layout .task-listing .task-listing-details {
  display: flex;
  align-items: center;
  padding: 28px 32px;
}

/* Media Queries for Tasks List */
@media (max-width: 1240px) {
  .tasks-list-container.tasks-grid-layout .task-listing-bid-inner {
    display: block;
  }
  .tasks-list-container.tasks-grid-layout .task-listing-bid {
    padding: 20px 35px;
    padding-bottom: 30px;
  }
  .tasks-list-container.tasks-grid-layout .task-listing-bid-inner {
    padding: 0;
  }
  .tasks-list-container.tasks-grid-layout .task-listing {
    flex-direction: column;
  }
  .tasks-list-container.tasks-grid-layout .task-listing-bid-inner .task-offers {
    flex: 1;
  }
  .tasks-list-container.tasks-grid-layout .task-listing-bid-inner .button {
    height: 100%;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .tasks-list-container.tasks-grid-layout {
    width: 100%;
  }
  .tasks-list-container.tasks-grid-layout .task-listing {
    margin: 0 0 30px 0;
    width: 100%;
  }
  .tasks-list-container.compact-list .task-listing-bid,
  .tasks-list-container.compact-list .task-listing-details {
    width: 100%;
  }
}

/* Full Page Media Queries */
@media (max-width: 3860px) {
  .full-page-content-inner .tasks-grid-layout .task-listing {
    width: calc(100% * (1 / 4) - 30px);
  }
}

@media (max-width: 2560px) {
  .full-page-content-inner .tasks-grid-layout .task-listing {
    width: calc(100% * (1 / 3) - 30px);
  }
}

@media (max-width: 1600px) {
  .full-page-content-inner .tasks-grid-layout .task-listing {
    width: calc(100% * (1 / 2) - 30px);
  }
}

@media (max-width: 1099px) {
  .full-page-content-inner .tasks-grid-layout .task-listing {
    width: calc(100% - 30px);
  }
}

@media (max-width: 992px) {
  .full-page-content-inner .tasks-grid-layout .task-listing {
    width: calc(100%);
  }
}

/* ---------------------------------- */
/* Freelancers - Grid Layout
------------------------------------- */
.freelancers-container {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 30px);
}

.freelancer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 30px 30px 0;
  width: calc(100% * (1 / 2) - 30px);
  border-radius: 4px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  transition: 0.3s;
  position: relative;
  cursor: default;
}

.freelancer:hover {
  box-shadow: 0 2px 18px rgba(0, 0, 0, 0.14);
  transform: translateY(-4px);
}

.freelancer .bookmark-icon {
  cursor: pointer;
}

.freelancer-overview {
  text-align: center;
  padding: 38px 20px;
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.freelancer-overview-inner {
  flex: 1;
}

.freelancer-overview .freelancer-avatar {
  width: 110px;
  margin: 0 auto;
  position: relative;
}

.freelancer-overview .freelancer-avatar img {
  width: 100%;
  border-radius: 50%;
  cursor: pointer;
}

.freelancer-overview .freelancer-avatar .verified-badge {
  position: absolute;
  bottom: 0;
  right: 0;
}

.freelancer-name {
  margin-top: 19px;
}

.freelancer-rating {
  margin-top: 5px;
  margin-bottom: -10px;
}

.freelancer-name h4,
.freelancer-name h4 a {
  font-size: 18px;
  font-weight: 600;
  color: #333;
  cursor: pointer;
}

.freelancer-name img.flag {
  height: 15px;
  border-radius: 3px;
  position: relative;
  top: -1px;
  display: inline-block;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  margin-left: 4px;
  cursor: default;
}

.freelancer-name span {
  color: #888;
}

.freelancer-details {
  padding: 35px;
  background-color: #fafafa;
  flex-grow: 0;
}

.freelancers-grid-layout .freelancer-details {
  border-radius: 0 0 4px 4px;
}

.freelancers-grid-layout .freelancer-details a.button {
  display: block;
  text-align: center;
  width: 100% !important;
  transition: 0.3s;
  box-shadow: 0 4px 12px rgba(102, 103, 107, 0.1);
}

.freelancer-details a.button:hover {
  box-shadow: 0 4px 12px rgba(102, 103, 107, 0.2);
}

.freelancer-details-list ul {
  list-style: none;
  padding: 0;
  margin: 0 0 5px 0;
  font-size: 14.7px;
}

.freelancer-details-list ul li {
  display: inline-block;
  margin-right: 25px;
  line-height: 23px;
  color: #808080;
  margin-bottom: 15px;
}

.freelancer-details-list ul li:last-child {
  margin-right: 0;
}

.freelancer-details-list ul li strong {
  display: block;
  color: #333;
}

.freelancer-details-list ul li strong i {
  position: relative;
  top: 1px;
  margin-right: -1px;
  margin-left: -2px;
}

/* Detail Item */
.freelancer-detail-item {
  display: inline-block;
  margin: 2px 10px 5px 0;
}

.freelancer-detail-item a,
.freelancer-detail-item {
  color: #888;
}

.freelancer-detail-item a:hover {
  color: #66676b;
}

.freelancer-detail-item i {
  position: relative;
  top: 2px;
  margin-right: 3px;
}

/* Media Queiers for Freelancers List */
@media (max-width: 1366px) {
  .freelances-grid-layout .freelancer-overview .freelancer-avatar {
    width: 100px;
  }
}

@media (max-width: 768px) {
  .freelances-grid-layout.freelancers-container {
    width: 100%;
  }
  .freelances-grid-layout .freelancer {
    margin: 0 0 30px 0;
    width: 100%;
  }
  .freelancers-container.freelancers-grid-layout {
    width: 100%;
  }
  .freelancers-container.freelancers-grid-layout .freelancer {
    width: 100%;
    margin-right: 0;
  }
}

/* Full Page Media Queiers for Freelancers List */
@media (max-width: 2560px) {
  .full-page-container .freelancer {
    width: calc(100% * (1 / 4) - 30px);
  }
}

@media (max-width: 1920px) {
  .full-page-container .freelancer {
    width: calc(100% * (1 / 3) - 30px);
  }
}

@media (max-width: 1366px) {
  .full-page-container .freelancer {
    width: calc(100% * (1 / 2) - 30px);
  }
}

@media (max-width: 1099px) {
  .full-page-container .freelancers-container {
    width: 100%;
  }
  .full-page-container .freelancer {
    width: 100%;
    margin-right: 0;
  }
}

/* ---------------------------------- */
/* Freelancers - List Layout
------------------------------------- */
.freelancers-container.freelancers-list-layout {
  width: 100%;
}

.freelancers-list-layout .freelancer {
  margin: 0 0 30px 0;
  width: 100%;
  flex-direction: row;
  justify-content: center;
}

.freelancers-list-layout .freelancer-overview {
  text-align: left;
  padding: 45px 40px;
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.freelancers-list-layout .freelancer-overview-inner {
  flex: 1;
  display: flex;
  align-items: center;
}

.freelancers-list-layout .freelancer-details {
  padding: 45px 40px 45px 0;
  background-color: transparent;
  flex: 0 0 360px;
}

.freelancers-list-layout .freelancer-overview .freelancer-avatar {
  width: 100px;
  margin: 0;
}

.freelancers-list-layout .freelancer-overview .freelancer-name {
  text-align: left;
  margin: 0 0 0 30px;
}

.freelancers-list-layout .freelancer-details a.button {
  display: block;
  text-align: center;
  min-width: 50%;
  max-width: 100%;
  width: auto !important;
  transition: 0.3s;
  float: right;
  box-shadow: 0 4px 12px rgba(102, 103, 107, 0.15);
}

.freelancers-list-layout .freelancer-details a.button:hover {
  box-shadow: 0 4px 12px rgba(102, 103, 107, 0.25);
}

.freelancers-list-layout .bookmark-icon {
  display: none;
}

.freelancers-list-layout .freelancer-details-list ul {
  float: right;
  display: block;
}

.freelancers-list-layout .freelancer-details-list ul li {
  margin-left: 25px;
  margin-right: 0;
  float: right;
}

/* Freelancers Compact List */
.compact-list.freelancers-list-layout .freelancer {
  border-radius: 0;
  margin-bottom: 0;
  box-shadow: none;
}

.compact-list.freelancers-list-layout .freelancer:hover {
  transform: translateY(0);
}

.compact-list.freelancers-list-layout {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.12);
}

.compact-list.freelancers-list-layout .freelancer:nth-child(2n) {
  background-color: #fafafa;
}

.compact-list.freelancers-list-layout .freelancer:hover:before {
  opacity: 1;
}

.compact-list.freelancers-list-layout .freelancer:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 3px;
  height: 100%;
  display: block;
  background: #66676b;
  transition: 0.3s;
  opacity: 0;
}

.compact-list.freelancers-list-layout .freelancer:last-of-type {
  border-radius: 0 0 3px 3px;
  overflow: hidden;
}

.compact-list.freelancers-list-layout .freelancer:first-of-type {
  border-radius: 3px 3px 0 0;
  overflow: hidden;
}

/* Media Queiers for Freelancers List Layout */
@media (max-width: 1366px) {
  .freelancers-list-layout .freelancer-overview .freelancer-avatar {
    width: 100px;
  }
}

@media (max-width: 1240px) {
  .freelancers-list-layout .freelancer {
    flex-direction: column;
  }
  .freelancers-list-layout .freelancer-details {
    flex: 1;
    padding: 35px 40px;
    background-color: #fafafa;
  }

  .freelancers-list-layout .freelancer-details-list ul {
    float: left;
  }

  .freelancers-list-layout .freelancer-details-list ul li {
    margin-left: 0;
    margin-right: 25px;
    float: left;
  }

  .freelancers-list-layout .freelancer-details a.button {
    float: none;
    width: 100% !important;
  }

  .freelancers-list-layout .freelancer-overview .freelancer-avatar {
    width: 90px;
  }

  /* Compact Layout */
  .compact-list.freelancers-list-layout .freelancer-details {
    background-color: transparent;
    padding-top: 0;
  }
}

@media (max-width: 768px) {
  .freelancers-list-layout .freelancer-overview,
  .freelancers-list-layout .freelancer-details {
    padding: 30px;
  }

  .freelancers-list-layout .freelancer-overview .freelancer-avatar {
    width: 80px;
  }

  .freelancers-list-layout .star-rating:before {
    display: block;
    float: none;
    text-align: center;
    max-width: 40px;
    margin-bottom: 4px;
  }
}

/* ---------------------------------- */
/* Browse Companies
------------------------------------- */

/* Letters List */
.letters-list {
  width: 100%;
  text-align: center;
  background-color: #f4f4f4;
  border-radius: 4px;
  padding: 20px;
}

.letters-list a {
  display: inline-block;
  font-size: 18px;
  color: #333;
  height: 40px;
  width: 40px;
  line-height: 40px;
  background-color: transparent;
  border-radius: 4px;
  transition: 0.3s;
  margin: 0 -2px;
}

.letters-list a.current {
  margin-right: 0;
  color: #fff;
  font-weight: 600;
  background-color: #66676b;
  box-shadow: 0 2px 8px rgba(102, 103, 107, 0.25);
}

.letters-list a:hover {
  color: #fff;
  background-color: #333;
  font-weight: 600;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

/* Companies List */
.companies-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  width: calc(100% + 30px);
}

.companies-list .company {
  width: calc(33.3% - 30px);
  margin: 0 30px 30px 0;
  flex-grow: 1;
  text-align: center;
  /*border-right: 1px solid #e0e0e0;
	border-bottom: 1px solid #e0e0e0;*/
  padding: 50px 0;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: 0.3s;
}

.companies-list .company:hover {
  transform: translateY(-3px);
}

@media (min-width: 993px) {
  .companies-list .company:nth-child(3n) {
    border-right: none;
  }
  .companies-list .company:nth-last-child(-n + 3) {
    border-bottom: none;
  }
}

.companies-list .company img {
  max-width: 120px;
  max-height: 60px;
  display: inline-block;
  margin: 0;
  transform: translate3d(0, 0, 0);
}

.companies-list .company .company-logo {
  height: 60px;
  display: flex;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.companies-list .company h4 {
  color: #333;
  font-size: 18px;
  font-weight: 600;
  transition: 0.3s;
  display: block;
}

.companies-list .company span {
  color: #888;
}

.companies-list .company .star-rating {
  margin-top: 5px;
}

.not-rated,
.company-not-rated {
  border-radius: 4px;
  background-color: #f0f0f0;
  color: #888;
  font-size: 14px;
  line-height: 15px;
  font-weight: 600;
  padding: 7px 9px;
  display: inline-block;
  margin-top: 8px;
  text-align: center;
}

/* Media Queries for Companies List */
@media (max-width: 1366px) {
  .letters-list a {
    font-size: 16px;
    height: 36px;
    width: 36px;
    line-height: 36px;
    margin: 0 -1px;
  }
}

@media (max-width: 992px) {
  .companies-list .company {
    width: calc(50% - 30px);
  }
}

@media (max-width: 768px) {
  .companies-list .company {
    width: 100%;
  }
}

/* -------------------------------------------------------------- */
/*  04. User Interface Elements
----------------------------------------------------------------- */

/* ---------------------------------- */
/* Buttons
------------------------------------- */

button {
  vertical-align: middle;
}

span.button,
button.button,
input[type="button"],
input[type="submit"],
a.button {
  background-color: #66676b;
  top: 0;
  padding: 10px 20px;
  line-height: 24px;
  color: #fff;
  position: relative;
  font-size: 16px;
  font-weight: 500;
  display: inline-block;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  overflow: hidden;
  border: none;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(102, 103, 107, 0.15);
}

/* Icons Aligment*/
.button i[class^="icon-material"],
button.button i[class^="icon-material"],
input[type="button"] i[class^="icon-material"],
input[type="submit"] i[class^="icon-material"],
a.button i[class^="icon-material"] {
  font-size: 18px;
  height: 0;
  width: 18px;
  line-height: 0;
  top: 2px;
  position: relative;
  display: inline-block;
  margin-left: 3px;
}

.button i[class^="icon-feather"],
button.button i[class^="icon-feather"],
input[type="button"] i[class^="icon-feather"],
input[type="submit"] i[class^="icon-feather"],
a.button i[class^="icon-feather"] {
  font-size: 16px;
  height: 0;
  width: 16px;
  line-height: 0;
  top: 2px;
  position: relative;
  display: inline-block;
  margin-left: 3px;
}

/* Dark Style */
button.button.dark,
input[type="button"].dark,
input[type="submit"].dark,
a.button.dark {
  background-color: #333;
  color: #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Dark Style */
button.button.gray,
input[type="button"].gray,
input[type="submit"].gray,
a.button.gray {
  background-color: #eaeaea;
  color: #666;
  box-shadow: none;
}

button.button.gray:hover,
input[type="button"].gray:hover,
input[type="submit"].gray:hover,
a.button.gray:hover {
  background-color: #e0e0e0;
}

body .button i.icon-material-outline-arrow-right-alt {
  font-size: 22px;
  top: 4px;
}

.button.full-width {
  max-width: 100%;
  width: 100%;
  text-align: center;
  display: block;
}

.button.big {
  font-size: 18px;
  padding: 13px 22px;
}

body .button.big i {
  font-size: 22px;
  top: 3px;
  position: relative;
}

/* Slide Out Button */
.button.button-slide-out span {
  transform: translateY(0px);
  opacity: 1;
  transition: 0.3s;
}

.button.button-slide-out:hover span {
  transform: translateY(-10px);
  opacity: 0;
  display: inline-block;
  transition: 0.3s;
}

.button.button-slide-out i {
  transform: translateY(calc(-50% + 10px));
  opacity: 0;
  display: inline-block;
  transition: 0.3s;
  position: absolute;
  left: 0;
  right: 0;
  font-size: 18px;
  top: 50%;
  height: 20px;
}

.button.button-slide-out:hover i {
  transform: translateY(calc(-50% + 0px));
  opacity: 1;
}

/* Move on Hover */
.move-on-hover {
  box-shadow: 0 4px 12px rgba(102, 103, 107, 0.1);
  transform: translateY(0);
  transition: 0.3s;
}

.move-on-hover:hover {
  box-shadow: 0 4px 12px rgba(102, 103, 107, 0.2);
  transform: translateY(-2px);
}

/* Centered Button */
.centered-button {
  text-align: center;
  display: block;
}

.centered-button .button {
  margin: 0 auto;
}

/* Bookmark Button */
span.bookmark-icon {
  font-size: 20px;
  position: absolute;
  z-index: 101;
  right: 35px;
  top: 35px;
  cursor: pointer;
  background-color: #eee;
  color: #c0c0c0;
  display: block;
  height: 39px;
  width: 39px;
  border-radius: 50%;
  transition: all 0.4s;
  line-height: 27px;
}

.bookmark-icon.bookmarked {
  animation: bookmarked-shadow 0.3s;
}

@keyframes bookmarked-shadow {
  0% {
    box-shadow: 0 0 0 0 rgba(254, 179, 66, 0.8);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
}

.bookmark-icon:before {
  transition: transform 0.4s cubic-bezier(0.8, -0.41, 0.19, 2.5);
  font-family: "Material-Icons";
  position: absolute;
  right: 0;
  left: 0;
  text-align: center;
  top: 6px;
  content: "\e988";
}

.bookmark-icon:hover {
  background-color: #333;
  color: #fff;
}

.bookmark-icon.bookmarked {
  background-color: #feb342;
  color: #fff;
}

.bookmark-icon.bookmarked:before {
  animation: bookmark-icon 0.5s;
}

@keyframes bookmark-icon {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

/* Bookmark Button */
.bookmark-button {
  background-color: #404040;
  display: inline-block;
  padding: 0;
  position: relative;
  height: 44px;
  line-height: 44px;
  color: #fff;
  padding: 0 15px;
  padding-left: 59px;
  border-radius: 4px;
  transition: 0.4s;
  cursor: pointer;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.1);
}

.bookmark-button .bookmark-icon,
.bookmark-button:hover .bookmark-icon {
  background-color: rgba(255, 255, 255, 0.05);
}

.bookmark-button .bookmark-icon {
  border-radius: 4px;
  position: absolute;
  left: 0;
  right: auto;
  top: auto;
  height: 44px;
  width: 44px;
  border-radius: 4px 0 0 4px;
  pointer-events: none;
  transition: 0.4s;
  animation: none;
}

.bookmark-button .bookmark-icon:before {
  top: 8px;
  color: #fff;
  transition: 0.4s;
  font-size: 18px;
}

.bookmark-button.bookmarked {
  background-color: #feb342;
  color: #fff;
  box-shadow: 0 3px 12px rgba(254, 179, 66, 0.2);
}

.bookmark-button.bookmarked .bookmark-icon:before,
.bookmark-button:hover .bookmark-icon:before {
  color: #fff;
}

.bookmark-button.bookmarked .bookmark-icon,
.bookmark-button.bookmarked:hover .bookmark-icon {
  background-color: rgba(255, 255, 255, 0.16);
}
.bookmark-button.bookmarked .bookmarked-text,
.bookmark-button .bookmark-text {
  display: block;
}

.bookmark-button.bookmarked .bookmark-text,
.bookmark-button .bookmarked-text {
  display: none;
}

/* ---------------------------------- */
/* Button with sliding icon
------------------------------------- */
.button-sliding-icon {
  text-align: center;
}
.button-sliding-icon i {
  opacity: 0;
  max-width: 0;
  transition: 0.25s;
  display: inline-block;
  transform: translateX(-20px);
}

.button-sliding-icon:hover i {
  max-width: 20px;
  opacity: 1;
  transform: translateX(0);
}

/* For buttons */
.button.button-sliding-icon {
  padding-left: 20px;
  padding-right: 10px;
}

.button.button-sliding-icon:hover {
  padding-right: 20px;
}

.button.button-sliding-icon i {
  padding-right: 5px;
}

.button.button-sliding-icon:hover i {
  max-width: 20px;
  opacity: 1;
  transform: translateX(0);
}

/* ---------------------------------- */
/* List Styles
------------------------------------- */
.list-3,
.list-2,
.list-1 {
  padding: 3px 0 0 0;
  font-size: 16px;
}

.list-3 li,
.list-2 li,
.list-1 li {
  list-style: none;
  margin: 10px 0;
  line-height: 27px;
  margin-left: 20px;
  position: relative;
}

.list-3 li:first-child,
.list-2 li:first-child,
.list-1 li:first-child {
  margin-top: 0;
}

.list-4 li:before,
.list-3 li:before,
.list-2 li:before,
.list-1 li:before {
  margin: 0;
  position: relative;
  color: #66676b;
  float: left;
  margin-left: -20px;
  display: block;
}

.list-1.gray li:before {
  color: #777;
}

.list-2 li,
.list-2 li {
  margin-left: 28px;
}

.list-3 li:before,
.list-2 li:before {
  font-family: "Material-Icons";
  content: "\e928";
  font-size: 18px;
  font-weight: 600;
  position: relative;
  top: -2px;
  margin-left: -28px;
}

.list-3 li:before {
  content: "\e912";
  font-family: "Feather-Icons";
  font-weight: 500;
  font-size: 18px;
}

.list-1 li:before {
  content: "";
  height: 6px;
  width: 6px;
  background-color: #66676b;
  border-radius: 2px;
  position: relative;
  top: 9px;
}

/* Numbered Style */
.numbered ol {
  counter-reset: li;
  list-style: none;
  padding: 0;
  margin-left: 18px;
  display: inline-block;
  font-size: 16px;
}

.numbered ol li {
  display: inline-block;
  padding: 6px 0;
  width: 100%;
}

.numbered ol > li::before {
  content: counter(li);
  counter-increment: li;
  font-size: 16px;
  line-height: 35px;
  width: 36px;
  height: 36px;
  display: inline-block;
  border: 1px solid #333;
  border-radius: 50%;
  text-align: center;
  margin: 0;
  margin-left: 0;
  font-weight: 500;
  color: #333;
  position: relative;
  float: left;
  left: -18px;
  font-size: 15px;
  transform: translateY(-10%);
}

.numbered.color ol > li::before {
  border: 1px solid #66676b;
  color: #66676b;
}

.numbered.color.filled ol > li::before {
  border: 1px solid #66676b;
  color: #fff;
  background-color: #66676b;
}

/* ---------------------------------- */
/* Marks
------------------------------------- */
mark {
  background-color: #fcf8e3;
  color: #8a803e;
  padding: 1px 5px;
}

mark.color {
  background-color: #66676b;
  border-radius: 4px;
  color: #fff;
}

/* ---------------------------------- */
/* Notification Boxes
------------------------------------- */
#result .success,
.notification {
  line-height: 24px;
  margin-bottom: 15px;
  position: relative;
  padding: 20px 26px;
  padding-right: 50px;
  border-radius: 3px;
}

.notification p {
  margin: 0;
  font-size: 15px;
}

#result .success,
.notification.success {
  background-color: #ebf6e0;
}

#result .success,
.notification.success,
.notification.success a,
.notification.success strong {
  color: #5f9025;
}

.notification.error {
  background-color: #ffe9e9;
}
.notification.error,
.notification.error a,
.notification.error strong {
  color: #de5959;
}

.notification.warning {
  background-color: #fbfadd;
}
.notification.warning,
.notification.warning a,
.notification.warning strong {
  color: #8f872e;
}

.notification.notice h4 {
  font-size: 19px;
  margin: 3px 0 15px 0;
}
.notification.notice h4,
.notification.notice,
.notification.notice a,
.notification.notice strong {
  color: #3184ae;
}

.notification.notice {
  background-color: #e9f7fe;
}

.notification.notice.large {
  padding: 32px 36px;
}

.notification strong {
  font-weight: 700;
}
.notification a {
  text-decoration: underline;
}

body .notification strong {
  border: none;
}

.notification.success .close,
.notification.error .close,
.notification.warning .close,
.notification.notice .close {
  padding: 0px 9px;
  position: absolute;
  right: 0;
  top: 22px;
  display: block;
  height: 8px;
  width: 8px;
  cursor: pointer;
}

.notification.notice p span i {
  font-weight: 500;
}
.notification a.button {
  float: right;
  color: #fff;
  margin-top: 3px;
}

.notification.notice a.button {
  background-color: #388fc5;
}
.notification.warning a.button {
  background-color: #dfbe51;
}
.notification.error a.button {
  background-color: #d34c4c;
}
.notification.success a.button {
  background-color: #79ba38;
}

.notification.closeable a.close:before {
  content: "\ea02";
  font-family: "Feather-Icons";
  position: absolute;
  right: 25px;
  top: 0;
  cursor: pointer;
  font-weight: 600;
}

/* ---------------------------------- */
/* Tables
------------------------------------- */
table.basic-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border: none;
  margin-bottom: 15px;
}

table.basic-table th {
  background-color: #66676b;
  text-align: left;
  color: #fff;
  vertical-align: top;
  font-weight: 500;
}

table.basic-table th:first-child {
  border-radius: 4px 0 0 4px;
}
table.basic-table th:last-child {
  border-radius: 0 4px 4px 0;
}

table.basic-table th,
table.basic-table td {
  padding: 15px 28px;
}

table.basic-table tr:nth-child(odd) {
  background-color: #f4f4f4;
}

table.basic-table {
  margin-bottom: 0;
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
  }
  table th {
    display: none;
  }

  table tr {
    display: block;
    margin-bottom: 0;
  }
  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 14px;
    text-align: right;
  }

  table td:before {
    content: attr(data-label);
    float: left;
    font-weight: 600;
  }

  table td:last-child {
    border-bottom: 0;
  }
}

/* ---------------------------------- */
/* Accordion
------------------------------------- */
.accordion {
  font-size: 16px;
  width: 100%;
  margin: 0 auto;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
}

.accordion .accordion {
  border-radius: 0px;
  box-shadow: none;
}

.accordion-header,
.accordion-body {
  background: #fff;
}

.accordion-header {
  padding: 15px 25px;
  background: #66676b;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  transition: all 0.3s;
  position: relative;
  box-shadow: 0 -1px 0 rgba(255, 255, 255, 0.15);
}

.accordion .accordion .accordion-header {
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.07);
}

.accordion-header:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  content: "";
  opacity: 0;
  transition: 0.3s;
}

.accordion-body__contents p:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

/*.accordion__item.active .accordion-header:before,
.accordion-header:hover:before { opacity: 0.04; }*/

.accordion-body {
  background: #fff;
  color: #666;
  display: none;
}

.accordion-body__contents {
  padding: 25px;
  font-size: 16px;
  line-height: 28px;
}

.accordion__item.active:last-child .accordion-header {
  border-radius: none;
}
.accordion:first-child > .accordion__item > .accordion-header {
  border-bottom: 1px solid transparent;
}

.accordion__item > .accordion-header:after {
  content: "\e957";
  font-family: Material-Icons;
  font-size: 19px;
  float: right;
  position: relative;
  top: -1px;
  transition: 0.3s all;
  transform: rotate(0deg);
}

.accordion__item.active > .accordion-header:after {
  transform: rotate(-180deg);
}
.accordion__item.active .accordion .accordion-header:before {
  opacity: 0;
}

.accordion__item .accordion__item .accordion-header {
  background: #f7f7f7;
  color: #666;
}

/* ---------------------------------- */
/* Tabs
------------------------------------- */
.tabs ul {
  padding: 0;
  list-style: none;
  margin: 0;
}

.tabs {
  z-index: 15px;
  position: relative;
  background: #ffffff;
  width: 100%;
  border-radius: 4px;
  box-shadow: 0 4px 18px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  margin: 0;
  overflow: hidden;
}

.tabs-header {
  position: relative;
  background: #66676b;
  overflow: hidden;
}

.tabs-header .tab-hover {
  position: absolute;
  bottom: 0;
  left: 0;
  background: #fff;
  width: auto;
  height: 100%;
  transition: 0.3s ease;
  opacity: 0.08;
  z-index: 90;
}

.tabs-header ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: calc(100% - 100px);
  position: relative;
  z-index: 100;
}

.tabs-header li {
  transition: 0.3s ease;
}

.tabs-header a {
  z-index: 1;
  display: block;
  box-sizing: border-box;
  padding: 15px 25px;
  color: #fff;
  font-weight: 500;
  text-decoration: none;
}

.tabs-nav {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 15px;
  color: #fff;
  user-select: none;
  font-size: 19px;
}

.tabs-nav i {
  cursor: pointer;
  display: inline-block;
  padding: 0;
  height: 30px;
  width: 30px;
  line-height: 30px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  text-align: center;
  position: relative;
  z-index: 101;
  margin-left: 5px;
  transition: 0.3s;
}

.tabs-nav i:hover {
  background-color: rgba(255, 255, 255, 0.15);
}

.tabs-content {
  position: relative;
  transition: 0.3s ease;
  overflow: hidden;
}

.tabs-content .tab {
  padding: 25px;
}

.tabs-content .tab p:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

.tabs-content:after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  display: block;
  width: 100%;
  height: 1px;
  box-shadow: 0 0 20px 10px #ffffff;
}

.tabs-content .tab {
  display: none;
}
.tabs-content .tab.active {
  display: block;
}

@media (max-width: 992px) {
  .tabs-nav {
    display: none;
  }
  .tabs-header ul {
    flex-direction: column;
    width: 100%;
  }
  .tab-hover {
    display: none;
  }
  .tabs-header a {
    box-shadow: 0 -1px 0 rgba(255, 255, 255, 0.15);
  }
  .tabs-header ul li.active a {
    background-color: rgba(255, 255, 255, 0.07);
  }
}

/* ---------------------------------- */
/* Copy to clipboard
------------------------------------- */
.copy-url {
  display: flex;
}

.copy-url input {
  border-radius: 4px 0 0 4px;
  border-right: 0;
}

.copy-url .copy-url-button {
  min-width: 48px;
  background-color: #66676b;
  color: #fff;
  border-radius: 0 4px 4px 0;
}

/* ---------------------------------- */
/* Share Buttons
------------------------------------- */
.share-buttons {
  display: block;
}

.share-buttons-trigger {
  display: inline-block;
  height: 44px;
  width: 44px;
  line-height: 44px;
  text-align: center;
  color: #a0a0a0;
  font-size: 18px;
  background-color: #f0f0f0;
  border-radius: 4px;
  transition: 0.4s;
  cursor: default;
}

.share-buttons-trigger i {
  position: relative;
  top: 1px;
}

.share-buttons-content {
  display: inline-block;
  position: relative;
  width: calc(100% - 60px);
}

.share-buttons-content span {
  margin-left: 10px;
  color: #888;
  cursor: default;
  padding: 10px 0;
  display: inline-block;
  transition: 0.4s;
}

.share-buttons-content span strong {
  color: #66676b;
  font-weight: 600;
}

.share-buttons:hover .share-buttons-content span {
  opacity: 0;
  transition: 0.2s;
}

/* Icons */
.share-buttons-icons {
  position: absolute;
  left: 5px;
  top: 0;
  padding: 0;
  margin: 0;
  opacity: 0;
  transform: translateX(-10px);
  transition: all 0.4s cubic-bezier(0.5, -0.41, 0.19, 2);
}

.share-buttons:hover .share-buttons-icons {
  opacity: 1;
  transform: translate(0);
}

.share-buttons-icons li {
  display: inline-block;
  float: left;
}

.share-buttons-icons li:first-child a {
  border-radius: 4px 0 0 4px;
}
.share-buttons-icons li:last-child a {
  border-radius: 0 4px 4px 0;
}

.share-buttons-icons li a {
  height: 44px;
  width: 44px;
  display: inline-block;
  line-height: 44px;
  text-align: center;
  background-color: #333;
  color: #fff;
  font-size: 14px;
}

.share-buttons-icons li a i {
  position: relative;
  top: 1px;
}

/* ---------------------------------- */
/* Verified Badge
------------------------------------- */
.job-listing-company .verified-badge,
.job-listing .job-listing-footer ul li .verified-badge {
  position: relative;
  display: inline-block;
  transform: scale(0.7);
  top: 2px;
  margin-left: -2px;
}

/* Verified Badge */
.verified-badge {
  position: relative;
  height: 25px;
  width: 25px;
  display: inline-block;
  background-color: #38b653;
  border-radius: 50%;
  text-align: center;
  z-index: 10;
  font-weight: 500;
}

.verified-badge:before {
  content: "\e92b";
  font-family: "Feather-Icons";
  font-size: 16px;
  color: #fff;
  position: relative;
  top: 0px;
  line-height: 27px;
}

.job-listing .job-listing-company-logo .verified-badge {
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(7px, 7px);
}

/* Long Verified Badge */
.verified-badge-with-title {
  position: relative;
  height: 26px;
  display: flex;
  top: -1px;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  background-color: #30ab4a;
  text-align: center;
  z-index: 10;
  font-weight: 500;
  border-radius: 4px;
  padding: 0 8px 0 0;
  margin: 0;
  overflow: hidden;
  padding-left: 34px;
  line-height: 27px;
}
.verified-badge-with-title:before {
  content: "\e92b";
  font-family: "Feather-Icons";
  font-size: 16px;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  line-height: 26px;
  height: 26px;
  width: 26px;
  display: inline-block;
  background-color: #38b653;
}

/* ---------------------------------- */
/* Star Rating
------------------------------------- */
/* Star Rating */
.star-rating {
  display: inline-block;
}

.star-rating .star {
  display: inline-block;
  margin: 0;
  padding: 0;
  float: left;
  margin-right: 0;
  position: relative;
}

.star-rating .star.half:after,
.star-rating .star:before {
  font-family: "Material-Icons";
  content: "\e988";
  display: block;
  color: #febe42;
  font-size: 22px;
}

.star-rating .star.empty:before {
  color: #ddd;
}

.rating:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.star-rating .star.half:before {
  color: #ddd;
}
.star-rating .star.half:after {
  color: #febe42;
  position: absolute;
  top: 0;
  width: 50%;
  display: block;
  height: 100%;
  overflow: hidden;
}

/* average rating before stars */
.star-rating:before {
  content: attr(data-rating);
  float: left;
  border-radius: 4px;
  background-color: #febe42;
  color: #fff;
  font-size: 14px;
  line-height: 15px;
  font-weight: 700;
  padding: 5px 7px;
  position: relative;
  top: 1px;
  margin-right: 10px;
}

/* No stars */
.star-rating.no-stars .star {
  display: none;
}
.star-rating.no-stars:before {
  margin-right: 0;
}

/* ---------------------------------- */
/* Leave Rating Stars
------------------------------------- */
.leave-rating {
  height: 24px;
  float: left;
  position: relative;
}

.leave-rating:hover input[type="radio"]:checked ~ label {
  color: #dadada;
}

.leave-rating input[type="radio"] {
  display: inline-block;
  width: 1px;
  position: absolute;
  opacity: 0;
  pointer-events: none;
  height: 100%;
  margin-left: 50%;
  left: 0;
  top: 0;
  transform: translateX(-50%);
}

.leave-rating input[type="radio"]:checked ~ label {
  color: #ffc600;
}

.leave-rating label {
  font-size: 24px;
  float: right;
  letter-spacing: 0px;
  color: #dadada;
  cursor: pointer;
  transition: 0.3s;
}

.leave-rating label:hover,
.leave-rating label:hover ~ label {
  color: #ffc600 !important;
}

.leave-rating-title {
  display: block;
  margin: 0 0 4px 0;
}

.welcome-text .leave-rating-container {
  text-align: center;
  margin: 0 auto;
  margin-top: 15px;
  left: 50%;
  width: 100%;
  display: block;
}

.welcome-text .leave-rating {
  position: relative;
  left: 50%;
  transform: translateX(-50%) scale(1.2);
}

/* ---------------------------------- */
/* Ripple Effect
------------------------------------- */
.ripple-effect-dark,
.ripple-effect {
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.ripple-effect span.ripple-overlay,
.ripple-effect-dark span.ripple-overlay {
  animation: ripple 0.9s;
  border-radius: 100%;
  background: #fff;
  height: 12px;
  position: absolute;
  width: 12px;
  line-height: 12px;
  opacity: 0.1;
  pointer-events: none;
}

.ripple-effect-dark span.ripple-overlay {
  background: #000;
  opacity: 0.07;
}

@keyframes ripple {
  0% {
    -webkit-transform: scale(4);
    transform: scale(4);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(40);
    transform: scale(40);
  }
}

/* ---------------------------------- */
/* Input With Icon
------------------------------------- */
.input-with-icon {
  display: block;
  position: relative;
}

.input-with-icon input {
  padding-right: 45px;
}

.input-with-icon i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  font-size: 20px;
  color: #a0a0a0;
  font-style: normal;
  cursor: normal;
  pointer-events: none;
}

.input-with-icon i.currency {
  font-size: 14px;
}

/* Input with boxed icon */
.input-with-icon-left {
  position: relative;
}

.input-with-icon-left input {
  padding-left: 65px;
}

.input-with-icon-left i {
  position: absolute;
  top: 0;
  color: #a0a0a0;
  text-align: center;
  line-height: 48px;
  width: 48px;
  height: 48px;
  font-size: 19px;
  background-color: #f8f8f8;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  display: block;
  border-radius: 4px 0 0 4px;
}

.input-with-icon-left.no-border i {
  border: none;
}

/* ---------------------------------- */
/* Keywords Input
------------------------------------- */
.keywords-container {
  margin-bottom: -15px;
}

.keywords-list {
  display: block;
  float: left;
  height: 0;
}

.keyword {
  background-color: rgba(102, 103, 107, 0.07);
  color: #66676b;
  display: inline-block;
  float: left;
  padding: 0;
  border-radius: 4px;
  cursor: default;
  margin: 0 7px 7px 0;
  height: 35px;
  line-height: 35px;
  box-sizing: border-box;
  animation: KeywordIn 0.3s ease-in-out;
  animation-fill-mode: both;
  transition: 0.4s;
  overflow: hidden;
  max-width: 100%;
  font-size: 14.7px;
}

@keyframes KeywordIn {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.keyword.keyword-removed {
  max-width: 0;
  margin: 0;
  overflow: hidden;
  opacity: 0;
  animation: KeywordOut 0.4s ease-in-out;
  animation-fill-mode: both;
  pointer-events: none;
}

@keyframes KeywordOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    margin-top: -35px;
  }
}

.keyword-text {
  padding-right: 12px;
}

.keyword .keyword-remove:before {
  display: inline-block;
  cursor: pointer;
  font-family: "Feather-Icons";
  content: "\ea02";
  line-height: 20px;
  position: relative;
  top: 2px;
  margin-right: 4px;
  margin-left: 10px;
  font-size: 15px;
}

.keyword-input-container {
  position: relative;
}

.keyword-input-container .keyword-input-button {
  position: absolute;
  top: 0;
  right: 0;
  height: 36px;
  width: 36px;
  padding: 0;
  color: #fff;
  background-color: #66676b;
  border-radius: 4px;
  margin: 6px;
  font-size: 19px;
  text-align: center;
  line-height: 36px;
}

.keyword-input-container .keyword-input-button i {
  color: #fff;
  position: relative;
  top: 2px;
}

input.keyword-input {
  margin: 0 0 15px 0;
}

/* ---------------------------------- */
/* Clickable Tags
------------------------------------- */
.tags-container {
  display: block;
}

.tags-container input[type="checkbox"] {
  display: none;
}

.tags-container input[type="checkbox"] + label {
  transition: 0.3s;
  font-size: 14.7px;
  cursor: pointer;
  border-radius: 4px;
  background-color: #eee;
  color: #777;
  display: inline-block;
  padding: 6px 15px;
  margin: 0;
  line-height: 24px;
}

.tags-container input[type="checkbox"] + label:hover {
  background-color: #e8e8e8;
}

.tags-container input[type="checkbox"]:checked + label {
  background-color: #66676b;
  color: #fff;
  transition: 0.3s;
}

.tags-container input[type="checkbox"] + label:before {
  font-family: "Feather-Icons";
  content: "\e92b";
  font-size: 18px;
  transition: all 0.35s cubic-bezier(0.5, -0.41, 0.19, 2), max-width 0.3s,
    margin 0.3s;
  max-width: 0;
  display: inline-block;
  position: relative;
  top: 3px;
  margin: 0;
  opacity: 0;
  color: #fff;
  line-height: 0;
  transform: scale(0.5);
}

.tags-container input[type="checkbox"]:checked + label:before {
  max-width: 30px;
  opacity: 1;
  transform: scale(1);
  margin: 0 5px 0 -2px;
}

.tags-container .tag {
  display: inline-block;
  float: left;
  margin: 0 7px 7px 0;
  overflow: hidden;
}

/* ---------------------------------- */
/* On/Off Switch
------------------------------------- */
.switches-list .switch-container {
  width: 100%;
}

.switches-list .switch-container:last-child label {
  margin-bottom: 0;
}

.switch-container {
  display: inline-block;
  cursor: pointer;
}

label.switch {
  position: relative;
}

.switches-list .switch-container label {
  cursor: pointer;
  position: relative;
  padding-left: 45px;
  line-height: 26px;
}

.switch-button {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
  cursor: pointer;
  background-color: #ccc;
  border-radius: 50px;
  transition: 0.4s;
  margin: 0 7px 0 0;
  top: 4px;
  position: absolute;
  top: 2px;
  left: 0;
}

.notify-box .switch-button {
  top: 0;
}

.notify-box .switch-container {
  top: 1px;
  position: relative;
}

.switch span.switch-text {
  display: block;
  padding-left: 48px;
  font-size: 16px;
  line-height: 22px;
  color: #666;
}

.switch input {
  display: none;
}

.switch.interactive-effect input:checked + .switch-button:before {
  animation: switch-shadow-color 0.4s;
}

.switch.interactive-effect .switch-button:before {
  animation: switch-shadow 0.4s;
}

@keyframes switch-shadow {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.3);
  }
  100% {
    box-shadow: 0 0 0px 10px rgba(0, 0, 0, 0);
  }
}

@keyframes switch-shadow-color {
  0% {
    box-shadow: 0 0 0 0 rgba(102, 103, 107, 0.6);
  }
  100% {
    box-shadow: 0 0 0px 10px rgba(0, 0, 0, 0);
  }
}

.switch-button:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  bottom: 2px;
  left: 2px;
  background-color: white;
  transition: 0.4s, box-shadow 0.3s;
  border-radius: 50px;
}

input:checked + .switch-button {
  background-color: #66676b;
}

input:checked + .switch-button:before {
  transform: translateX(14px);
}

/* ---------------------------------- */
/* Radio Button
------------------------------------- */

.radio {
  display: inline-block;
  vertical-align: top;
}

.radio label {
  margin: 3px 0;
  cursor: pointer;
  position: relative;
  padding-left: 29px;
  line-height: 25px;
}

.radio input[type="radio"] {
  position: absolute;
  opacity: 0;
}

.radio input[type="radio"] + label .radio-label {
  content: "";
  background: #fff;
  border-radius: 100%;
  border: 2px solid #b4b4b4;
  display: inline-block;
  width: 18px;
  height: 18px;
  position: relative;
  margin-right: 5px;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
  transition: all 250ms ease;
  background-color: #fff;
  box-shadow: inset 0 0 0 8px #fff;
  z-index: 100;
  position: absolute;
  top: 2px;
  left: 0;
}

.radio input[type="radio"] + label .radio-label:after {
  background-color: #66676b;
  content: "";
  top: 3px;
  left: 0;
  right: 0;
  margin: 0 auto;
  position: absolute;
  opacity: 1;
  transition: 0.3s;
  border-radius: 50%;
  transform: scale(0);
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  z-index: 99;
}

.radio input[type="radio"]:checked + label .radio-label {
  background-color: #66676b;
  border-color: #66676b;
  box-shadow: inset 0 0 0 3px #fff;
}

.radio-label:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background-color: transparent;
  display: block;
}

.interactive-effect label .radio-label:before {
  animation: radius-shadow 0.4s;
}

@keyframes radius-shadow {
  0% {
    box-shadow: 0 0 0 0 rgba(102, 103, 107, 0.4);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
}

.small-label {
  border-radius: 4px;
  display: inline-block;
  background: #e0f5d7;
  color: #449626;
  padding: 0 8px;
  line-height: 27px;
  height: 26px;
  margin-left: 5px;
  position: relative;
  top: -1px;
}

/* ---------------------------------- */
/* Checkbox
------------------------------------- */
.checkbox {
  display: inline-block;
  margin-bottom: 0;
}

.checkbox input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.checkbox label {
  position: relative;
  cursor: pointer;
  padding-left: 30px;
  line-height: 25px;
}

.checkbox label span.checkbox-icon {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #b4b4b4;
  height: 20px;
  width: 20px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  border-radius: 4px;
  transition: 0.3s;
  position: absolute;
  left: 0;
  top: 1px;
}

.checkbox input + label span.checkbox-icon:after {
  position: absolute;
  top: 2px;
  left: 0;
  right: 0;
  margin: 0 auto;
  opacity: 0;
  content: "";
  display: block;
  width: 6px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: scale(0.5) rotate(45deg);
  transition: all 0.35s cubic-bezier(0.3, -0.41, 0.19, 2), opacity 0.3s;
}

.checkbox input:checked + label span.checkbox-icon {
  border-color: #66676b;
  background-color: #66676b;
}

.checkbox input:checked + label span.checkbox-icon:after {
  transform: scale(1) rotate(45deg);
  opacity: 1;
}

/* ---------------------------------- */
/* Custom Upload Button
------------------------------------- */
.uploadButton {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 10px;
  width: 100%;
  font-style: normal;
  font-size: 14px;
}

.uploadButton .uploadButton-input {
  opacity: 0;
  position: absolute;
  overflow: hidden;
  z-index: -1;
  pointer-events: none;
}

.uploadButton .uploadButton-button {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 44px;
  padding: 10px 18px;
  cursor: pointer;
  border-radius: 4px;
  color: #66676b;
  background-color: transparent;
  border: 1px solid #66676b;
  flex-direction: row;
  transition: 0.3s;
  margin: 0;
  outline: none;
  box-shadow: 0 3px 10px rgba(102, 103, 107, 0.1);
}

.uploadButton .uploadButton-button:hover {
  background-color: #66676b;
  box-shadow: 0 4px 12px rgba(102, 103, 107, 0.15);
  color: #fff;
}

.uploadButton .uploadButton-file-name {
  flex-grow: 1;
  display: flex;
  align-items: center;
  flex: 1;
  box-sizing: border-box;
  padding: 0 10px;
  padding-left: 18px;
  min-height: 42px;
  top: 1px;
  position: relative;
  color: #888;
  background-color: transparent;
  overflow: hidden;
  line-height: 22px;
}

/* ---------------------------------- */
/* Intro Banner
------------------------------------- */
.intro-banner {
  padding: 105px 0;
  position: relative;
}

.intro-banner.big-padding {
  padding: 230px 0;
}

.intro-banner .container {
  z-index: 100;
  position: relative;
}

.intro-banner:after,
.intro-banner:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  display: block;
  top: 0;
  left: 0;
  z-index: 15;
  background: linear-gradient(
    to right,
    rgba(250, 250, 250, 1) 20%,
    rgba(250, 250, 250, 0.95) 50%,
    rgba(250, 250, 250, 0.1) 80%,
    rgba(250, 250, 250, 0) 100%
  );
}

.intro-banner:after {
  z-index: 5;
  background-color: #fafafa;
}

.intro-banner .background-image-container {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 100% 60%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 90%;
  left: 10%;
  z-index: 10;
  overflow: hidden;
}

/* Disabled Gradient */
.intro-banner.disable-gradient:before {
  background: #f2f2f2;
  opacity: 0.85;
}

.intro-banner.disable-gradient .background-image-container {
  left: 0;
  width: 100%;
}

/* Banner Headline */
.banner-headline {
  display: block;
  max-width: 55%;
}

.banner-headline h1,
.banner-headline h2,
.banner-headline h3,
.banner-headline h4 {
  font-size: 28px;
  font-weight: 300;
  color: #777;
  line-height: 42px;
}

.banner-headline strong {
  font-weight: 600;
  color: #333;
}

.banner-headline strong.color {
  color: #66676b;
}

/* Alternative Headline */
.banner-headline-alt {
  margin: -10px 0 0 0;
  display: block;
}

.banner-headline-alt h3,
.banner-headline-alt h1 {
  font-size: 40px;
  font-weight: 600;
  color: #333;
  line-height: 50px;
}

.banner-headline-alt span {
  font-size: 24px;
  color: #888;
  font-weight: 300;
  line-height: 34px;
  margin-top: 5px;
  display: inline-block;
}
.dark-overlay .banner-headline-alt h3,
.banner-headline-alt h1 {
  color: #fff;
}
.dark-overlay .banner-headline-alt span {
  color: rgba(255, 255, 255, 0.7);
}

/* Banner Search Form */
.intro-banner-search-form {
  display: flex;
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.09);
}

/* Video */
.video-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  overflow: hidden;
  background-size: cover;
  background-position: 50%;
}

.video-container video {
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -10;
  background-size: cover;
  transition: 1s opacity;
  position: absolute;
  z-index: 9;
}

/* Field Row Styles */
.intro-search-field {
  padding: 10px 12px;
  border-right: 1px solid #e4e4e4;
  flex: 1;
  align-items: center;
  display: flex;
  position: relative;
}

.intro-search-field .field-title {
  content: attr(data-field-tiele);
  position: absolute;
  top: 0;
  left: 0;
  background: #66676b;
  color: #fff;
  border-radius: 4px;
  padding: 5px 10px;
  line-height: 22px;
  transform: translateY(calc(-100% - 15px));
  cursor: default;
}

.intro-banner-search-form .intro-search-field:nth-last-child(2) {
  border-right: none;
}

.intro-search-button {
  margin: 8px;
  margin-left: 0;
  flex: auto;
  flex-grow: 0;
  display: flex;
  align-items: center;
}

.intro-search-button .button {
  height: 52px;
  line-height: 52px;
  padding: 0 30px;
  font-size: 18px;
}

/* Field Styles */
.intro-banner-search-form input:focus {
  box-shadow: none;
}
.intro-banner-search-form input {
  box-shadow: none;
  font-size: 18px;
  margin: 0;
}

.intro-banner-search-form input::placeholder {
  color: #888;
}

.intro-banner-search-form .input-with-icon {
  align-self: center;
  flex: 1;
}

.intro-banner-search-form input {
  padding: 10px 15px;
}
.intro-banner-search-form .input-with-icon input {
  padding: 10px 15px;
  padding-left: 43px;
}

.intro-banner-search-form .input-with-icon i {
  position: absolute;
  right: auto;
  left: 10px;
  top: calc(50% - 1px);
  font-size: 24px;
  color: #b0b0b0;
}

/* Autocomplete Adjustment */
.intro-search-field.with-autocomplete .pac-container {
  border: none;
  padding-top: 0;
  z-index: 9;
  left: 0 !important;
  top: 100% !important;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  width: 100% !important;
  margin-top: 0;
  overflow: visible;
}

.intro-search-field.with-autocomplete .pac-container:before {
  content: "";
  position: absolute;
  background-color: #fff;
  width: 100%;
  height: 5px;
  display: block;
  top: -5px;
  left: 0;
}

/* Dropdown Adjustment */
.intro-search-field .bootstrap-select.btn-group button {
  padding: 0 15px;
  box-shadow: none;
  font-size: 18px;
}

/* IE 11 Fixes */
@media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
  .intro-search-field .field-title {
    transform: translateY(-100%);
    margin-top: -15px;
  }
  .intro-banner-search-form input {
    line-height: 20px;
  }
  .intro-search-field .bootstrap-select.open .dropdown-menu {
    top: 55px;
  }
}
/* IE 11 Fixes - End */

/* Intro Stats */
.intro-stats {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.intro-stats li {
  flex: auto;
  flex-grow: 0;
  margin-right: 35px;
  padding-right: 35px;
  border-right: 1px solid #e0e0e0;
}

.intro-stats li:last-child {
  margin-right: 0;
  padding-right: 0;
  border-right: none;
}

.intro-stats li span,
.intro-stats li strong {
  display: block;
  margin: 0;
  padding: 0;
}

.intro-stats li strong {
  font-weight: 600;
  color: #333;
  font-size: 28px;
  margin-bottom: 2px;
}

.intro-stats li span {
  font-weight: 300;
  color: #888;
  font-size: 18px;
}

/* Dark Style */
.intro-banner.dark-overlay:before {
  background: #333;
  opacity: 0.75;
}

.intro-banner.dark-overlay .background-image-container {
  left: 0;
  width: 100%;
  background-position: 100%;
}

.intro-banner.dark-overlay .intro-stats li span,
.intro-banner.dark-overlay .banner-headline strong,
.intro-banner.dark-overlay .banner-headline h1,
.intro-banner.dark-overlay .banner-headline h2,
.intro-banner.dark-overlay .banner-headline h3,
.intro-banner.dark-overlay .banner-headline h4 {
  color: rgba(255, 255, 255, 0.7);
}

.intro-banner.dark-overlay .intro-stats li strong,
.intro-banner.dark-overlay .banner-headline strong {
  color: #fff;
}
.intro-banner.dark-overlay .intro-banner-search-form {
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.2);
}
.intro-banner.dark-overlay .intro-stats li {
  border-right: 1px solid rgba(255, 255, 255, 0.25);
}
.intro-banner.dark-overlay .intro-stats li:last-child {
  border-right: none;
}

/* Intro Banner Media Queries
------------------------------------- */
@media (max-width: 1366px) {
  .banner-headline h1,
  .banner-headline h2,
  .banner-headline h3,
  .banner-headline h4 {
    font-size: 26px;
    line-height: 42px;
  }
}

@media (max-width: 1240px) {
  .banner-headline {
    max-width: 65%;
  }
}

@media (max-width: 992px) {
  .intro-search-field
    .bootstrap-select.btn-group
    .dropdown-toggle
    .filter-option {
    height: auto;
    line-height: 20px;
  }

  .intro-banner {
    padding: 70px 0;
  }

  .banner-headline {
    max-width: 100%;
  }
  .banner-headline h1,
  .banner-headline h2,
  .banner-headline h3,
  .banner-headline h4 {
    font-size: 24px;
    line-height: 38px;
  }

  /* Alternative Banner Style */
  .intro-banner.dark-overlay .intro-banner-search-form {
    box-shadow: none;
  }
  .intro-banner.big-padding {
    padding: 70px 0;
  }

  .banner-headline-alt {
    margin-bottom: 0;
  }
  .banner-headline-alt h3 {
    font-size: 36px;
    line-height: 42px;
  }
  .banner-headline-alt span {
    font-size: 22px;
    line-height: 32px;
  }

  /* Photo Position */
  .intro-banner:after,
  .intro-banner:before {
    background: rgba(250, 250, 250, 0.9);
  }

  .intro-banner .background-image-container {
    background-size: cover;
    height: 100%;
    width: 100%;
    left: 0;
  }
  /* Photo Position - End*/

  .intro-banner-search-form
    .intro-search-field
    .bootstrap-select.btn-group
    button,
  .intro-banner-search-form input {
    height: 56px;
    padding: 15px 22px;
  }

  .intro-banner-search-form .input-with-icon i {
    left: 17px;
  }
  .intro-banner-search-form .input-with-icon input {
    padding-left: 50px;
  }

  .intro-banner-search-form.margin-top-100,
  .intro-banner-search-form.margin-top-95,
  .intro-banner-search-form.margin-top-90,
  .intro-banner-search-form.margin-top-85 {
    margin-top: 20px !important;
  }

  .intro-banner-search-form {
    flex-direction: column;
    background: transparent;
    box-shadow: none;
    margin-top: 20px;
  }

  .intro-banner-search-form .intro-search-field {
    padding: 0;
    border-radius: 4px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.07);
    margin-top: 60px;
  }

  .intro-banner-search-form .intro-search-field.with-label {
    margin-top: 80px;
  }

  .intro-banner-search-form .intro-search-button {
    margin-top: 30px;
  }

  /* Intro Stats */
  .intro-stats {
    flex-direction: column;
    background: #fff;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    justify-content: center;
  }

  .intro-stats li {
    display: flex;
    flex-direction: column;
    margin: 0 0 10px 0;
    padding: 0 0 10px 0;
    border: none;
    border-bottom: 1px solid #eee;
    width: 100%;
    align-self: center;
    text-align: center;
  }

  .intro-stats li:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border: none;
  }
}

/* ---------------------------------- */
/* Category Boxes
------------------------------------- */
.categories-container {
  display: flex;
  flex-wrap: wrap;
}

.category-box {
  flex: 0 0 25%;
  align-content: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin: 0;
  text-align: center;
  padding: 25px;
  border-radius: 4px;
  transition: 0.35s;
}

/* IE 11 Fixes */
@media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
  .category-box {
    max-width: 25%;
    text-align: center;
  }
  .category-box-counter {
    width: 50px !important;
  }
}
/* IE 11 Fixes - End */

/* Box Icon */
.category-box-icon {
  font-size: 42px;
  line-height: 32px;
  color: #66676b;
  position: relative;
}

.category-box-icon i {
  height: 42px;
  display: block;
  transition: 0.35s;
  margin-bottom: 12px;
}

/* Box Counter */
.category-box-counter {
  width: auto;
  height: 24px;
  font-size: 14px;
  line-height: 25px;
  border-radius: 4px;
  padding: 0 8px;
  color: #909090;
  background: rgba(0, 0, 0, 0.06);
  font-weight: 600;
  display: inline-block;
  margin: 0 auto;
  transition: 0.35s;
  margin-bottom: 18px;
}

/* Box Content */
.category-box-content h3 {
  font-size: 16px;
  font-weight: 600;
  color: #333;
  margin: 0;
  padding: 0;
  transition: 0.35s;
}

.category-box-content p {
  color: #888;
  margin: 0;
  padding: 0;
  line-height: 24px;
  margin-top: 5px;
  transition: 0.35s;
}

/* Hover State */
.category-box:hover {
  background: #66676b;
  box-shadow: 0 4px 12px rgba(102, 103, 107, 0.2);
  color: #fff;
}

.category-box:hover .category-box-content h3,
.category-box:hover .category-box-icon {
  color: #fff;
}
.category-box:hover .category-box-content p {
  color: rgba(255, 255, 255, 0.7);
}

.category-box:hover .category-box-counter {
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
}

/* Media Queries for Category Boxes */
@media (max-width: 1240px) {
  .category-box {
    flex: 0 0 33.3%;
  }
}

@media (max-width: 768px) {
  .category-box {
    flex: 0 0 50%;
  }
}

@media (max-width: 480px) {
  .category-box {
    flex: 0 0 100%;
    margin-right: 0;
  }
}

/* ---------------------------------- */
/* Photo Box
------------------------------------- */
.photo-box {
  border-radius: 4px;
  height: 360px;
  display: block;
  position: relative;
  background-size: cover;
  background-position: 100%;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  transition: 0.4s;
  margin: 0 0 25px 0;
}

.photo-box:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #333;
  opacity: 0.5;
  border-radius: 4px;
  transition: 0.4s;
}

.photo-box-content {
  position: absolute;
  bottom: 30px;
  left: 30px;
}

.photo-box-content h3 {
  color: #fff;
  font-size: 20px;
  line-height: 28px;
}

.photo-box-content span {
  color: #333;
  background: #fff;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 500;
  padding: 4px 10px;
  line-height: 20px;
  margin-top: 8px;
  display: inline-block;
}

/* Hover State */
.photo-box:hover {
  box-shadow: 0 4px 12px rgba(102, 103, 107, 0.25);
  transform: translateY(-10px);
}

.photo-box:hover:before {
  background: #66676b;
  opacity: 0.95;
}

/* Small Photobox Styles */
.photo-box.small {
  height: 160px;
  margin-bottom: 30px;
}
.photo-box.small:hover {
  transform: translateY(-5px);
}

.photo-box.small .photo-box-content {
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  bottom: auto;
  top: 50%;
  transform: translateY(-49%);
}

.photo-box.small .photo-box-content h3 {
  font-size: 16px;
  font-weight: 600;
}

.photo-box.small .photo-box-content span {
  color: #fff;
  background: #66676b;
  font-size: 14px;
  line-height: 18px;
  margin-top: 5px;
  transition: 0.3s;
}

.photo-box.small:hover .photo-box-content span {
  color: #fff;
  background: rgba(0, 0, 0, 0.2);
}

/* ---------------------------------- */
/* Pricing Plans
------------------------------------- */
.billing-cycle-radios {
  display: block;
  margin: 0 auto;
  text-align: center;
}

.billing-cycle-radios.text-align-left {
  text-align: left;
}

.billing-cycle-radios .radio {
  margin-right: 20px;
}
.billing-cycle-radios .radio:last-child {
  margin-right: 0;
}

/* Pricing Plans Container */
.pricing-plans-container {
  border-radius: 4px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
  display: flex;
}

.pricing-plan {
  flex: 1;
  padding: 35px 17px;
  position: relative;
}

.pricing-plan:first-of-type {
  padding-left: 35px;
}

.pricing-plan:last-of-type {
  padding-right: 35px;
}

.pricing-plan h3 {
  font-size: 20px;
  font-weight: 600;
}

.pricing-plan p {
  margin: 0;
}

/* Label Switcher */
.billed-yearly-label {
  display: none;
}
.billed-yearly .billed-yearly-label {
  display: block;
}
.billed-yearly .billed-monthly-label {
  display: none;
}

/* Pricing Plan Label */
.pricing-plan-label {
  background: #f6f6f6;
  border-radius: 4px;
  font-size: 18px;
  color: #888;
  text-align: center;
  line-height: 24px;
  padding: 15px;
  margin: 22px 0;
}

.pricing-plan-label strong {
  font-size: 32px;
  font-weight: 700;
  color: #333;
  margin-right: 5px;
  line-height: 30px;
}

.recommended .pricing-plan-label {
  background-color: rgba(102, 103, 107, 0.06);
  color: #66676b;
}
.recommended .pricing-plan-label strong {
  color: #66676b;
}

/* Pricing Plan Features */
.pricing-plan-features strong {
  color: #333;
  font-weight: 600;
  margin-bottom: 5px;
  line-height: 24px;
  display: inline-block;
}

.pricing-plan-features ul {
  padding: 0;
  margin: 0;
}

.pricing-plan-features ul li {
  display: block;
  margin: 0;
  padding: 3px 0;
  line-height: 24px;
}

/* Pricing Plan Button */
.pricing-plan .button:hover,
.pricing-plan.recommended .button {
  color: #fff;
  background-color: #66676b;
  box-shadow: 0 4px 12px rgba(102, 103, 107, 0.15);
}

.pricing-plan .button {
  color: #66676b;
  background-color: #fff;
  border: 1px solid #66676b;
  box-shadow: 0 4px 12px rgba(102, 103, 107, 0.1);
}

.pricing-plan .button:hover {
  box-shadow: 0 4px 12px rgba(102, 103, 107, 0.15);
}

/* Recommended Plan */
.pricing-plan.recommended {
  box-shadow: 0 0px 45px rgba(0, 0, 0, 0.09);
  padding: 35px;
  margin: 0 15px;
}

.pricing-plan .recommended-badge {
  background-color: #66676b;
  color: #fff;
  position: absolute;
  width: 100%;
  height: 45px;
  top: -45px;
  left: 0;
  text-align: center;
  border-radius: 4px 4px 0 0;
  font-weight: 600;
  line-height: 45px;
}

/* Media Queries for Pricing Plans */
@media (max-width: 992px) {
  .pricing-plans-container {
    box-shadow: none;
    display: block;
    margin-top: -40px;
  }

  .pricing-plan {
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
    margin-bottom: 40px;
    flex: auto;
    width: 100%;
    border-radius: 4px;
    padding: 35px;
  }

  .pricing-plan.recommended {
    padding: 35px;
    margin: 0 0 40px 0;
  }

  .recommended.pricing-plan {
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
    margin-top: 80px;
  }
}

/* ---------------------------------- */
/* Checkout Page
------------------------------------- */

/* Checkout Headline */
.headline-border-top {
  border-top: 1px solid #e0e0e0;
}

/* Boxed Widget */
.boxed-widget {
  background-color: #f9f9f9;
  padding: 0;
  transform: translate3d(0, 0, 0);
  z-index: 90;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
}

.boxed-widget-headline {
  color: #333;
  font-size: 20px;
  padding: 20px 30px;
  background-color: #f0f0f0;
  color: #333;
  position: relative;
  border-radius: 4px 4px 0 0;
}

.boxed-widget-headline h3 {
  font-size: 20px;
  padding: 0;
  margin: 0;
}

.boxed-widget-inner {
  padding: 30px;
}

.boxed-widget ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.boxed-widget ul li span {
  float: right;
  color: #333;
  font-weight: 600;
}

.boxed-widget ul li {
  color: #666;
  padding-bottom: 1px;
}

.boxed-widget.summary li.total-costs {
  font-size: 18px;
  border-top: 1px solid #e4e4e4;
  padding-top: 18px;
  margin-top: 18px;
}

.boxed-widget-footer {
  border-top: 1px solid #e4e4e4;
  width: 100%;
  padding: 20px 30px;
}

.boxed-widget-footer .checkbox label {
  margin-bottom: 0;
}

.boxed-widget.summary li.total-costs span {
  font-weight: 700;
  color: #66676b;
}

.listing-item-container.compact.order-summary-widget .listing-item {
  border-radius: 4px 4px 0 0;
  cursor: default;
  height: 240px;
}

.listing-item-container.compact.order-summary-widget {
  margin-bottom: 0;
}
.listing-item-container.compact.order-summary-widget:hover {
  transform: none;
}

/* Billing Cycle
------------------------------------- */
.billing-cycle {
  display: flex;
}

.billing-cycle .radio {
  flex: 1;
  margin: 5px 20px 5px 0;
}

.billing-cycle .radio label {
  border-radius: 4px;
  border: 2px solid #eee;
  padding: 25px;
  height: 100%;
  align-self: center;
}

.billing-cycle .radio:last-of-type {
  margin-right: 0;
}
.billing-cycle .radio input[type="radio"] + label .radio-label {
  position: relative;
  top: 2px;
  margin-right: 7px;
}

.billing-cycle-details {
  display: block;
  padding-left: 30px;
}

.discounted-price-tag,
.regular-price-tag {
  font-size: 14px;
  background: #e0f5d7;
  color: #449626;
  border-radius: 4px;
  line-height: 20px;
  padding: 4px 10px;
  flex-grow: 0;
  flex: auto;
  width: auto;
  transition: 0.3s;
  margin-top: 6px;
  margin-right: 5px;
  display: inline-block;
}

.line-through {
  text-decoration: line-through;
  background-color: #fbf6dd;
  color: #a18d29;
}

/* Media Queries for Billing Cycle */
@media (max-width: 768px) {
  .billing-cycle {
    display: flex;
    flex-direction: column;
  }
  .billing-cycle .radio {
    margin-right: 0;
  }
}

/* Payment Toggle
------------------------------------- */
.payment-logo {
  height: 24px;
  position: absolute;
  right: 19px;
  top: 18px;
  image-rendering: -webkit-optimize-contrast;
}

.payment-logo.paypal {
  height: 16px;
  top: 22px;
}

.payment-tab-trigger > input {
  position: absolute;
  visibility: hidden;
}

.payment-tab-trigger {
  padding: 0px 20px;
}

.payment-tab-trigger > label {
  cursor: pointer;
  display: block;
  position: relative;
  top: 0;
  padding: 14px 0;
  top: 3px;
  margin-bottom: 0;
  font-weight: 500;
}

.payment-tab-trigger > label::before {
  transition: 0.2s;
  box-sizing: border-box;
  background-color: #fff;
  border: 2px solid #d0d0d0;
  border-radius: 100%;
  content: "";
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: 3px;
  width: 18px;
  height: 18px;
}

.payment-tab-trigger > label::after {
  background: #fff;
  border-radius: 100%;
  content: "";
  display: inline-block;
  position: absolute;
  left: 5px;
  top: 22px;
  width: 8px;
  height: 8px;
  transition: 0.2s;
  transform: scale(0);
}

.payment-tab-trigger > input:checked ~ label::before {
  border-color: #66676b;
}

.payment-tab-trigger > input:checked ~ label::after {
  background-color: #66676b;
  transform: scale(1);
}

.payment {
  border: 2px solid #f2f2f2;
  border-radius: 4px;
  overflow: hidden;
}

.payment-tab {
  background: #f2f2f2;
  box-sizing: border-box;
  max-height: 60px;
  overflow: hidden;
  position: relative;
  transition: all 0.3s 0s ease-in-out;
  width: 100%;
}

.payment-tab:first-of-type {
  border-radius: 4px 4px 0 0;
}
.payment-tab:last-of-type {
  border-radius: 0 0 4px 4px;
}

.payment-tab.payment-tab-active {
  max-height: 400px;
  background: #fff;
}

@media (max-width: 991px) {
  .payment-tab.payment-tab-active {
    max-height: 800px;
  }
}

.payment-tab-content {
  padding: 0 20px 20px 20px;
  background-color: #fff;
  visibility: hidden;
  opacity: 0;
  transition: 0.2s ease-in-out;
}

.payment-form-row {
  margin-top: -10px;
  margin-bottom: 5px;
}

.payment-tab.payment-tab-active .payment-tab-content {
  visibility: visible;
  opacity: 1;
}

.card-label > input {
  box-sizing: border-box;
  display: block;
  height: 51px;
  margin: 14px auto 5px;
  padding: 5px 15px 0 15px;
  transition: border-color 0.3s;
  width: 100%;
  border: 1px solid #dbdbdb;
  background-color: #fff;
  text-decoration: none;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0px rgba(0, 0, 0, 0.06);

  box-shadow: none;
  padding: 15px 0;
  border: none;
  border-bottom: 1px solid #e0e0e0;
  border-radius: 0;
}

.card-label > input:focus {
  box-shadow: none;
  border-bottom: 1px solid #e0e0e0;
  padding: 15px 0;
}

.card-label > input::placeholder {
  transition: 0.2s ease-in-out;
  -webkit-transform-origin: left;
}

.card-label > input:focus::placeholder {
  transform: translateY(-18px);
}

.card-label > label {
  background-color: #fff;
  display: inline-block;
  margin: 6px auto auto 8px;
  font-size: 13px;
  padding: 0px 7px;
  font-weight: 500;
  color: #909090;
  display: none;
}

.payment-tab p {
  margin: 10px 0;
}

/* Booking Confirmation Page
------------------------------------- */
.order-confirmation-page {
  text-align: center;
  padding-bottom: 120px;
  padding-top: 0px;
}

.order-confirmation-page h2 {
  font-size: 48px;
  font-weight: 700;
  letter-spacing: -0.5px;
  margin-bottom: 25px;
  display: block;
}

.order-confirmation-page p {
  font-size: 20px;
  color: #808080;
  font-weight: 300;
  display: block;
  line-height: 32px;
}

.order-confirmation-page a.button:before {
  display: none;
}
.order-confirmation-page a.button {
  background-color: #eee;
  color: #606060;
  font-weight: 600;
  font-size: 18px;
  box-shadow: none;
  padding-top: 14px;
  padding-bottom: 14px;
}

.order-confirmation-page a.button i {
  font-size: 24px;
}

.book-now.button {
  padding: 9px;
  margin-bottom: -4px;
}

.breathing-icon {
  background-color: #66676b;
  width: 120px;
  height: 120px;
  line-height: 0;
  font-size: 18px;
  border-radius: 50%;
  margin: 0 auto;
  margin-top: 30px;
  position: relative;
  z-index: 100;
  transition: 0.4s;
  color: #fff;
  font-weight: 500;
  animation: bicokAnim 3s infinite;
  box-shadow: 0 0 0 15px rgba(102, 103, 107, 0.07);
  margin-bottom: 70px;
  font-size: 90px;
}

.breathing-icon i {
  color: #fff;
  position: absolute;
  top: 53%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

@keyframes bicokAnim {
  0%,
  100% {
    box-shadow: 0 0 0 15px rgba(102, 103, 107, 0.07);
  }
  50% {
    box-shadow: 0 0 0 28px rgba(102, 103, 107, 0.12);
  }
}

@media (max-width: 991px) {
  .order-confirmation-page i {
    font-size: 100px;
  }
  .order-confirmation-page p {
    font-size: 18px;
  }
  .order-confirmation-page h2 {
    font-size: 30px;
  }
}

/* ---------------------------------- */
/* Icon Boxes
------------------------------------- */
.icon-box {
  text-align: center;
  margin-top: 45px;
  position: relative;
}

.icon-box-circle {
  background-color: #fff;
  width: 50%;
  height: 60px;
  z-index: 100;
  margin: 0 auto;
  display: inline-block;
  position: relative;
  margin-bottom: 35px;
}

.icon-box-circle-inner {
  font-size: 72px;
  color: #ccc;
  position: relative;
  width: 60px;
  margin: 0 auto;
  display: inline-block;
}

.icon-box-check {
  background-color: #66676b;
  width: 30px;
  height: 30px;
  line-height: 32px;
  font-size: 18px;
  border-radius: 50%;
  margin: 0 auto;
  position: relative;
  z-index: 100;
  animation: circle-box-anim 2s infinite;
  transition: 0.4s;
  color: #fff;
  font-weight: 500;
  position: absolute;
  bottom: -3px;
  right: -10px;
  animation: checkAnim 2s infinite;
  box-shadow: 0 0 0 5px rgba(102, 103, 107, 0.1);
}

@keyframes checkAnim {
  0%,
  100% {
    box-shadow: 0 0 0 5px rgba(102, 103, 107, 0.15);
  }
  50% {
    box-shadow: 0 0 0 8px rgba(102, 103, 107, 0.15);
  }
}

.icon-box span {
  color: #666;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
}

.icon-box h4 {
  margin: 4px 0;
  line-height: 26px;
}

.icon-box h3 {
  margin: 4px 0;
  line-height: 26px;
  font-size: 22px;
  font-weight: 500;
}

.icon-box p {
  margin: 0;
  margin-top: 25px;
  font-style: 16px;
  padding: 0 10px;
  color: #808080;
}

.icon-box.with-line:before {
  content: "";
  height: 1px;
  width: 100%;
  position: absolute;
  left: 50%;
  top: 40px;
  background: #e9e9e9;
  display: block;
  z-index: 9;
}

@media (max-width: 992px) {
  .icon-box.with-line:before {
    display: none;
  }
}

/* ---------------------------------- */
/* Testimonials
------------------------------------- */
.testimonial-box {
  background: transparent;
  border-radius: 6px;
  padding: 45px;
  padding-top: 35px;
  color: #888;
  position: relative;
  margin: 70px 0 0;
  box-shadow: none;
  transition: 0.4s;
  cursor: grab;
  text-align: center;
  transform: scale(0.8);
}

.testimonial-box:before {
  content: "\e948";
  font-family: "Material-Icons";
  position: absolute;
  top: -35px;
  left: 0;
  font-size: 80px;
  transform: translateY(-100%);
  color: #66676b;
  opacity: 0;
  transition: 0.3s;
}

.testimonial-carousel .slick-slide {
  margin: 0 20px 30px 20px;
  opacity: 0;
  transition: 0.4s;
  pointer-events: all;
}

.testimonial-carousel .slick-slide.slick-active {
  opacity: 1;
  filter: none;
  pointer-events: all;
}

.testimonial-carousel .slick-slide.slick-active .testimonial-box {
  transform: scale(1);
}

.testimonial-carousel .slick-slide.slick-active .testimonial-box:before {
  opacity: 1;
}

.testimonial-carousel .slick-slide.slick-active .testimonial-box {
  background: #fff;
  color: #666;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.08);
}

.testimonial,
.testimonial p {
  font-size: 18px;
  font-weight: 300;
  line-height: 30px;
  color: #808080;
  line-height: 32px;
}

.testimonial-author {
  display: block;
  width: 100%;
  padding: 20px 0 15px 0;
  text-align: center;
  margin: 0 auto;
}

.testimonial-avatar {
  position: absolute;
  top: 0;
  transform: translateY(-50%);
  margin: 0 auto;
  left: 0;
  right: 0;
}

.testimonial-avatar img {
  width: 70px;
  display: inline-block;
  text-align: center;
  height: auto;
  border-radius: 50%;
  margin: 0 auto;
}

.testimonial-author h4 {
  display: block;
  width: 100%;
  font-size: 18px;
  padding-top: 0;
  color: #333;
  font-weight: 600;
}

.testimonial-author span {
  display: block;
  padding-top: 0;
  display: inline-block;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 300;
  line-height: 23px;
  padding: 3px 12px;
  margin: 5px 0 10px 0;
  background-color: #66676b;
  color: #fff;
  box-shadow: 0 2px 10px rgba(102, 103, 107, 0.25);
}

.testimonial-box .star-rating {
  height: 30px;
  display: inline-block;
  margin-bottom: -10px;
  margin-top: 15px;
}

.testimonial-carousel .slick-next {
  right: 25%;
}
.testimonial-carousel .slick-prev {
  left: 25%;
}

.testimonial-carousel .slick-next,
.testimonial-carousel .slick-prev {
  margin-top: 35px;
}

/* Media Queries for Testimonials */
@media (max-width: 1600px) {
  .testimonial-carousel .slick-next {
    right: 15%;
  }
  .testimonial-carousel .slick-prev {
    left: 15%;
  }
}

@media (max-width: 1240px) {
  .testimonial-carousel .slick-next {
    right: 13%;
  }
  .testimonial-carousel .slick-prev {
    left: 13%;
  }
}

@media (max-width: 992px) {
  .testimonial-carousel .slick-next {
    right: 8%;
  }
  .testimonial-carousel .slick-prev {
    left: 8%;
  }
  .testimonial-box {
    padding: 35px;
  }
  .testimonial,
  .testimonial p {
    font-size: 16px;
    line-height: 28px;
  }
}

@media (max-width: 768px) {
  .testimonial-carousel .slick-next {
    right: 20px;
  }
  .testimonial-carousel .slick-prev {
    left: 20px;
  }

  .testimonial-carousel .slick-next,
  .testimonial-carousel .slick-prev {
    transform: scale(0.8) translateY(-50%);
  }
}

/* Logo Carousel */
.logo-carousel .carousel-item {
  display: inline-block;
  height: 120px;
  text-align: center;
  position: relative;
}

.logo-carousel .carousel-item a {
  display: inline-block;
}

.logo-carousel .carousel-item img {
  max-height: 60%;
  max-width: 60%;
  margin: 0 auto;
  left: 0;
  right: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.logo-carousel .slick-prev,
.logo-carousel .slick-next {
  top: 50%;
}

/* Blog Carousel */
.blog-carousel {
  width: calc(100% + 30px);
  left: -15px;
}

.blog-carousel .blog-compact-item-container {
  margin: 15px;
  display: inline-block;
  width: 30%;
}

.blog-carousel.slick-initialized .blog-compact-item-container {
  width: auto;
}

.full-width-carousel-fix .default-slick-carousel.slick-initialized .freelancer,
.full-width-carousel-fix
  .blog-carousel.slick-initialized
  .blog-compact-item-container {
  margin: 15px;
  transform: scale(0.85);
  opacity: 0;
  pointer-events: none;
  transition: 0.4s;
}

.full-width-carousel-fix
  .default-slick-carousel.slick-initialized
  .slick-slide.slick-active.freelancer,
.full-width-carousel-fix
  .blog-carousel.slick-initialized
  .slick-slide.slick-active.blog-compact-item-container {
  transform: scale(1);
  opacity: 1;
  pointer-events: all;
}

.full-width-carousel-fix
  .blog-carousel.slick-initialized
  .slick-slide.slick-active.blog-compact-item-container:hover {
  transform: scale(1) translateY(-10px);
}

/* ---------------------------------- */
/* Text Banner
------------------------------------- */
.photo-section {
  background-size: cover;
  background-position: 50%;
  display: block;
  position: relative;
}

.photo-section:before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #333;
  opacity: 0.65;
  display: block;
  position: absolute;
  top: 0;
}

/* Styles for info banner with parallax */
.text-content {
  background: transparent;
  padding: 110px 0px;
  font-weight: 500;
  z-index: 99;
  position: relative;
}

.text-content.white-font,
.text-content h1,
.text-content h2,
.text-content h3,
.text-content h4,
.text-content h5,
.text-content.white-font h1,
.text-content.white-font h2,
.text-content.white-font h3,
.text-content.white-font h4,
.text-content.white-font h5 {
  color: #fff;
}

.text-content a.button {
  font-size: 16px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
}

.text-content p {
  font-size: 18px;
  font-weight: 300;
  line-height: 31px;
  opacity: 0.85;
}

.text-content h2 {
  margin-bottom: 23px;
  line-height: 46px;
  font-size: 34px;
  font-weight: 500;
}

.top-1 {
  position: relative;
  top: -1px;
}
.top-0 {
  position: relative;
}

@media (max-width: 992px) {
  .text-content {
    padding: 80px 0;
  }

  .text-content h2 {
    margin-bottom: 20px;
    line-height: 40px;
    font-size: 30px;
    font-weight: 500;
  }
}

/* ---------------------------------- */
/* Counters
------------------------------------- */
.counters-container {
  display: flex;
  flex-wrap: wrap;
}

.single-counter {
  flex: 1;
  text-align: center;
  position: relative;
  border-right: 1px solid #e0e0e0;
  margin-top: 80px;
}

.single-counter:last-child {
  border-right: 0;
}

.single-counter i {
  font-size: 64px;
  color: #ccc;
  position: absolute;
  top: -20px;
  transform: translateY(-100%);
  left: 0;
  right: 0;
}

.single-counter h3 {
  font-size: 36px;
  font-weight: 600;
  color: #66676b;
  margin-bottom: 7px;
}

.single-counter .counter-title {
  color: #888;
  padding-top: 0;
  display: inline-block;
  border-radius: 4px;
  font-size: 18px;
}

/* Media Queries for Counters */
@media (max-width: 992px) {
  .single-counter {
    flex: 1 1 50%;
    margin-bottom: 30px;
  }
  .single-counter:nth-child(2) {
    border: none;
  }
  .counters-container {
    margin-bottom: -30px;
  }
}

/* Media Queries for Counters */
@media (max-width: 768px) {
  .single-counter {
    flex: 1 1 100%;
    border: none;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 30px;
  }
  .single-counter:nth-child(2) {
    border-bottom: 1px solid #e0e0e0;
  }
  .single-counter:last-child {
    margin-bottom: -15px;
    border-bottom: none;
  }
  .counters-container {
    margin-bottom: -30px;
  }
}

/* ---------------------------------- */
/* Countdown
------------------------------------- */
.countdown {
  border-radius: 4px;
  width: 100%;
  display: block;
  text-align: center;
  font-size: 18px;
  line-height: 28px;
  padding: 14px;
}

.countdown.green {
  background-color: #e7f8ec;
  color: #289c41;
}

.countdown.yellow {
  background-color: #fbf6dd;
  color: #a18d29;
}

/* ---------------------------------- */
/* Bidding Widget
------------------------------------- */
.bidding-widget {
  border-radius: 4px;
  background-color: #f9f9f9;
}

.dashboard-box .bidding-widget,
.popup-tab-content .bidding-widget {
  border-radius: 0;
  background-color: transparent;
}

.dashboard-box .slider-handle.custom,
.popup-tab-content .slider-handle.custom {
  border: 1px solid #e0e0e0;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.07);
}

.bidding-widget .bidding-headline {
  background-color: #f0f0f0;
  color: #333;
  position: relative;
  border-radius: 4px 4px 0 0;
}

.bidding-widget .bidding-inner {
  padding: 30px 35px;
}

.bidding-widget .bidding-headline {
  color: #333;
  font-size: 20px;
  padding: 20px 35px;
}

.bidding-widget .bidding-headline h3 {
  color: #333;
  font-size: 20px;
  padding: 0;
  margin: 0;
}

.bidding-widget .bidding-signup {
  color: #666;
  font-size: 14.7px;
  padding: 16px 35px;
  text-align: center;
  border-top: 1px solid #e0e0e0;
}

.bidding-widget .bidding-signup a {
  font-weight: 600;
}

.bidding-widget button.button {
  margin-bottom: 3px;
}

.bidding-widget .bidding-detail {
  color: #888;
  display: block;
}

.bidding-widget .bidding-detail strong {
  color: #333;
  font-weight: 700;
}

.dashboard-box .bidding-detail strong {
  font-weight: 600;
}

.bidding-widget .bidding-value {
  font-size: 26px;
  font-weight: 500;
  color: #333;
  margin: 5px 0 20px 0;
}

.bidding-widget .bidding-value {
  font-size: 26px;
  font-weight: 500;
  color: #333;
}

.bidding-widget .button {
  box-shadow: 0 4px 12px rgba(102, 103, 107, 0.15);
  transition: 0.3s;
  height: 46px;
}

.bidding-widget .button:hover {
  box-shadow: 0 4px 12px rgba(102, 103, 107, 0.25);
  transform: translateY(-2px);
}

.bidding-widget .bidding-fields {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: 12px;
  width: calc(100% + 20px);
}

.bidding-widget .bidding-fields .bidding-field {
  flex: 1;
  margin-right: 20px;
}

/* Quantity Buttons for Booking Widget*/
.qtyButtons {
  display: flex;
  margin: 0;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.12);
  height: 48px;
  padding: 6px;
}

.qtyButtons.with-border {
  border: 1px solid #e0e0e0;
  box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.05);
}

.qtyButtons input {
  outline: 0;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  width: 50px;
  height: 36px;
  color: #808080;
  line-height: 36px;
  margin: 0 !important;
  padding: 0 5px;
  border: none;
  box-shadow: none;
  display: inline-block;
  flex-grow: 1;
  box-shadow: none !important;
}

.qtyTitle {
  font-size: 16px;
  font-weight: 600;
  line-height: 36px;
  padding-right: 15px;
  display: block;
  flex: 1;
}

.qtyInc,
.qtyDec {
  width: 36px;
  height: 36px;
  line-height: 36px;
  font-size: 18px;
  background-color: #f0f0f0;
  color: #808080;
  display: inline-block;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
  font-family: "Feather-Icons";
  flex: 1;
  min-width: 36px;
  max-width: 36px;
  transition: 0.3s;
}

.qtyInc:before {
  content: "\e9b1";
}
.qtyDec:before {
  content: "\e996";
}

.qtyInc:hover,
.qtyDec:hover {
  background-color: #66676b;
  color: #fff;
}

.qtyTotal {
  background-color: #66676b;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  font-size: 11px;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  line-height: 18px;
  text-align: center;
  position: relative;
  top: -2px;
  left: 2px;
  height: 18px;
  width: 18px;
}

/* Media Queiers for Bidding Widget */
@media (max-width: 1366px) {
  .qtyInc,
  .qtyDec {
    min-width: 34px;
    max-width: 34px;
  }
  .bidding-widget .bidding-inner {
    padding: 27px 32px;
  }
  .bidding-widget .bidding-headline {
    padding: 20px 32px;
  }
}

@media (max-width: 1240px) {
  .qtyInc,
  .qtyDec {
    min-width: 36px;
    max-width: 36px;
  }

  .bidding-widget .bidding-fields .bidding-field {
    flex: auto;
    margin-right: 0;
    width: 100%;
  }

  .bidding-widget .bidding-fields .bidding-field:first-child {
    margin-bottom: 20px;
  }
  .bidding-widget .bidding-fields {
    width: 100%;
  }
}

/* -------------------------------- */
/* 404 Error Page
----------------------------------- */
#not-found {
  margin: 40px 0 80px 0;
}

#not-found.center {
  text-align: center;
}

#not-found h2 {
  text-align: center;
  font-size: 210px;
  line-height: 210px;
  font-weight: 500;
  letter-spacing: -8px;
}

#not-found p {
  text-align: center;
  font-size: 28px;
  line-height: 42px;
  display: inline-block;
  margin-top: 0px;
  color: #999;
}

#not-found i {
  color: #e0e0e0;
  font-size: 210px;
  top: 0;
  margin-left: -40px;
  position: relative;
}

.not-found-search.intro-banner-search-form .intro-search-field,
.not-found-search.intro-banner-search-form input,
.not-found-search.intro-banner-search-form {
  background: #f7f7f7;
  box-shadow: none;
}

@media (max-width: 992px) {
  .not-found-search.intro-banner-search-form {
    background: #fff;
  }
}

@media (max-width: 767px) {
  #not-found h2 {
    font-size: 110px;
    line-height: 110px;
  }

  #not-found p {
    font-size: 20px;
    line-height: 32px;
  }

  #not-found i {
    font-size: 120px;
    top: 5px;
    margin-left: 0;
  }

  .not-found-search.intro-banner-search-form {
    margin-top: -30px;
  }

  .login-register-page .account-type {
    width: 100%;
  }
  .login-register-page .account-type div {
    margin-right: 0;
  }
  .login-register-page .social-login-buttons button {
    margin-right: 0;
    width: 100%;
    display: block;
    max-width: 100%;
  }
}

.login-register-page .welcome-text h3 {
  font-size: 26px;
}

/* -------------------------------------------------------------- */
/* 05. Scripts Styles
----------------------------------------------------------------- */

/* ---------------------------------- */
/* Slick Slider
------------------------------------- */
/* Slider */
.slick-slider {
  position: relative;

  display: block;
  box-sizing: border-box;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;

  display: block;
  overflow: hidden;

  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;

  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before,
.slick-track:after {
  display: table;

  content: "";
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;

  height: 100%;
  min-height: 1px;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;

  height: auto;

  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
/*.slick-loading .slick-list {
    background: #fff url('./ajax-loader.gif') center center no-repeat;
}
*/

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  border: none;
  outline: none;
  background-color: #333;
  border-radius: 4px;
  width: 48px;
  height: 48px;
  display: block;
  top: calc(50% - 15px);
  transform: translateY(-50%);
  transition: 0.3s;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15);
  z-index: 10;
}

.slick-prev:hover,
.slick-next:hover {
  color: transparent;
  outline: none;
  background: #66676b;
}
.slick-prev:hover:before,
.slick-next:hover:before {
  opacity: 1;
}

.slick-prev.slick-disabled,
.slick-next.slick-disabled {
  pointer-events: none;
  cursor: default;
  background-color: #e8e8e8;
  box-shadow: none;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  color: #808080;
}

.slick-prev:before,
.slick-next:before {
  font-family: "Feather-Icons";
  font-size: 26px;
  line-height: 1;
  opacity: 1;
  transition: 0.3s;
  position: relative;
  color: #fff;
}

.slick-prev {
  left: -85px;
}
.slick-prev:before {
  content: "\e92f";
  left: -1px;
}

.slick-next {
  right: -85px;
}
.slick-next:before {
  content: "\e930";
  right: -1px;
}

/* Dots */
.slick-dots {
  margin: 0 auto;
  padding: 0;
  text-align: center;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  padding: 0;
  margin: 0 3px;
  cursor: pointer;
}

.slick-dots li {
  font-size: 0;
  line-height: 0;
  cursor: pointer;
  color: transparent;
  border: 0;
  position: relative;
  outline: none;
  display: inline-block;
  width: 15px;
  height: 15px;
  margin: 0 3px;
  opacity: 1;
  border-radius: 50%;
  line-height: 0;
  box-shadow: inset 0 0 0 2px #c0c0c0;
  transition: all 0.3s;
}

.slick-dots li.slick-active {
  box-shadow: inset 0 0 0 6px #c0c0c0;
  transition: all 0.3s;
}

.slick-dots li:after {
  display: block;
  height: 13px;
  width: 13px;
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  background-color: transparent;
  border-radius: 50%;
  transition: all 0.3s;
  opacity: 0;
  background-color: #c0c0c0;
}

.slick-dots li.slick-active:after {
  opacity: 1;
}

/* Reset */
.slick-slide {
  display: none;
  outline: none;
}
.slick-initialized .slick-slide {
  display: block;
}

.default-slick-carousel .slick-carousel-item {
  margin: 15px;
}

.default-slick-carousel {
  height: auto;
  width: calc(100% + 30px);
  left: -15px;
}

.default-slick-carousel,
.testimonial-carousel,
.logo-carousel,
.blog-carousel {
  display: none;
}

.default-slick-carousel.slick-initialized,
.testimonial-carousel.slick-initialized,
.logo-carousel.slick-initialized,
.blog-carousel.slick-initialized {
  display: block;
}

/* Custom */
.default-slick-carousel .freelancer {
  margin: 15px;
  width: 30%;
}
.default-slick-carousel.slick-initialized .freelancer {
  width: auto;
}
.default-slick-carousel .freelancer-details-list ul li {
  margin-right: 22px;
}
.default-slick-carousel .freelancer-details-list ul li:last-child {
  margin-right: 0px;
}

.default-slick-carousel .freelancer:hover {
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.12);
  transform: translateY(0px);
}

@media (min-width: 1367px) and (max-width: 1440px) {
  .slick-prev {
    left: -65px;
  }
  .slick-next {
    right: -65px;
  }

  .intro-banner.big-padding {
    padding: 120px 0;
  }
}

@media (max-width: 1290px) {
  .slick-prev {
    left: -10px;
  }
  .slick-next {
    right: -10px;
  }
}

@media (max-width: 1090px) {
  .slick-prev {
    left: 40px;
  }
  .slick-next {
    right: 40px;
  }
}

.full-width-carousel-fix {
  overflow: hidden;
}
.full-width-carousel-fix .slick-list {
  overflow: visible;
}

/* ---------------------------------- */
/* Simlpebar.js (custom scrollbar)
------------------------------------- */
[data-simplebar] {
  position: relative;
  z-index: 0;
  overflow: hidden !important;
  max-height: inherit;
  -webkit-overflow-scrolling: touch; /* Trigger native scrolling for mobile, if not supported, plugin is used. */
}

[data-simplebar="init"] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.simplebar-scroll-content {
  overflow-x: hidden !important;
  overflow-y: scroll;
  min-width: 100% !important;
  max-height: inherit !important;
  -webkit-box-sizing: content-box !important;
  box-sizing: content-box !important;
}

.simplebar-content {
  overflow-y: hidden !important;
  overflow-x: scroll;
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
  min-height: 100% !important;
}

.simplebar-track {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 11px;
  height: calc(100% - 6px);
  margin-top: 6px;
}

.simplebar-scrollbar {
  position: absolute;
  right: 6px;
  width: 5px;
  min-height: 10px;
}

.simplebar-scrollbar:before {
  position: absolute;
  content: "";
  background: black;
  border-radius: 7px;
  left: 0;
  right: 0;
  opacity: 0;
  transition: opacity 0.2s, background-color 0.3s;
  background-color: #d8d8d8;
}

.simplebar-scrollbar:hover:before {
  background-color: #c8c8c8;
}

.simplebar-track:hover .simplebar-scrollbar:before,
.simplebar-track .simplebar-scrollbar.visible:before {
  /* When hovered, remove all transitions from drag handle */
  opacity: 1;
  -webkit-transition: opacity 0 linear;
  transition: opacity 0 linear;
}

.simplebar-track.vertical {
  top: 0;
}

.simplebar-track.vertical .simplebar-scrollbar:before {
  top: 2px;
  bottom: 2px;
}

.simplebar-track.horizontal {
  left: 0;
  width: auto;
  height: 11px;
  display: none;
}

.simplebar-track.horizontal .simplebar-scrollbar:before {
  height: 100%;
  left: 2px;
  right: 2px;
}

.horizontal.simplebar-track .simplebar-scrollbar {
  right: auto;
  top: 2px;
  height: 7px;
  min-height: 0;
  min-width: 10px;
  width: auto;
}

body.gray .dashboard-content-container .simplebar-scrollbar:before {
  background-color: #c9c9c9;
}
body.gray .dashboard-content-container .simplebar-scrollbar:hover:before {
  background-color: #b9b9b9;
}
.dashboard-box .simplebar-scrollbar:before {
  background-color: #d8d8d8;
}
.dashboard-box .simplebar-scrollbarLhover:before {
  background-color: #c8c8c8;
}

/* ---------------------------------- */
/* Tippy.js Tooltips
------------------------------------- */

/* Dark */
.tippy-tooltip.dark-theme {
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  padding: 6px 12px;
}

.tippy-tooltip.dark-theme .tippy-backdrop {
  background-color: #333;
}

.tippy-popper[x-placement^="top"] .tippy-tooltip.dark-theme .tippy-arrow {
  border-top-color: #333;
}
.tippy-popper[x-placement^="bottom"] .tippy-tooltip.dark-theme .tippy-arrow {
  border-bottom-color: #333;
}
.tippy-popper[x-placement^="right"] .tippy-tooltip.dark-theme .tippy-arrow {
  border-right-color: #333;
}
.tippy-popper[x-placement^="left"] .tippy-tooltip.dark-theme .tippy-arrow {
  border-left-color: #333;
}

.tippy-tooltip.dark-theme .tippy-arrow {
  transform: scale(0.7);
}

/* Light */
.tippy-tooltip.light-theme {
  color: #333;
  font-weight: 500;
  font-size: 14px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  padding: 6px 12px;
  background-color: #fff;
}

.tippy-popper[x-placement^="top"] .tippy-tooltip.light-theme .tippy-arrow {
  border-top-color: #fff;
}
.tippy-popper[x-placement^="bottom"] .tippy-tooltip.light-theme .tippy-arrow {
  border-bottom-color: #fff;
}
.tippy-popper[x-placement^="right"] .tippy-tooltip.light-theme .tippy-arrow {
  border-right-color: #fff;
}
.tippy-popper[x-placement^="left"] .tippy-tooltip.light-theme .tippy-arrow {
  border-left-color: #fff;
}

/* ---------------------------------- */
/* Magnific Popup
------------------------------------- */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
  overflow: hidden;
  position: fixed;
  background: #111;
}

.mfp-wrap {
  top: -10px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2001;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}

.mfp-container {
  height: 100%;
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 18px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.mfp-container:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 505;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #aaa;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 504;
}

.mfp-preloader a {
  color: #cccccc;
}

.mfp-preloader a:hover {
  color: #fff;
}

.mfp-s-ready .mfp-preloader {
  display: none;
}

.mfp-s-error .mfp-content {
  display: none;
}

.mfp-s-loading .mfp-arrow {
  display: none;
}

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  border: 0;
  -webkit-appearance: none;
  display: block;
  padding: 0;
  z-index: 506;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  right: 0px;
  top: -40px;
}

/* Close Button */
.og-close,
button.mfp-close {
  position: absolute;
  width: 45px;
  height: 45px;
  top: -20px;
  display: block;
  right: -12px;
  cursor: pointer !important;
  z-index: 9999;
  color: #fff;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  border-radius: 50%;
  margin: 0px;
  background-color: transparent;
  outline: none;
  transform: translate3d(0, 0, 0);
}

.mfp-iframe-holder .mfp-close {
  top: -50px;
  right: -12px;
  transform: translate3d(0, 0, 0);
}

.mfp-gallery .mfp-close {
  top: 20px;
  right: 10px;
}

.mfp-gallery .mfp-content .mfp-close {
  display: none;
}

.mfp-close::after,
.mfp-close::before {
  top: 0px;
}

.og-close {
  margin: 17px;
  right: 10px;
  background: transparent;
  color: #222;
}

.og-close:before,
.mfp-close:before {
  font-family: "Feather-Icons";
  font-size: 20px;
  top: 2px;
  left: 0;
  position: relative;
  height: 10px;
}

.og-close:before,
.mfp-close:before {
  content: "\ea02";
}

#small-dialog .mfp-close,
#small-dialog-1 .mfp-close,
#small-dialog-2 .mfp-close,
#small-dialog-3 .mfp-close,
#small-dialog-4 .mfp-close,
#sign-in-dialog,
.mfp-close,
.mfp-close:hover {
  color: #fff;
}

/* Popup close button*/
#sign-in-dialog .mfp-close,
#small-dialog-1 .mfp-close,
#small-dialog-2 .mfp-close,
#small-dialog-3 .mfp-close,
#small-dialog-4 .mfp-close,
#small-dialog .mfp-close {
  color: #666;
  background-color: #e4e4e4;
  border-radius: 4px;
  top: 28px;
  right: 40px;
  width: 40px;
  height: 40px;
}

#sign-in-dialog .mfp-close:hover,
#small-dialog-1 .mfp-close:hover,
#small-dialog-2 .mfp-close:hover,
#small-dialog-3 .mfp-close:hover,
#small-dialog-4 .mfp-close:hover,
#small-dialog .mfp-close:hover {
  color: #fff;
  background-color: #66676b;
}

.small-dialog-content input[type="submit"] {
  width: 100%;
  margin-top: 26px;
  margin-bottom: 10px;
}

.small-dialog-content .divider {
  display: inline-block;
  width: 100%;
  margin-top: 15px;
  padding-bottom: 0;
}

.small-dialog-header {
  font-size: 22px;
  width: calc(100% + 80px);
  position: relative;
  left: -40px;
  top: 0;
  border-radius: 4px 4px 0 0;
  display: inline-block;
  background-color: #f6f6f6;
  padding: 35px 40px;
  margin-bottom: 40px;
}

.small-dialog-header h3 {
  padding: 0;
  margin: 0;
  font-size: 23px;
}

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #aaa;
  font-size: 13px;
  line-height: 18px;
}

.mfp-arrow {
  opacity: 1;
  margin: 0 20px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9999;
  color: #fff;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  width: 60px;
  height: 60px;
  position: absolute;
  display: block;
  z-index: 100;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 50%;
  outline: none;
}

.mfp-arrow:hover {
  background: #66676b;
  color: #fff;
}

.mfp-arrow.mfp-arrow-left,
.mfp-arrow.mfp-arrow-right {
  font-family: "simple-line-icons";
  font-size: 20px;
}

.mfp-arrow.mfp-arrow-right {
  right: 15px;
}

.mfp-arrow.mfp-arrow-left:before {
  content: "\e605";
  left: -1px;
  top: 0px;
  position: relative;
}
.mfp-arrow.mfp-arrow-right:before {
  content: "\e606";
  left: 1px;
  top: 0px;
  position: relative;
}

.mfp-content:hover .mfp-arrow {
  opacity: 1;
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 1180px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 54%;
}

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: black;
}

/* Popup */
#sign-in-dialog,
#small-dialog-1,
#small-dialog-2,
#small-dialog-3,
#small-dialog-4,
#small-dialog {
  background: #fff;
  padding: 40px;
  padding-top: 0;
  text-align: left;
  max-width: 610px;
  margin: 40px auto;
  position: relative;
  box-sizing: border-box;
  border-radius: 4px;
  max-width: 550px;
}

#sign-in-dialog-1 span.line,
#sign-in-dialog-2 span.line,
#sign-in-dialog-3 span.line,
#sign-in-dialog-4 span.line,
#sign-in-dialog span.line,
#small-dialog span.line {
  padding-top: 0;
}

.my-mfp-zoom-in .zoom-anim-dialog {
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);
}

/* animate in */
.my-mfp-zoom-in.mfp-ready .zoom-anim-dialog {
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

/* animate out */
.my-mfp-zoom-in.mfp-removing .zoom-anim-dialog {
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);
  opacity: 0;
}

/* Dark overlay, start state */
.my-mfp-zoom-in.mfp-bg {
  opacity: 0.001; /* Chrome opacity transition bug */
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
}
/* animate in */
.my-mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 0.85;
}
/* animate out */
.my-mfp-zoom-in.mfp-removing.mfp-bg {
  opacity: 0;
}
.dupa {
  display: block;
  height: 30px;
}

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 40px auto;
  border-radius: 4px;
}

/* The shadow behind the image */
.mfp-figure:after {
  content: "";
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 18px rgba(11, 11, 11, 0.6);
}

.mfp-figure {
  line-height: 0;
}

.mfp-bottom-bar {
  margin-top: -30px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #f3f3f3;
  word-break: break-word;
  padding-right: 36px;
}

.mfp-figure small {
  color: #bdbdbd;
  display: block;
  font-size: 12px;
  line-height: 14px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

/* Media Queries for Magnific Popup */
@media screen and (max-width: 800px) and (orientation: landscape),
  screen and (max-height: 300px) {
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }

  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }

  /* The shadow behind the image */
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }

  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }

  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }

  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }

  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }
}

.mfp-ie7 .mfp-img {
  padding: 0;
}

.mfp-ie7 .mfp-bottom-bar {
  width: 600px;
  left: 50%;
  margin-left: -300px;
  margin-top: 5px;
  padding-bottom: 5px;
}

.mfp-ie7 .mfp-container {
  padding: 0;
}

.mfp-ie7 .mfp-content {
  padding-top: 44px;
}

.mfp-ie7 .mfp-close {
  top: 0;
  right: 0;
  padding-top: 0;
}

.mfp-fade.mfp-bg {
  opacity: 0;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.92;
  background-color: #111;
}

.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}

.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}

.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}

/* Feedback Yes No */
.feedback-yes-no strong {
  font-size: 16px;
  font-weight: 600;
  color: #333;
  display: block;
}

.feedback-yes-no .radio {
  margin-right: 15px;
}

.feedback-yes-no {
  margin-bottom: 15px;
}

.feedback-yes-no .leave-rating {
  transform: scale(1.1);
  margin: 3px 0 10px 3px;
}

/* ---------------------------------- */
/* Popup tabs
------------------------------------- */
.popup-tabs-nav {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  display: block;
  height: 62px;
  background: #f8f8f8;
  border-radius: 4px 4px 0 0;
  border-bottom: 1px solid #e0e0e0;
}

.popup-tabs-nav li {
  float: left;
  line-height: 62px;
  height: 62px;
  padding: 0;
  margin-right: 0;
  position: relative;
}

.popup-tabs-nav li:first-child a {
  border-radius: 4px 0 0 0;
}

.popup-tabs-nav li a,
.popup-tab-reviews {
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.popup-tabs-nav li a {
  border: none;
  color: #777;
  display: inline-block;
  outline: none;
  padding: 0;
  text-decoration: none;
  font-size: 16px;
  z-index: 10;
  position: relative;
  height: 100%;
  padding: 0 30px;
  border-right: 1px solid transparent;
  border-left: 1px solid transparent;
  transition: all 0.3s, font-weight 0s;
  border-right: 1px solid #e0e0e0;
}

.popup-tabs-nav li.active {
  background-color: #fff;
  position: relative;
}

.popup-tabs-nav li a:hover {
  background-color: #f3f3f3;
  height: calc(100% - 1px);
  color: #333;
}

.popup-tabs-nav li:last-child a:hover {
  border-right: 1px solid #e0e0e0;
}

.popup-tabs-nav li.active a:hover,
.popup-tabs-nav li.active {
  background-color: #fff;
}

.popup-tabs-nav li.active a {
  color: #66676b;
  font-weight: 600;
}

.popup-tabs-nav li.active:after {
  content: "";
  position: absolute;
  display: block;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #fff;
  z-index: 111;
}

.popup-tabs-nav li:first-child.active {
  border-left: none;
  border-radius: 4px 0 0 0;
}

.popup-tab-content {
  padding: 50px 35px;
  position: relative;
  z-index: 10;
  display: inline-block;
  width: 100%;
}

.popup-tabs-container {
  overflow: hidden;
  width: 100%;
}

.popup-tab-content p:last-child {
  margin-bottom: 0;
}

.dialog-with-tabs#sign-in-dialog,
.dialog-with-tabs#small-dialog-1,
.dialog-with-tabs#small-dialog-2,
.dialog-with-tabs#small-dialog-3,
.dialog-with-tabs#small-dialog-4,
.dialog-with-tabs#small-dialog {
  padding: 0;
  color: #666;
  max-width: 540px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.25);
}

/* Close Button Adjustments*/
#sign-in-dialog.dialog-with-tabs .mfp-close,
#small-dialog.dialog-with-tabs .mfp-close,
#small-dialog-1.dialog-with-tabs .mfp-close,
#small-dialog-2.dialog-with-tabs .mfp-close,
#small-dialog-3.dialog-with-tabs .mfp-close,
#small-dialog-4.dialog-with-tabs .mfp-close {
  color: #888;
  background-color: #f8f8f8;
  border-left: 1px solid #e0e0e0;
  border-radius: 0 4px 0 0;
  top: 0;
  right: 0;
  width: 62px;
  height: 61px;
}

#sign-in-dialog.dialog-with-tabs .mfp-close:hover,
#small-dialog-1.dialog-with-tabs .mfp-close:hover,
#small-dialog-2.dialog-with-tabs .mfp-close:hover,
#small-dialog-3.dialog-with-tabs .mfp-close:hover,
#small-dialog-4.dialog-with-tabs .mfp-close:hover,
#small-dialog.dialog-with-tabs .mfp-close:hover {
  color: #333;
  background-color: #f2f2f2;
}

.dialog-with-tabs .mfp-close:before,
.dialog-with-tabs .mfp-close:before {
  font-size: 24px;
}

/* Chrome autocomplete highlight reset */
.dialog-with-tabs input:-webkit-autofill,
.dialog-with-tabs input:focus:-webkit-autofill {
  border: 1px solid #e0e0e0;
  -webkit-box-shadow: inset 0 0 0px 9999px white, 0 1px 4px rgba(0, 0, 0, 0.07);
}

.dialog-with-tabs input {
  margin-bottom: 22px;
}

/* Forgot Password Link */
.dialog-with-tabs .forgot-password {
  font-size: 14px;
  color: #888;
  margin-top: -12px;
  display: block;
}

.dialog-with-tabs .forgot-password:hover {
  color: #66676b;
}

/* Submit Button Adjustments */
.dialog-with-tabs .button {
  height: 48px;
  width: 100% !important;
  margin-top: 25px;
  box-shadow: 0 4px 12px rgba(102, 103, 107, 0.15);
}

/* Welcome Text */
.welcome-text {
  display: block;
  text-align: center;
  color: #808080;
  margin-bottom: 33px;
}

.welcome-text h3 {
  display: block;
  font-weight: 700;
  color: #333;
  font-size: 24px;
}

.welcome-text span a {
  font-weight: 500;
}

.welcome-text span {
  display: block;
  margin-top: 4px;
}

/* Social Login Separator */
.social-login-separator {
  width: 100%;
  display: block;
  text-align: center;
  position: relative;
  width: 100%;
  margin: 38px 0 15px 0;
  height: 1px;
}

.social-login-separator:before {
  width: 100%;
  content: "";
  height: 1px;
  display: block;
  background-color: #e0e0e0;
  position: absolute;
  top: 0;
}

.social-login-separator span {
  background-color: #fff;
  top: 0;
  position: absolute;
  line-height: 10px;
  top: -5px;
  margin-left: -15px;
  padding: 0px 10px;
  color: #808080;
}

/* Social Login Buttons */
.social-login-buttons {
  display: flex;
  width: calc(100% + 20px);
}

.social-login-buttons button {
  flex: 1;
  text-align: center;
  border: 1px solid #333;
  color: #333;
  border-radius: 4px;
  margin-top: 10px;
  margin-right: 20px;
  font-size: 14px;
  padding: 9px 5px;
  max-width: 50%;
  transition: 0.3s;
}

.social-login-buttons button:hover {
  background-color: #333;
  color: #fff;
}

.social-login-buttons button i {
  position: relative;
  top: 1px;
  margin-right: 7px;
  font-size: 15px;
}

.social-login-buttons .facebook-login {
  border-color: #3b5998;
  color: #3b5998;
}

.social-login-buttons .facebook-login:hover {
  background-color: #3b5998;
  color: #fff;
}

.social-login-buttons .google-login {
  border-color: #dd4b39;
  color: #dd4b39;
}

.social-login-buttons .google-login:hover {
  background-color: #dd4b39;
  color: #fff;
}

@media (max-width: 768px) {
  .social-login-separator {
    margin-bottom: 25px;
  }
  .social-login-buttons,
  .social-login-buttons a {
    width: 100%;
    display: block;
    max-width: 100%;
  }
}

/* Account Type */
.account-type {
  display: flex;
  width: calc(100% + 20px);
  margin: 0 0 20px 0;
}

.account-type div {
  flex: 1;
  margin-right: 20px;
}

.account-type label {
  border-radius: 3px;
  border: none;
  background-color: #f2f2f2;
  width: 100%;
}

/* hide input */
.account-type input.account-type-radio:empty {
  display: none;
}

/* style label */
.account-type label i {
  position: relative;
  font-size: 18px;
  top: 2px;
  margin-right: 2px;
}

.account-type input.account-type-radio:empty ~ label {
  position: relative;
  float: left;
  padding: 10px;
  text-align: center;
  padding-bottom: 11px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 4px;
  color: #888;
  transition: 0.4s;
  height: 48px;
  line-height: 28px;
  overflow: hidden;
}

.account-type input.account-type-radio ~ label:hover {
  color: #289c41;
  background-color: #e4f6e9;
}

.account-type input.account-type-radio:checked ~ label {
  color: #fff;
  background-color: #47bb67;
}

.account-type input.account-type-radio:empty ~ label:after,
.account-type input.account-type-radio:empty ~ label:before {
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 48px;
  border-radius: 4px;
  font-size: 22px;
  background: transparent;
  z-index: 100;
  opacity: 0;
}

.account-type .ripple-effect-dark span.ripple-overlay {
  animation: ripple 1.2s;
  opacity: 0.03;
}

/* Standard syntax */
@keyframes typeCheck {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  50% {
    opacity: 1;
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    transform: translateY(-10px);
  }
}

@keyframes typeBG {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* Popups Media Queries */
@media (max-width: 480px) {
  .popup-tabs-nav {
    padding-top: 50px;
    height: 50px;
  }
  .mfp-container {
    padding: 0 10px;
  }
  .popup-tabs-nav li:first-child {
    border-top: 1px solid #e0e0e0;
  }
  .popup-tabs-nav li {
    border-bottom: 1px solid #e0e0e0;
  }
  .popup-tabs-nav li,
  .popup-tabs-nav li a {
    width: 100%;
    text-align: center;
    height: 50px;
    line-height: 50px;
  }

  .popup-tabs-nav li a:hover {
    background-color: transparent;
  }

  #sign-in-dialog.dialog-with-tabs .mfp-close,
  #small-dialog.dialog-with-tabs .mfp-close,
  #small-dialog-1.dialog-with-tabs .mfp-close,
  #small-dialog-2.dialog-with-tabs .mfp-close,
  #small-dialog-3.dialog-with-tabs .mfp-close,
  #small-dialog-4.dialog-with-tabs .mfp-close {
    height: 50px;
    width: 50px;
  }

  .account-type,
  .social-login-buttons {
    flex-direction: column;
  }
  .social-login-buttons button {
    max-width: 100%;
    width: 100%;
    flex: auto;
  }

  .uploadButton {
    flex-direction: column;
  }
  .uploadButton .uploadButton-file-name {
    padding: 0;
    margin: 15px 0 0 0;
  }
}

/* ---------------------------------- */
/* Boostrap Slider
------------------------------------- */
input.range-slider,
input.default-slider {
  display: none;
}

.slider {
  display: inline-block;
  vertical-align: middle;
  position: relative;
}

.slider.slider-horizontal {
  width: calc(100% - 20px);
  margin-left: 11px;
  height: 20px;
}

.slider.slider-horizontal .slider-track {
  height: 3px;
  width: calc(100% + 18px);
  margin-top: -2px;
  top: 50%;
  left: -9px;
}

.slider.slider-horizontal .slider-selection,
.slider.slider-horizontal .slider-track-low,
.slider.slider-horizontal .slider-track-high {
  height: 100%;
  top: 0;
  bottom: 0;
}

.slider.slider-horizontal .slider-handle {
  margin-left: -11px;
}

.slider.slider-horizontal .slider-tick-label-container {
  white-space: nowrap;
  margin-top: 20px;
}

.slider.slider-horizontal .tooltip {
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  position: absolute;
}

.slider input {
  display: none;
}

.slider .tooltip.top {
  margin-top: -40px;
}

.slider .tooltip-inner {
  white-space: nowrap;
  max-width: none;
  background-color: #333;
  padding: 4px 12px;
  line-height: 21px;
  color: #fff;
  font-size: 14px;
  border-radius: 4px;
}

.slider .tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  bottom: -6px;
  left: 50%;
  transform: translateX(-50%);
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #333;
}

.tooltip.tooltip-min,
.tooltip.tooltip-max {
  display: none;
}

.slider .hide {
  display: none;
}

.slider-track {
  position: absolute;
  cursor: pointer;
  border-radius: 4px;
  background-color: #e0e0e0;
}

.slider-selection {
  position: absolute;
  background-color: #66676b;
  box-sizing: border-box;
  border-radius: 4px;
}

.slider-track-low,
.slider-track-high {
  position: absolute;
  background: transparent;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 4px;
}

.slider-handle {
  position: absolute;
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #66676b;
  cursor: pointer;
  z-index: 20;
  border-radius: 50%;
}

.slider-handle:after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #fff;
  border-radius: 50%;
  z-index: 30;
  display: block;
  box-shadow: 0 0 0px 6px rgba(102, 103, 107, 0.12);
  transition: 0.2s;
}

.slider-handle:hover:after {
  box-shadow: 0 0 0px 8px rgba(102, 103, 107, 0.12);
}

.slider-handle.custom {
  position: absolute;
  top: -5px;
  width: 40px;
  height: 29px;
  border: none;
  cursor: pointer;
  z-index: 20;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  text-align: center;
}

.slider-handle.custom:after {
  display: none;
}

.slider-handle.custom:before {
  content: "\e958 \e959";
  font-family: "Material-Icons";
  letter-spacing: -10px;
  font-size: 21px;
  color: #a0a0a0;
  margin-left: -10px;
  transition: 0.3s;
}

.slider-handle.custom:hover:before {
  color: #333;
}
.slider.slider-horizontal .slider-handle.custom {
  margin-left: -20px;
}

/* ---------------------------------- */
/* Bootstrap-select
------------------------------------- */
select.bs-select-hidden,
select.selectpicker {
  display: none !important;
}

.bootstrap-select .btn {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.bootstrap-select > .dropdown-toggle {
  width: 100%;
  padding-right: 25px;
  z-index: 1;
}

.bootstrap-select > .dropdown-toggle.bs-placeholder,
.bootstrap-select > .dropdown-toggle.bs-placeholder:hover,
.bootstrap-select > .dropdown-toggle.bs-placeholder:focus,
.bootstrap-select > .dropdown-toggle.bs-placeholder:active {
  color: #808080;
}

.bootstrap-select > select {
  position: absolute !important;
  bottom: 0;
  left: 50%;
  display: block !important;
  width: 0.5px !important;
  height: 100% !important;
  padding: 0 !important;
  opacity: 0 !important;
  border: none;
}
.bootstrap-select > select.mobile-device {
  top: 0;
  left: 0;
  display: block !important;
  width: 100% !important;
  z-index: 2;
}

.has-error .bootstrap-select .dropdown-toggle,
.error .bootstrap-select .dropdown-toggle {
  border-color: #b94a48;
}

.bootstrap-select.fit-width {
  width: auto !important;
}

.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: 100%;
}

.bootstrap-select.form-control {
  margin-bottom: 0;
  padding: 0;
  border: none;
}
.bootstrap-select.form-control:not([class*="col-"]) {
  width: 100%;
}
.bootstrap-select.form-control.input-group-btn {
  z-index: auto;
}
.bootstrap-select.form-control.input-group-btn:not(:first-child):not(:last-child)
  > .btn {
  border-radius: 0;
}
.bootstrap-select.btn-group:not(.input-group-btn),
.bootstrap-select.btn-group[class*="col-"] {
  float: none;
  display: flex;
  margin-left: 0;
}
.bootstrap-select.btn-group.dropdown-menu-right,
.bootstrap-select.btn-group[class*="col-"].dropdown-menu-right,
.row .bootstrap-select.btn-group[class*="col-"].dropdown-menu-right {
  float: right;
}
.form-inline .bootstrap-select.btn-group,
.form-horizontal .bootstrap-select.btn-group,
.checkbox .bootstrap-select.btn-group {
  margin-bottom: 0;
}
.checkbox-lg .bootstrap-select.btn-group.form-control,
.checkbox-sm .bootstrap-select.btn-group.form-control {
  padding: 0;
}
.checkbox-lg .bootstrap-select.btn-group.form-control .dropdown-toggle,
.checkbox-sm .bootstrap-select.btn-group.form-control .dropdown-toggle {
  height: 100%;
  font-size: inherit;
  line-height: inherit;
  border-radius: inherit;
}
.form-inline .bootstrap-select.btn-group .form-control {
  width: 100%;
}
.bootstrap-select.btn-group.disabled,
.bootstrap-select.btn-group > .disabled {
  cursor: not-allowed;
}
.bootstrap-select.btn-group.disabled:focus,
.bootstrap-select.btn-group > .disabled:focus {
  outline: none !important;
}
.bootstrap-select.btn-group.bs-container {
  position: absolute;
  height: 0 !important;
  padding: 0 !important;
}
.bootstrap-select.btn-group.bs-container .dropdown-menu {
  z-index: 1060;
}

.bootstrap-select.btn-group .dropdown-toggle .filter-option {
  overflow: hidden;
  width: 100%;
  line-height: 48px;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 90%;
  float: left;
  height: 48px;
  display: block;
}

.bootstrap-select.btn-group .dropdown-menu li.hidden {
  display: none;
}

.language-switcher.bootstrap-select.btn-group .dropdown-toggle .filter-option,
.sort-by .bootstrap-select.btn-group .dropdown-toggle .filter-option {
  max-width: 100%;
  float: left;
}

.sort-by .bootstrap-select.btn-group .dropdown-toggle .filter-option {
  line-height: 20px;
  height: auto;
}

.bootstrap-select.btn-group .dropdown-toggle .caret {
  position: absolute;
  top: 50%;
  right: 20px;
  margin-top: -2px;
  vertical-align: middle;
  transition: 0.35s;
}

.bootstrap-select.open .dropdown-toggle .caret {
  transform: rotate(180deg);
}

.bootstrap-select.btn-group[class*="col-"] .dropdown-toggle {
  width: 100%;
}

.bootstrap-select.btn-group .dropdown-menu {
  min-width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 16px;
}

.bootstrap-select.btn-group .dropdown-menu.inner {
  position: static;
  float: none;
  border: 0;
  padding: 0 5px;
  border-radius: 0;
  box-shadow: none;
}

.bootstrap-select.btn-group .dropdown-menu li {
  position: relative;
  margin-bottom: 1px;
}

.bootstrap-select.btn-group .dropdown-menu li.disabled {
  opacity: 0.75;
}

.bootstrap-select.btn-group .dropdown-menu li.active small {
  color: #fff;
}

.bootstrap-select.btn-group .dropdown-menu li.disabled a {
  cursor: not-allowed;
}
.bootstrap-select.btn-group .dropdown-menu li a {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.bootstrap-select.btn-group .dropdown-menu li a span.text {
  display: inline-block;
}
.bootstrap-select.btn-group .dropdown-menu li small {
  padding-left: 7px;
  opacity: 0.8;
}

.bootstrap-select.btn-group .dropdown-menu .notify {
  position: absolute;
  bottom: 5px;
  width: calc(100% - 20px);
  margin: 0;
  min-height: 26px;
  padding: 3px 5px;
  pointer-events: none;
  padding: 5px 10px;
  background: #333;
  color: #fff;
  margin: 5px;
  margin-bottom: 5px;
  border-radius: 4px;
  white-space: nowrap;
  font-size: 14px;
  border: none;
}

.bootstrap-select.btn-group .no-results {
  padding: 5px 10px;
  background: #f6f6f6;
  margin: 5px 0;
  margin-bottom: 0;
  border-radius: 4px;
  white-space: nowrap;
  font-size: 14px;
}

.bootstrap-select.btn-group.fit-width .dropdown-toggle .filter-option {
  position: static;
}

.bootstrap-select.btn-group.fit-width .dropdown-toggle .caret {
  position: static;
  top: auto;
  margin-top: -1px;
}

.bootstrap-select.btn-group.show-tick .dropdown-menu li a span.text {
  white-space: normal;
}

.bootstrap-select.show-menu-arrow.open > .dropdown-toggle {
  z-index: 1061;
}

.bootstrap-select.show-menu-arrow .dropdown-toggle:before {
  content: "";
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid rgba(204, 204, 204, 0.2);
  position: absolute;
  bottom: -4px;
  left: 9px;
  display: none;
}

.bootstrap-select.show-menu-arrow .dropdown-toggle:after {
  content: "";
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid white;
  position: absolute;
  bottom: -4px;
  left: 10px;
  display: none;
}
.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle:before {
  bottom: auto;
  top: -3px;
  border-top: 7px solid rgba(204, 204, 204, 0.2);
  border-bottom: 0;
}

.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle:after {
  bottom: auto;
  top: -3px;
  border-top: 6px solid white;
  border-bottom: 0;
}

.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle:before {
  right: 12px;
  left: auto;
}

.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle:after {
  right: 13px;
  left: auto;
}

.bootstrap-select.show-menu-arrow.open > .dropdown-toggle:before,
.bootstrap-select.show-menu-arrow.open > .dropdown-toggle:after {
  display: block;
}

.bs-searchbox,
.bs-actionsbox,
.bs-donebutton {
  padding: 0 5px;
}

.bs-actionsbox {
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 5px;
  margin-bottom: 10px;
  margin-top: -5px;
}

.bs-actionsbox div {
  display: flex;
}

.bootstrap-select.btn-group .bs-actionsbox div button {
  flex: 1;
  background: #f6f6f6;
  box-shadow: none;
  padding: 5px;
  height: 36px;
  font-size: 14px;
  color: #777;
  transition: 0.3s;
}

.bootstrap-select.btn-group .bs-actionsbox div button:first-child {
  border-radius: 4px 0 0 4px;
  background-color: #f0f0f0;
}

.bootstrap-select.btn-group .bs-actionsbox div button:last-child {
  border-radius: 0 4px 4px 0;
}

.bootstrap-select.btn-group .bs-actionsbox div button:hover {
  background: #66676b;
  color: #fff;
}

.bs-actionsbox .btn-group button {
  width: 50%;
}

.bs-donebutton {
  float: left;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.bs-donebutton .btn-group button {
  width: 100%;
}

.bs-searchbox + .bs-actionsbox {
  padding: 0;
}

.bs-searchbox .form-control:focus,
.bs-searchbox .form-control {
  margin-bottom: 0;
  width: 100%;
  float: none;
  box-shadow: none;
  background: #f4f4f4;
  margin: -5px 0 10px 0;
  height: 38px;
  line-height: 38px;
  padding: 10px;
}

.bs-searchbox {
  position: relative;
}

.bs-searchbox:after {
  font-family: "Material-Icons";
  content: "\e982";
  font-size: 20px;
  width: 20px;
  height: 20px;
  line-height: 0;
  display: inline-block;
  position: absolute;
  margin: 0;
  color: #a0a0a0;
  right: 15px;
  top: 18px;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid #aaa;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.dropup,
.dropdown {
  position: relative;
}

.dropdown-toggle:focus {
  outline: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  padding: 10px 5px;
  margin: -4px 0 0 0;
  font-size: 15px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  border: none;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.12);
}

.dropdown-menu.pull-right {
  right: 0;
  left: auto;
}
.dropdown-menu .divider {
  display: none;
}
.dropdown-menu > li > a {
  display: block;
  padding: 7px 10px;
  padding-right: 35px;
  clear: both;
  font-weight: normal;
  line-height: 21px;
  color: #808080;
  border-radius: 4px;
}

.bootstrap-select:before {
  content: "";
  position: absolute;
  z-index: 2100;
  bottom: 0px;
  left: 0;
  height: 8px;
  width: 100%;
  background-color: #fff;
  display: block;
  opacity: 0;
  pointer-events: none;
  transition: 0.4s;
}

.bootstrap-select.open:before {
  opacity: 1;
}

.dropdown-menu > li > a:hover,
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover {
  color: #66676b;
  text-decoration: none;
  background-color: rgba(102, 103, 107, 0.07);
}

.dropdown-menu > .disabled > a,
.dropdown-menu > .disabled > a:hover {
  color: #777;
}

.dropdown-menu > .disabled > a:hover {
  text-decoration: none;
  cursor: not-allowed;
  background-color: transparent;
  background-image: none;
}

/* Custom Scrollbar for -wekbit */
.dropdown-menu.inner::-webkit-scrollbar {
  width: 5px;
}

.dropdown-menu.inner::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
  border-radius: 12px;
}

.dropdown-menu.inner::-webkit-scrollbar-thumb {
  border-radius: 12px;
  background-color: #d8d8d8;
}

.dropdown-menu.inner::-webkit-scrollbar-thumb:hover {
  border-radius: 12px;
  background-color: #c8c8c8;
}

.bootstrap-select .dropdown-menu {
  opacity: 0;
  transition: 0.4s;
  display: block;
  pointer-events: none;
  visibility: hidden;
  cursor: default;
}

.bootstrap-select.open .dropdown-menu {
  opacity: 1;
  pointer-events: all;
  visibility: visible;
}

.open > a {
  outline: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-header {
  display: block;
  padding: 7px 10px;
  line-height: 20px;
  white-space: nowrap;
  background: #f6f6f6;
  color: #777;
  border-radius: 4px;
  margin-bottom: 1px;
}

.dropdown-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 990;
}

.pull-right > .dropdown-menu {
  right: 0;
  left: auto;
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  content: "";
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
}

.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
  top: 100%;
  bottom: auto;
  margin-bottom: 2px;
}

@media (min-width: 767px) {
  .navbar-right .dropdown-menu {
    right: 0;
    left: auto;
  }
  .navbar-right .dropdown-menu-left {
    right: auto;
    left: 0;
  }
}

.bootstrap-select.btn-group {
  position: relative;
}

.bootstrap-select.btn-group button {
  position: relative;
  display: inline-block;
  height: 48px;
  line-height: 0;
  padding: 0 20px;
  margin: 0;
  outline: none;
  font-size: 16px;
  color: #808080;
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
  display: block;
  background-color: #fff;
  font-weight: 500;
  opacity: 1;
  border-radius: 4px;
  border: none;
  box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.12);
}

/* Select Check Icon */
.hide-tick .check-mark {
  display: none !important;
}
.bootstrap-select.btn-group .dropdown-menu li a span.check-mark {
  position: absolute;
  display: inline-block;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  margin: 0;
  line-height: 0;
  width: 20px;
  height: 20px;
  text-align: center;
}

.bootstrap-select.btn-group .dropdown-menu li span.check-mark:before {
  font-family: "Feather-Icons";
  content: "\e92b";
  font-size: 18px;
  transition: all 0.3s;
  display: inline-block;
  top: 50%;
  right: 0;
  position: absolute;
  margin: 0;
  opacity: 0;
  transform: scale(0.5);
}

.bootstrap-select.btn-group .dropdown-menu li.selected span.check-mark:before {
  opacity: 1;
  transform: scale(1);
  transition: all 0.35s cubic-bezier(0.3, -0.41, 0.19, 2), opacity 0.3s;
}

.bootstrap-select .dropdown-menu li.selected a span.check-mark:before,
.bootstrap-select .dropdown-menu li.selected:hover a span.check-mark:before {
  color: #66676b;
}

/* Icons*/
.bootstrap-select .glyphicon:not(.check-mark) {
  font-size: 17px;
  line-height: 0;
  position: relative;
  top: 1px;
  margin-right: 3px;
}

.bootstrap-select .filter-option .glyphicon {
  position: relative;
  top: 1px;
}

/* Styles with border */
.with-border .dropdown-menu,
.with-border.bootstrap-select.btn-group button {
  border: 1px solid #e0e0e0;
  box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.05);
}

.with-border.bootstrap-select:before {
  width: calc(100% - 2px);
  left: 1px;
}

/* ---------------------------------- */
/* Snackbar
------------------------------------- */
.snackbar-container {
  transition: all 500ms ease;
  transition-property: top, right, bottom, left, opacity;
  font-size: 16px;
  min-height: 14px;
  background-color: #404040;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  line-height: 22px;
  padding: 18px 24px;
  bottom: -100px;
  top: -100px;
  opacity: 0;
  z-index: 9999;
  cursor: default;
  border-radius: 4px;
  font-weight: 500;
}

.snackbar-container .action {
  background: inherit;
  display: inline-block;
  border: none;
  font-size: inherit;
  text-transform: uppercase;
  color: #aaa;
  margin: 0 0 0 24px;
  padding: 0;
  min-width: min-content;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
}

@media (min-width: 640px) {
  .snackbar-container {
    display: inline-flex;
    margin: 24px;
  }
}

.snackbar-pos.bottom-center {
  top: auto !important;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

.snackbar-pos.bottom-left {
  top: auto !important;
  bottom: 0;
  left: 0;
}

.snackbar-pos.bottom-right {
  top: auto !important;
  bottom: 0;
  right: 0;
}

.snackbar-pos.top-left {
  bottom: auto !important;
  top: 0;
  left: 0;
}

.snackbar-pos.top-center {
  bottom: auto !important;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

.snackbar-pos.top-right {
  bottom: auto !important;
  top: 0;
  right: 0;
}

@media (max-width: 640px) {
  .snackbar-container {
    left: 0;
    right: 0;
    width: 100%;
    border-radius: 0;
  }
  .snackbar-pos.bottom-center,
  .snackbar-pos.top-center {
    left: 0;
    transform: none;
  }
}

/* ---------------------------------- */
/* Google Autocomplete
------------------------------------- */
body .pac-item {
  font-size: 12px;
  color: #999;
  cursor: pointer;
  transition: 0.2s;
  padding: 5px 15px;
}

body .pac-container {
  border-radius: 0 0 4px 4px;
  border: none;
  box-sizing: content-box;
  margin-top: -2px;
  background-color: #fff;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.08);
  z-index: 100;
}

/* ---------------------------------- */
/* Google Maps
------------------------------------- */
.infoBox {
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 30px);
  line-height: 24px;
}

.infoBox .job-listing {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  box-shadow: none;
  width: 100%;
}

.infoBox .job-listing .job-listing-description {
  padding-top: 0;
}

.infoBox .job-listing:hover {
  transform: none;
}

.infoBox .job-listing-details {
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 20px 25px;
}

.infoBox .job-listing h4.job-listing-company {
  font-size: 14px;
  position: relative;
  top: 0px;
}

.infoBox .job-listing .verified-badge {
  transform: scale(0.85);
  right: -7px;
  bottom: -7px;
}

.infoBox .job-listing .not-verified-badge {
  display: none;
}

.infoBox .job-listing-footer {
  flex-grow: 0;
  padding: 15px 25px;
  font-size: 16px;
  display: none;
}

.infoBox .job-listing h3.job-listing-title {
  font-size: 16px;
  line-height: 24px;
}

.infoBox .job-listing .job-listing-company-logo {
  max-width: 46px;
  margin: 5px 18px 5px 0;
  top: 0;
  flex: 0 0 46px;
}

.infoBox .job-listing .job-listing-company-logo img {
  max-width: 46px;
}

.map-box {
  background-color: #fff;
  margin-bottom: 20px;
  border-radius: 4px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.12);
  position: relative;
  width: 100%;
}

.map-box .listing-img-container img {
  width: 100%;
  border-radius: 4px 4px 0 0;
}

.map-box h4 {
  margin: 0;
  padding: 0;
}

.map-box h4 a {
  padding: 0 0 2px 0;
  font-size: 17px;
  line-height: 25px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  display: inline-block;
}

.listing-img-container {
  position: relative;
  height: 100%;
  display: block;
}

.map-box h4 a:hover {
  color: #66676b;
}

.map-box p {
  padding: 0;
  line-height: 25px;
  margin: 2px 0 0 0;
  font-size: 14px;
}

.map-box ul.listing-details {
  padding: 0;
  font-size: 14px;
  display: none;
}

.map-box .listing-title {
  padding: 16px 22px;
}

.map-box .listing-img-content {
  padding: 18px 22px;
}

.map-box .listing-img-content span {
  font-size: 21px;
}

.map-box .listing-img-content .listing-price i {
  font-size: 15px;
  margin: -7px 0 0 0;
}

.infoBox > img {
  position: absolute !important;
  right: 0px;
  top: 0px;
}

.map-box .listing-img-container::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  display: block;
  background: linear-gradient(
    to top,
    rgba(35, 35, 37, 0.85) 0%,
    rgba(35, 35, 37, 0.4) 35%,
    rgba(22, 22, 23, 0) 60%,
    rgba(0, 0, 0, 0) 100%
  );
  background-color: rgba(35, 35, 37, 0.2);
}

.map-box .listing-item-content {
  position: absolute;
  bottom: 20px;
  left: 0;
  padding: 0 24px;
  width: 100%;
  z-index: 50;
  box-sizing: border-box;
}

.map-box .listing-item-content h3 {
  color: #fff;
  font-size: 18px;
  padding: 0 0 2px 0;
  font-weight: 500;
  margin: 0;
  line-height: 27px;
}

.map-box .listing-item-content span {
  font-size: 15px;
  font-weight: 300;
  display: inline-block;
  color: rgba(255, 255, 255, 0.7);
}

/* Close Button */
.infoBox-close {
  position: absolute;
  top: 12px;
  right: 12px;
  display: inline-block;
  z-index: 999;
  text-align: center;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  height: 27px;
  width: 27px;
  line-height: 27px;
  background-color: #f0f0f0;
  color: #666;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  border-radius: 4px;
}

.infoBox-close i {
  position: relative;
  top: 2px;
  left: -0.5px;
}

.infoBox-close:hover {
  color: #fff;
  background-color: #66676b;
}

/* Read More Icon */
.map-box-icon {
  height: 38px;
  width: 38px;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #3685cf;
  display: block;
  color: #fff;
  font-size: 12px;
  z-index: 101;
  text-align: center;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-transform: translateX(50%);
  -ms-transform: translateX(50%);
  transform: translateX(50%);
  opacity: 0;
  visibility: hidden;
}

.map-box-icon:before {
  content: "\f064";
  font-family: "FontAwesome";
  position: relative;
  top: 7px;
  display: block;
}

.map-box-image:hover .map-box-icon {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateX(0);
}

.map-box-image {
  position: relative;
  overflow: hidden;
  display: block;
}

.infoBox {
  animation: infoBoxAnimation 0.5s;
}

/* Standard syntax */
@keyframes infoBoxAnimation {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

.infoBox .star-rating span {
  margin-right: 3px;
}

.infoBox .rating-counter {
  position: relative;
  top: -1px;
}

.infoBox:after {
  content: "";
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-top: 16px solid #fff;
}

#map {
  height: 100%;
  width: 100%;
  margin: 0;
  z-index: 990;
}

#map-container {
  position: relative;
  top: 0;
  left: 0;
  height: 520px;
  width: 100%;
  z-index: 990;
  margin-bottom: 60px;
}

.gmnoprint {
  margin: 10px;
}

.homepage-map {
  height: 560px;
  margin-bottom: 0;
  position: relative;
}

.search-bar-on-map {
  position: absolute;
  bottom: 50px;
  z-index: 1000;
  margin: 0 auto;
  left: 0;
  right: 0;
}

/* Cluster styles */
.cluster img {
  display: none;
}

.cluster-visible {
  text-align: center;
  font-size: 14px !important;
  color: #fff !important;
  font-weight: 700;
  border-radius: 50%;
  width: 33px !important;
  height: 33px !important;
  line-height: 33px !important;
  background-color: #66676b;
  animation: clusterAnimation 2.5s infinite;
}

@keyframes clusterAnimation {
  0%,
  100% {
    box-shadow: 0 0 0 6px rgba(102, 103, 107, 0.15);
  }
  50% {
    box-shadow: 0 0 0 10px rgba(102, 103, 107, 0.15);
  }
}

/* Marker */
.map-marker-container {
  position: absolute;
}

.marker-container {
  position: relative;
  margin: -9px 0 0 -9px;
  width: 18px;
  height: 18px;
  z-index: 1;
  border-radius: 50%;
  cursor: pointer;
  top: 0;
  background-color: #66676b;
  animation: markerAnimation 2.5s infinite;
  transition: 0.4s;
}

@keyframes markerAnimation {
  0%,
  100% {
    box-shadow: 0 0 0 6px rgba(102, 103, 107, 0.15);
  }
  50% {
    box-shadow: 0 0 0 8px rgba(102, 103, 107, 0.15);
  }
}

/* Clicked & Hover States */
.map-marker-container.clicked .marker-container {
  transform: scale(1.3);
}

.marker-container:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  background-color: transparent;
  border-radius: 50%;
  transform: scale(2);
  opacity: 0;
}

/* Custom Zoom Buttons
------------------------------------- */
.custom-zoom-in,
.custom-zoom-out {
  background-color: #fff;
  color: #333;
  cursor: pointer;
  border-radius: 4px;
  margin: 5px 15px;
  transition: 0.3s;
  box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.1);
  text-align: center;
  font-size: 18px;
  height: 34px;
  width: 34px;
}

.custom-zoom-in:hover,
.custom-zoom-out:hover {
  background-color: #66676b;
  color: #fff;
}

.custom-zoom-in:before,
.custom-zoom-out:before {
  font-family: "Feather-Icons";
  width: 100%;
  line-height: 35px;
}

.zoomControlWrapper {
  position: absolute;
  left: initial;
  right: 0;
  width: 70px;
  margin-right: 20px;
}

.custom-zoom-in:before {
  content: "\e9b1";
}
.custom-zoom-out:before {
  content: "\e996";
}

/* Street View Button
------------------------------------- */
#streetView,
#geoLocation,
#scrollEnabling {
  background-color: #fff;
  color: #333;
  cursor: pointer;
  border-radius: 4px;
  margin: 5px 15px;
  transition: 0.3s;
  box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 10px;
  left: 5px;
  z-index: 99;
  font-size: 14px;
  line-height: 21px;
  padding: 8px 14px;
  font-weight: 500;
}

#geoLocation:hover,
#streetView:hover,
#scrollEnabling:hover,
#scrollEnabling.enabled {
  background-color: #66676b;
  color: #fff;
}

#streetView:before {
  content: "\e974";
  font-family: "Material-Icons";
  font-size: 18px;
  top: 3px;
  position: relative;
  margin-right: 7px;
  margin-left: -1px;
  line-height: 0;
}

/* ---------------------------------- */
/* Leaflet Maps
------------------------------------- */
.leaflet-pane,
.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-tile-container,
.leaflet-pane > svg,
.leaflet-pane > canvas,
.leaflet-zoom-box,
.leaflet-image-layer,
.leaflet-layer {
  position: absolute;
  left: 0;
  top: 0;
}
.leaflet-container {
  overflow: hidden;
}
.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}
/* Safari renders non-retina tile on retina better with this, but Chrome is worse */
.leaflet-safari .leaflet-tile {
  image-rendering: -webkit-optimize-contrast;
}
/* hack that prevents hw layers "stretching" when loading new tiles */
.leaflet-safari .leaflet-tile-container {
  width: 1600px;
  height: 1600px;
  -webkit-transform-origin: 0 0;
}
.leaflet-marker-icon,
.leaflet-marker-shadow {
  display: block;
}
/* .leaflet-container svg: reset svg max-width decleration shipped in Joomla! (joomla.org) 3.x */
/* .leaflet-container img: map is broken in FF if you have max-width: 100% on tiles */
.leaflet-container .leaflet-overlay-pane svg,
.leaflet-container .leaflet-marker-pane img,
.leaflet-container .leaflet-shadow-pane img,
.leaflet-container .leaflet-tile-pane img,
.leaflet-container img.leaflet-image-layer,
.leaflet-container .leaflet-tile {
  max-width: none !important;
  max-height: none !important;
}

.leaflet-container.leaflet-touch-zoom {
  -ms-touch-action: pan-x pan-y;
  touch-action: pan-x pan-y;
}
.leaflet-container.leaflet-touch-drag {
  -ms-touch-action: pinch-zoom;
  /* Fallback for FF which doesn't support pinch-zoom */
  touch-action: none;
  touch-action: pinch-zoom;
}
.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
  -ms-touch-action: none;
  touch-action: none;
}

.leaflet-tile {
  filter: inherit;
  visibility: hidden;
}
.leaflet-tile-loaded {
  visibility: inherit;
}
.leaflet-zoom-box {
  width: 0;
  height: 0;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 800;
}
/* workaround for https://bugzilla.mozilla.org/show_bug.cgi?id=888319 */
.leaflet-overlay-pane svg {
  -moz-user-select: none;
}

.leaflet-pane {
  z-index: 400;
}

.leaflet-tile-pane {
  z-index: 200;
}
.leaflet-overlay-pane {
  z-index: 400;
}
.leaflet-shadow-pane {
  z-index: 500;
}
.leaflet-marker-pane {
  z-index: 600;
}
.leaflet-tooltip-pane {
  z-index: 650;
}
.leaflet-popup-pane {
  z-index: 700;
}

.leaflet-map-pane canvas {
  z-index: 100;
}
.leaflet-map-pane svg {
  z-index: 200;
}

.leaflet-vml-shape {
  width: 1px;
  height: 1px;
}
.lvml {
  behavior: url(#default#VML);
  display: inline-block;
  position: absolute;
}

/* control positioning */

.leaflet-control {
  position: relative;
  z-index: 800;
  pointer-events: visiblePainted; /* IE 9-10 doesn't have auto */
  pointer-events: auto;
}
.leaflet-top,
.leaflet-bottom {
  position: absolute;
  z-index: 1000;
  pointer-events: none;
}
.leaflet-top {
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
}
.leaflet-right {
  right: 0;
}
.leaflet-bottom {
  bottom: 0;
}
.leaflet-left {
  left: initial;
  right: 15px;
}
.leaflet-control {
  float: left;
  clear: both;
}
.leaflet-right .leaflet-control {
  float: right;
}
.leaflet-top .leaflet-control {
  margin-top: 10px;
}
.leaflet-bottom .leaflet-control {
  margin-bottom: 10px;
}
.leaflet-left .leaflet-control {
  margin-left: 10px;
}
.leaflet-right .leaflet-control {
  margin-right: 10px;
}

/* zoom and fade animations */

.leaflet-fade-anim .leaflet-tile {
  will-change: opacity;
}
.leaflet-fade-anim .leaflet-popup {
  opacity: 0;
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}
.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
  opacity: 1;
}
.leaflet-zoom-animated {
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
}
.leaflet-zoom-anim .leaflet-zoom-animated {
  will-change: transform;
}
.leaflet-zoom-anim .leaflet-zoom-animated {
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  -moz-transition: -moz-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1);
}
.leaflet-zoom-anim .leaflet-tile,
.leaflet-pan-anim .leaflet-tile {
  -webkit-transition: none;
  -moz-transition: none;
  transition: none;
}

.leaflet-zoom-anim .leaflet-zoom-hide {
  visibility: hidden;
}

/* cursors */

.leaflet-interactive {
  cursor: pointer;
}
.leaflet-grab {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}
.leaflet-crosshair,
.leaflet-crosshair .leaflet-interactive {
  cursor: crosshair;
}
.leaflet-popup-pane,
.leaflet-control {
  cursor: auto;
}
.leaflet-dragging .leaflet-grab,
.leaflet-dragging .leaflet-grab .leaflet-interactive,
.leaflet-dragging .leaflet-marker-draggable {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}

/* marker & overlays interactivity */
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-image-layer,
.leaflet-pane > svg path,
.leaflet-tile-container {
  pointer-events: none;
}

.leaflet-marker-icon.leaflet-interactive,
.leaflet-image-layer.leaflet-interactive,
.leaflet-pane > svg path.leaflet-interactive {
  pointer-events: visiblePainted; /* IE 9-10 doesn't have auto */
  pointer-events: auto;
}

/* general toolbar styles */

.leaflet-bar {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
  border-radius: 4px;
}

.leaflet-bar a,
.leaflet-bar a:hover {
  width: 26px;
  height: 26px;
  line-height: 26px;
  display: block;
  text-align: center;
  text-decoration: none;
}

.leaflet-bar a,
.leaflet-control-layers-toggle {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  display: block;
}

.leaflet-bar a.leaflet-disabled {
  cursor: default;
  pointer-events: none;
}

.leaflet-touch .leaflet-bar a {
  width: 34px;
  height: 34px;
  line-height: 34px;
}

.leaflet-touch .leaflet-bar a i {
  line-height: 34px;
  left: -1px;
  position: relative;
}

body #submit_map {
  outline: none;
  margin-bottom: 30px;
}

/* zoom control */

.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
  font: bold 18px "Lucida Console", Monaco, monospace;
  text-indent: 1px;
}

.leaflet-touch .leaflet-control-zoom-in,
.leaflet-touch .leaflet-control-zoom-out {
  font-size: 15px;
  text-align: center;
}

/* layers control */

.leaflet-control-layers {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
  background: #fff;
  border-radius: 5px;
}
.leaflet-control-layers-toggle {
  background-image: url(../images/logo.png);
  width: 36px;
  height: 36px;
}
.leaflet-retina .leaflet-control-layers-toggle {
  background-image: url(../images/logo.png);
  background-size: 26px 26px;
}
.leaflet-touch .leaflet-control-layers-toggle {
  width: 44px;
  height: 44px;
}
.leaflet-control-layers .leaflet-control-layers-list,
.leaflet-control-layers-expanded .leaflet-control-layers-toggle {
  display: none;
}
.leaflet-control-layers-expanded .leaflet-control-layers-list {
  display: block;
  position: relative;
}
.leaflet-control-layers-expanded {
  padding: 6px 10px 6px 6px;
  color: #333;
  background: #fff;
}
.leaflet-control-layers-scrollbar {
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 5px;
}
.leaflet-control-layers-selector {
  margin-top: 2px;
  position: relative;
  top: 1px;
}
.leaflet-control-layers label {
  display: block;
}
.leaflet-control-layers-separator {
  height: 0;
  border-top: 1px solid #ddd;
  margin: 5px -10px 5px -6px;
}

/* Default icon URLs */
.leaflet-default-icon-path {
  background-image: url(../images/logo.png);
}

/* attribution and scale controls */

.leaflet-container .leaflet-control-attribution {
  background: #fff;
  margin: 10px;
  border-radius: 3px;
  padding: 4px 8px;
  color: #777;
  font-weight: 500;
  line-height: 15px;
}

.leaflet-container .leaflet-control-attribution a {
  color: #333;
  text-decoration: none !important;
}
.leaflet-control-attribution,
.leaflet-control-scale-line {
  padding: 0 5px;
  color: #333;
}
.leaflet-control-attribution a {
  text-decoration: none;
}
.leaflet-control-attribution a:hover {
  text-decoration: underline;
}
.leaflet-container .leaflet-control-attribution,
.leaflet-container .leaflet-control-scale {
  font-size: 11px;
}
.leaflet-left .leaflet-control-scale {
  margin-left: 5px;
}
.leaflet-bottom .leaflet-control-scale {
  margin-bottom: 5px;
}
.leaflet-control-scale-line {
  border: 2px solid #777;
  border-top: none;
  line-height: 1.1;
  padding: 2px 5px 1px;
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  background: #fff;
  background: rgba(255, 255, 255, 0.5);
}
.leaflet-control-scale-line:not(:first-child) {
  border-top: 2px solid #777;
  border-bottom: none;
  margin-top: -2px;
}
.leaflet-control-scale-line:not(:first-child):not(:last-child) {
  border-bottom: 2px solid #777;
}

.leaflet-touch .leaflet-control-attribution,
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  box-shadow: none;
}
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  background-clip: padding-box;
}

.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
  background-color: #fff;
  color: #333;
  cursor: pointer;
  border-radius: 4px;
  margin: 5px 15px;
  transition: 0.3s;
  box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.1);
  text-align: center;
  font-size: 18px;
  height: 34px;
  width: 34px;
}

.leaflet-control-zoom-in:hover,
.leaflet-control-zoom-out:hover {
  background-color: #66676b;
  color: #fff;
}

.leaflet-control-zoom-in:before,
.leaflet-control-zoom-out:before {
  font-family: "Feather-Icons";
  width: 100%;
  line-height: 35px;
}

.leaflet-control-zoom-in:before {
  content: "\e9b1";
}
.leaflet-control-zoom-out:before {
  content: "\e996";
}

.marker-container:hover .marker-card,
.clicked .marker-container .marker-card,
.leaflet-marker-icon.clicked .marker-container {
  transform: scale(1.3);
}

/* Popup
----------------------------------------*/
.leaflet-popup {
  position: absolute;
  text-align: center;
  margin-bottom: 25px;
}

.leaflet-popup-content-wrapper {
  padding: 0;
  text-align: left;
  border-radius: 4px;
}

.leaflet-popup-content {
  width: 270px;
}

.leaflet-popup-content p {
  margin: 0;
}

.leaflet-listing-img-container img {
  border-radius: 4px 4px 0 0;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.leaflet-listing-img-container {
  position: relative;
  height: 210px;
  display: block;
  border-radius: 4px;
  overflow: hidden;
}

.leaflet-listing-img-container:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  display: block;
  background: linear-gradient(
    to top,
    rgba(35, 35, 37, 0.85) 0%,
    rgba(35, 35, 37, 0.4) 35%,
    rgba(22, 22, 23, 0) 60%,
    rgba(0, 0, 0, 0) 100%
  );
  background-color: rgba(35, 35, 37, 0.2);
}

.leaflet-listing-item-content {
  position: absolute;
  bottom: 20px;
  left: 0;
  padding: 0 24px;
  width: 100%;
  z-index: 50;
  box-sizing: border-box;
}

.leaflet-listing-item-content h3 {
  color: #fff;
  font-size: 18px;
  padding: 0 0 2px 0;
  font-weight: 500;
  margin: 0;
  line-height: 25px;
}

.leaflet-listing-content {
  padding: 16px 22px;
  width: 100%;
  z-index: 50;
  box-sizing: border-box;
  position: relative;
}

.leaflet-listing-content .not-rated {
  color: #888;
}

.leaflet-listing-content .star-rating {
  font-size: 16px;
  margin-top: 1px;
  display: block;
  line-height: 18px;
}

.leaflet-listing-content .numerical-rating {
  margin: 0;
}

.leaflet-listing-item-content span {
  font-size: 15px;
  font-weight: 300;
  display: inline-block;
  color: rgba(255, 255, 255, 0.7);
  line-height: 24px;
  display: block;
}

.leaflet-popup-tip-container {
  width: 30px;
  height: 30px;
  position: absolute;
  left: 50%;
  margin-left: -15px;
  overflow: hidden;
  pointer-events: none;
  background: transparent;
}

.leaflet-popup-tip {
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 15px solid #fff;
}

.leaflet-popup-content-wrapper {
  background: white;
  color: #333;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.12);
}

/* Close Button */
.leaflet-container a.leaflet-popup-close-button {
  position: absolute;
  top: 12px;
  right: 12px;
  display: inline-block;
  z-index: 999;
  text-align: center;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  height: 27px;
  width: 27px;
  line-height: 27px;
  background-color: #f0f0f0;
  color: transparent;
  transition: all 0.2s ease-in-out;
  border-radius: 4px;
}

.leaflet-container a.leaflet-popup-close-button:before {
  position: absolute;
  font-family: "Feather-Icons";
  content: "\ea02";
  color: #666;
  transition: all 0.2s ease-in-out;
  left: 0;
  right: 0;
  top: 0;
}

.leaflet-container a.leaflet-popup-close-button:hover {
  background-color: #66676b;
}

.leaflet-container a.leaflet-popup-close-button:hover:before {
  color: #fff;
}

.leaflet-popup-scrolled {
  overflow: auto;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
}

.leaflet-oldie .leaflet-popup-content-wrapper {
  zoom: 1;
}
.leaflet-oldie .leaflet-popup-tip {
  width: 24px;
  margin: 0 auto;

  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678)";
  filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678);
}
.leaflet-oldie .leaflet-popup-tip-container {
  margin-top: -1px;
}

.leaflet-oldie .leaflet-control-zoom,
.leaflet-oldie .leaflet-control-layers,
.leaflet-oldie .leaflet-popup-content-wrapper,
.leaflet-oldie .leaflet-popup-tip {
  border: 1px solid #999;
}

/* div icon */

.leaflet-div-icon {
  background: #fff;
  border: 1px solid #666;
}

/* Popups
------------------------------------- */
.leaflet-popup-content-wrapper .job-listing {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  box-shadow: none;
  width: 100%;
}

.leaflet-popup-content-wrapper .job-listing .job-listing-description {
  padding-top: 0;
}

.leaflet-popup-content-wrapper .job-listing:hover {
  transform: none;
}

.leaflet-popup-content-wrapper .job-listing-details {
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 20px 25px;
}

.leaflet-popup-content-wrapper .job-listing h4.job-listing-company {
  font-size: 14px;
  position: relative;
  top: 0px;
}

.leaflet-popup-content-wrapper .job-listing .verified-badge {
  transform: scale(0.85);
  right: -7px;
  bottom: -7px;
}

.leaflet-popup-content-wrapper .job-listing .not-verified-badge {
  display: none;
}

.leaflet-popup-content-wrapper .job-listing-footer {
  flex-grow: 0;
  padding: 15px 25px;
  font-size: 16px;
  display: none;
}

.leaflet-popup-content-wrapper .job-listing h3.job-listing-title {
  font-size: 16px;
  line-height: 24px;
}

.leaflet-popup-content-wrapper .job-listing .job-listing-company-logo {
  max-width: 46px;
  margin: 5px 18px 5px 0;
  top: 0;
  flex: 0 0 46px;
}

.leaflet-popup-content-wrapper .job-listing .job-listing-company-logo img {
  max-width: 46px;
}

/* Tooltip */
/* Base styles for the element that has a tooltip */
.leaflet-tooltip {
  position: absolute;
  padding: 6px;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 3px;
  color: #222;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
}
.leaflet-tooltip.leaflet-clickable {
  cursor: pointer;
  pointer-events: auto;
}
.leaflet-tooltip-top:before,
.leaflet-tooltip-bottom:before,
.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
  position: absolute;
  pointer-events: none;
  border: 6px solid transparent;
  background: transparent;
  content: "";
}

/* Directions */

.leaflet-tooltip-bottom {
  margin-top: 6px;
}
.leaflet-tooltip-top {
  margin-top: -6px;
}
.leaflet-tooltip-bottom:before,
.leaflet-tooltip-top:before {
  left: 50%;
  margin-left: -6px;
}
.leaflet-tooltip-top:before {
  bottom: 0;
  margin-bottom: -12px;
  border-top-color: #fff;
}
.leaflet-tooltip-bottom:before {
  top: 0;
  margin-top: -12px;
  margin-left: -6px;
  border-bottom-color: #fff;
}
.leaflet-tooltip-left {
  margin-left: -6px;
}
.leaflet-tooltip-right {
  margin-left: 6px;
}
.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
  top: 50%;
  margin-top: -6px;
}
.leaflet-tooltip-left:before {
  right: 0;
  margin-right: -12px;
  border-left-color: #fff;
}
.leaflet-tooltip-right:before {
  left: 0;
  margin-left: -12px;
  border-right-color: #fff;
}

.leaflet-cluster-anim .leaflet-marker-icon,
.leaflet-cluster-anim .leaflet-marker-shadow {
  -webkit-transition: -webkit-transform 0.3s ease-out, opacity 0.3s ease-in;
  -moz-transition: -moz-transform 0.3s ease-out, opacity 0.3s ease-in;
  -o-transition: -o-transform 0.3s ease-out, opacity 0.3s ease-in;
  transition: transform 0.3s ease-out, opacity 0.3s ease-in;
}

.leaflet-cluster-spider-leg {
  /* stroke-dashoffset (duration and function) should match with leaflet-marker-icon transform in order to track it exactly */
  -webkit-transition: -webkit-stroke-dashoffset 0.3s ease-out,
    -webkit-stroke-opacity 0.3s ease-in;
  -moz-transition: -moz-stroke-dashoffset 0.3s ease-out,
    -moz-stroke-opacity 0.3s ease-in;
  -o-transition: -o-stroke-dashoffset 0.3s ease-out,
    -o-stroke-opacity 0.3s ease-in;
  transition: stroke-dashoffset 0.3s ease-out, stroke-opacity 0.3s ease-in;
}

/* Clusters
----------------------------------------*/
.marker-cluster-small,
.marker-cluster-medium,
.marker-cluster-large {
  background-color: transparent;
}

.marker-cluster {
  background-clip: padding-box;
  border-radius: 20px;
}

.marker-cluster div {
  margin-left: 5px;
  margin-top: 5px;
  text-align: center;
  font-size: 15px;
  color: #fff;
  font-weight: 600;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  line-height: 36px;
  background-color: #66676b;
  position: relative;
}

.marker-cluster-small div,
.marker-cluster-medium div,
.marker-cluster-large div {
  background-color: #66676b;
}

.marker-cluster div:before {
  border: 7px solid #66676b;
  opacity: 0.2;
  box-shadow: inset 0 0 0 4px #66676b;
  content: "";
  position: absolute;
  border-radius: 50%;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: cluster-animation 2.5s infinite;
}

@keyframes cluster-animation {
  0%,
  100% {
    transform: scale(1.3) rotate(0deg);
  }
  50% {
    transform: scale(1.5) rotate(90deg);
  }
}

.marker-cluster span {
  font-family: Arial, sans-serif;
  line-height: 36px;
}

.leaflet-control-geocoder {
  border-radius: 4px;
  background: white;
  min-width: 26px;
  min-height: 26px;
}

.leaflet-touch .leaflet-control-geocoder {
  min-width: 30px;
  min-height: 30px;
}

.leaflet-control-geocoder a,
.leaflet-control-geocoder .leaflet-control-geocoder-icon {
  border-bottom: none;
  display: inline-block;
}

.leaflet-control-geocoder .leaflet-control-geocoder-alternatives a {
  width: inherit;
  height: inherit;
  line-height: inherit;
}

.leaflet-control-geocoder a:hover,
.leaflet-control-geocoder .leaflet-control-geocoder-icon:hover {
  border-bottom: none;
  display: inline-block;
}

.leaflet-control-geocoder-form {
  display: none;
  vertical-align: middle;
}
.leaflet-control-geocoder-expanded .leaflet-control-geocoder-form {
  display: inline-block;
}
.leaflet-control-geocoder-form input {
  font-size: 120%;
  border: 0;
  background-color: transparent;
  width: 246px;
}

.leaflet-control-geocoder-icon {
  border-radius: 4px;
  width: 26px;
  height: 26px;
  border: none;
  background-color: white;
  background-image: url(../images/geocoder.png);
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.leaflet-touch .leaflet-control-geocoder-icon {
  width: 30px;
  height: 30px;
}

.leaflet-control-geocoder-throbber .leaflet-control-geocoder-icon {
  background-image: url(../images/geocoder.png);
}

.leaflet-control-geocoder-form-no-error {
  display: none;
}

.leaflet-control-geocoder-form input:focus {
  outline: none;
}

.leaflet-control-geocoder-form button {
  display: none;
}
.leaflet-control-geocoder-error {
  margin-top: 8px;
  margin-left: 8px;
  display: block;
  color: #444;
}
.leaflet-control-geocoder-alternatives {
  display: block;
  width: 272px;
  list-style: none;
  padding: 0;
  margin: 0;
}

.leaflet-control-geocoder-alternatives-minimized {
  display: none;
  height: 0;
}
.leaflet-control-geocoder-alternatives li {
  white-space: nowrap;
  display: block;
  overflow: hidden;
  padding: 5px 8px;
  text-overflow: ellipsis;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}

.leaflet-control-geocoder-alternatives li a,
.leaflet-control-geocoder-alternatives li a:hover {
  width: inherit;
  height: inherit;
  line-height: inherit;
  background: inherit;
  border-radius: inherit;
  text-align: left;
}

.leaflet-control-geocoder-alternatives li:last-child {
  border-bottom: none;
}
.leaflet-control-geocoder-alternatives li:hover,
.leaflet-control-geocoder-selected {
  background-color: #f5f5f5;
}
.leaflet-control-geocoder-address-detail {
}
.leaflet-control-geocoder-address-context {
  color: #666;
}

/* Touch Gestures
----------------------------------------*/
@-webkit-keyframes leaflet-gestures-fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes leaflet-gestures-fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.leaflet-container:after {
  -webkit-animation: leaflet-gestures-fadein 0.8s backwards;
  animation: leaflet-gestures-fadein 0.8s backwards;
  color: #fff;
  font-family: "Roboto", Arial, sans-serif;
  font-size: 22px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 15px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 461;
  pointer-events: none;
}

.leaflet-gesture-handling-touch-warning:after,
.leaflet-gesture-handling-scroll-warning:after {
  -webkit-animation: leaflet-gestures-fadein 0.8s forwards;
  animation: leaflet-gestures-fadein 0.8s forwards;
}

.leaflet-gesture-handling-touch-warning:after {
  content: attr(data-gesture-handling-touch-content);
}

.leaflet-gesture-handling-scroll-warning:after {
  content: attr(data-gesture-handling-scroll-content);
}

/* Leaflet autocomplete  */
#leaflet-geocode-cont {
  position: absolute;
  z-index: 110;
  background: #fff;
  border: none;
  border-radius: 0 0 4px 4px;
  top: 46px;
  box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.12);
  display: none;
  width: 100%;
  border-top: 1px solid #e8e8e8;
}

#leaflet-geocode-cont:before {
  content: "";
  width: 100%;
  height: 4px;
  background-color: #fff;
  display: block;
  position: absolute;
  top: -5px;
}
#leaflet-geocode-cont.active {
  display: block;
}

#leaflet-geocode-cont ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#leaflet-geocode-cont ul li {
  color: #888;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  padding: 11px 15px;
  padding-left: 38px;
  margin: 0;
  border-bottom: 1px solid #e8e8e8;
  cursor: pointer;
  transition: 0.3s;
  position: relative;
  /*     white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis; */
}

#leaflet-geocode-cont ul li:before {
  content: "\f041";
  font-family: "FontAwesome";
  font-size: 14px;
  position: absolute;
  top: 10px;
  left: 20px;
  margin-right: 0;
  color: #aaa;
  transition: 0.3s;
}

#leaflet-geocode-cont ul li:hover:before {
  color: #a0a0a0;
}

#leaflet-geocode-cont ul li:last-child {
  border: none;
  border-radius: 0 0 4px 4px;
}

#leaflet-geocode-cont ul li.selected,
#leaflet-geocode-cont ul li:hover {
  background: #fafafa;
}

#leaflet-geocode-cont span.highlight {
  color: #222;
  font-weight: 700;
}
.type-and-hit-enter {
  display: block;
  position: absolute;
  right: 16px;
  top: 13px;
  z-index: 120;
  background-color: #f0f0f0;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 500;
  color: #888;
  line-height: 18px;
  padding: 3px 7px;
  pointer-events: none;
  opacity: 0;
  transition: 0.4s;
  transform: translateX(4px);
}

@media (min-width: 992px) {
  .intro-banner-search-form .type-and-hit-enter {
    top: 23px;
    right: 18px;
  }

  .intro-banner-search-form #leaflet-geocode-cont {
    width: calc(100% + 24px);
    left: -12px;
    top: calc(100% + 10px);
  }
}

@media (max-width: 992px) {
  .intro-banner-search-form #leaflet-geocode-cont {
    top: calc(100% + 0px);
  }
  .intro-banner-search-form .type-and-hit-enter {
    top: 17px;
    right: 14px;
  }
}

.tip-visible-focusin.type-and-hit-enter,
.tip-visible.type-and-hit-enter {
  opacity: 1;
  transform: translateX(0);
}

.main-search-container.alt-search-box #leaflet-geocode-cont {
  top: 51px;
}
.main-search-input.gray-style .type-and-hit-enter,
.main-search-inner .type-and-hit-enter {
  top: 11px;
  right: 25px;
}
.main-search-container.alt-search-box .type-and-hit-enter {
  right: 19px;
  top: 14px;
}

@media (min-width: 992px) {
  .main-search-input .osm-dropdown-active #leaflet-geocode-cont,
  .main-search-container:not(.alt-search-box)
    .osm-dropdown-active
    #leaflet-geocode-cont {
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
    border: none;
    padding-top: 45px;
    top: 0;
    z-index: 10;
    border-radius: 4px;
  }

  .main-search-input.gray-style .osm-dropdown-active #leaflet-geocode-cont {
    box-shadow: none;
    border: 1px solid #e0e0e0;
  }

  .main-search-input
    .osm-dropdown-active
    #leaflet-geocode-cont
    ul
    li:first-child,
  .main-search-container:not(.alt-search-box)
    .osm-dropdown-active
    #leaflet-geocode-cont
    ul
    li:first-child {
    border-top: 1px solid #e8e8e8;
  }
}

@media (max-width: 992px) {
  .main-search-container:not(.alt-search-box) #leaflet-geocode-cont {
    top: 52px;
  }
  .main-search-input.gray-style .type-and-hit-enter,
  .main-search-container:not(.alt-search-box) .type-and-hit-enter {
    top: 16px;
    right: 20px;
  }
}

#leaflet-geocode-cont ul li.powered-by-osm {
  display: block;
  padding: 9px 0;
  padding-right: 20px;
  font-size: 12px;
  font-weight: 500;
  color: #999;
  pointer-events: none;
  text-align: right;
}

#leaflet-geocode-cont ul li.powered-by-osm strong {
  color: #333;
  position: relative;
  padding-left: 26px;
}

#leaflet-geocode-cont ul li.powered-by-osm strong:before {
  background-image: url(https://i.imgur.com/YaiaYGw.png);
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  height: 17px;
  width: 17px;
  display: inline-block;
  position: absolute;
  left: 5px;
  top: -2px;
}

#leaflet-geocode-cont ul li.powered-by-osm:before {
  content: "";
}
.leaflet-control-geocoder {
  border-radius: 4px;
  background: white;
  min-width: 26px;
  min-height: 26px;
}

.leaflet-touch .leaflet-control-geocoder {
  min-width: 30px;
  min-height: 30px;
}

.leaflet-control-geocoder a,
.leaflet-control-geocoder .leaflet-control-geocoder-icon {
  border-bottom: none;
  display: inline-block;
}

.leaflet-control-geocoder .leaflet-control-geocoder-alternatives a {
  width: inherit;
  height: inherit;
  line-height: inherit;
}

.leaflet-control-geocoder a:hover,
.leaflet-control-geocoder .leaflet-control-geocoder-icon:hover {
  border-bottom: none;
  display: inline-block;
}

.leaflet-control-geocoder-form {
  display: none;
  vertical-align: middle;
}
.leaflet-control-geocoder-expanded .leaflet-control-geocoder-form {
  display: inline-block;
}
.leaflet-control-geocoder-form input {
  font-size: 120%;
  border: 0;
  background-color: transparent;
  width: 246px;
}

.leaflet-control-geocoder-icon {
  border-radius: 4px;
  width: 26px;
  height: 26px;
  border: none;
  background-color: white;
  background-image: url(../images/geocoder.png);
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.leaflet-touch .leaflet-control-geocoder-icon {
  width: 30px;
  height: 30px;
}

.leaflet-control-geocoder-throbber .leaflet-control-geocoder-icon {
  background-image: url(../images/geocoder.png);
}

.leaflet-control-geocoder-form-no-error {
  display: none;
}

.leaflet-control-geocoder-form input:focus {
  outline: none;
}

.leaflet-control-geocoder-form button {
  display: none;
}
.leaflet-control-geocoder-error {
  margin-top: 8px;
  margin-left: 8px;
  display: block;
  color: #444;
}
.leaflet-control-geocoder-alternatives {
  display: block;
  width: 272px;
  list-style: none;
  padding: 0;
  margin: 0;
}

.leaflet-control-geocoder-alternatives-minimized {
  display: none;
  height: 0;
}
.leaflet-control-geocoder-alternatives li {
  white-space: nowrap;
  display: block;
  overflow: hidden;
  padding: 5px 8px;
  text-overflow: ellipsis;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}

.leaflet-control-geocoder-alternatives li a,
.leaflet-control-geocoder-alternatives li a:hover {
  width: inherit;
  height: inherit;
  line-height: inherit;
  background: inherit;
  border-radius: inherit;
  text-align: left;
}

.leaflet-control-geocoder-alternatives li:last-child {
  border-bottom: none;
}
.leaflet-control-geocoder-alternatives li:hover,
.leaflet-control-geocoder-selected {
  background-color: #f5f5f5;
}

.leaflet-control-geocoder-address-detail {
}
.leaflet-control-geocoder-address-context {
  color: #666;
}
#streetView {
  z-index: 999;
}
#map,
#singleListingMap {
  outline: none;
}

/* -------------------------------------------------------------- */
/*  06. Single Pages
----------------------------------------------------------------- */

/* Boxed List for Single Page 
------------------------------------- */
.boxed-list {
  margin-top: 10px;
  display: block;
}

.boxed-list-headline {
  background-color: #f2f2f2;
  border-radius: 4px;
  padding: 20px 35px;
}

.boxed-list-headline h3 {
  font-size: 18px;
}
.boxed-list-headline h3 span {
  color: #888;
}

.boxed-list-headline h3 i {
  font-size: 18px;
  color: #66676b;
  font-size: 24px;
  position: relative;
  top: 3px;
  line-height: 0;
  display: inline-block;
  margin-right: 5px;
}

.boxed-list ul.boxed-list-ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.boxed-list ul.boxed-list-ul > li {
  display: block;
  background-color: transparent;
  padding: 35px;
  border-radius: 4px;
}

.boxed-list ul.boxed-list-ul > li:nth-child(2n) {
  background-color: #fafafa;
}

/* Fields UL */
.fields-ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.fields-ul > li {
  display: block;
  background-color: transparent;
  padding: 30px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e4e4e4;
}

/* Boxed List Item
------------------------------------- */
.boxed-list-item {
  display: flex;
}

.boxed-list-item .item-image {
  flex: 0 0 60px;
  height: 60px;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  margin-right: 25px;
  position: relative;
  top: -2px;
  display: flex;
  background-color: #fff;
}

/* IE 11 Fixes */
@media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
  .boxed-list-item .item-image {
    max-width: 60px;
  }
  .boxed-list-item .item-image img {
    width: 100%;
  }
}
/* IE 11 Fixes - End */

.boxed-list-item .item-image img {
  height: auto;
  align-self: center;
}

.boxed-list-item .item-content h4 a,
.boxed-list-item .item-content h4 {
  font-size: 18px;
  font-weight: 600;
  color: #333;
}

.boxed-list-item .item-content h4 span {
  font-size: 16px;
  color: #808080;
  display: block;
  font-weight: 500;
}

.boxed-list-item .item-details {
  display: flex;
  margin-top: 5px;
}

.boxed-list-item .item-details .star-rating {
  top: -2px;
  position: relative;
  margin-right: 15px;
}

.boxed-list-item .item-details .detail-item {
  margin-right: 15px;
  color: #808080;
}

.boxed-list-item .item-details .detail-item a {
  color: #808080;
}
.boxed-list-item .item-details .detail-item a:hover {
  color: #66676b;
}

.boxed-list-item .item-details .detail-item i {
  margin-right: 3px;
  position: relative;
  top: 0;
}

.boxed-list-item .item-description p {
  margin-bottom: 0;
  padding-bottom: 0;
}

.boxed-list-item .item-description {
  margin-top: 15px;
}

/* Bid 
------------------------------------- */
.bid {
  display: flex;
  width: 100%;
}

.bid .bids-content {
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.bid .bids-bid {
  align-items: center;
  display: flex;
}

.bid .bids-avatar {
  align-self: center;
}

.bid .freelancer-name {
  margin-top: 0;
}

.bid .freelancer-avatar {
  width: 80px;
  margin: 0 auto;
  position: relative;
  margin-right: 30px;
}

.bid .star-rating {
  margin-top: 3px;
  display: block;
}

.bid .freelancer-avatar img {
  width: 100%;
  border-radius: 50%;
  cursor: pointer;
}

.bid .freelancer-avatar .verified-badge {
  position: absolute;
  bottom: 0;
  right: 0;
}

/* Bid Rate */
.bid-rate {
  text-align: center;
  font-size: 14px;
  background: #f4f4f4;
  color: #888;
  border-radius: 4px;
  padding: 8px 12px;
}

.boxed-list ul.boxed-list-ul > li:nth-child(2n) .bid-rate {
  background: #f2f2f2;
}

.bid-rate .rate {
  color: #333;
  font-weight: 600;
  text-align: center;
  line-height: 20px;
  border-radius: 4px;
  padding: 5px 10px;
  padding-bottom: 0;
  font-size: 16px;
}

.bid-rate span {
  display: block;
  margin-top: -2px;
}

@media (max-width: 768px) {
  .bid .freelancer-avatar {
    width: 70px;
    margin: 0 0 15px 0;
  }
  .bid {
    display: block;
  }
  .bids-bid {
    margin-top: 15px;
  }
  .bid-rate {
    width: 100%;
  }
}

/* Single Job
------------------------------------- */
.job-overview {
  border-radius: 4px;
  background-color: #f9f9f9;
}

.job-overview .job-overview-headline {
  color: #333;
  font-size: 20px;
  padding: 20px 35px;
  background-color: #f0f0f0;
  color: #333;
  position: relative;
  border-radius: 4px 4px 0 0;
}

.job-overview .job-overview-inner {
  padding: 35px;
}

.job-overview .job-overview-inner ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

/* List Item */
.job-overview .job-overview-inner ul li {
  position: relative;
  display: block;
  font-size: 16px;
  padding-left: 38px;
  margin-bottom: 25px;
}

.job-overview .job-overview-inner ul li:last-child {
  margin-bottom: 0;
}

.job-overview .job-overview-inner ul li span {
  font-weight: 600;
  color: #333;
  margin: 0;
  padding: 0;
  display: block;
}

.job-overview .job-overview-inner ul li h5 {
  font-weight: 500;
  color: #666;
  font-size: 16px;
  margin: 0;
  padding: 0;
  line-height: 20px;
}

.job-overview .job-overview-inner ul li i {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 24px;
  color: #66676b;
}

/* Apply Now Button */
a.apply-now-button,
div.apply-now-button {
  background-color: #66676b;
  color: #fff;
  text-align: center;
  font-size: 18px;
  border-radius: 4px;
  padding: 14px 20px;
  margin-bottom: 35px;
  box-shadow: 0 2px 8px rgba(102, 103, 107, 0.15);
  transition: 0.3s;
  display: block;
}

a.apply-now-button:hover,
div.apply-now-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 2px 8px rgba(102, 103, 107, 0.35);
}

a.apply-now-button i,
div.apply-now-button i {
  font-size: 22px;
  position: relative;
  line-height: 0;
  top: 3px;
  margin-left: 5px;
}

/* Single Job Map */
#single-job-map-container {
  position: relative;
}

#single-job-map-container #singleListingMap {
  position: relative;
  height: 340px;
  border-radius: 4px;
}

/* Single Freelancer Profile
------------------------------------- */

/* Freelancer Socials*/
.freelancer-socials ul {
  margin: 0;
  padding: 0;
  list-style: none;
  cursor: default;
  margin: 0 0 -5px -8px;
}

.freelancer-socials ul li {
  display: inline-block;
  font-size: 24px;
}

.freelancer-socials ul li a {
  color: #b9b9b9;
  padding: 0 8px;
}

.freelancer-socials ul li a:hover {
  color: #333;
}

/* Attachments */
.attachments-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: calc(100% + 20px);
  margin-bottom: -30px;
}

.attachment-box {
  display: flex;
  margin: 0 20px 20px 0;
  background-color: #f4f4f4;
  border-radius: 4px 0px 4px 4px;
  font-weight: 600;
  padding: 15px 20px;
  padding-bottom: 45px;
  padding-right: 25px;
  line-height: 24px;
  flex-direction: column;
  color: #666;
  position: relative;
  transition: 0.3s;
  flex: 0 1 calc(50% - 21px);
  cursor: default;
  position: relative;
}

.single-page-section .attachment-box {
  flex: 0 1 calc(33% - 20px);
}
.single-page-section .attachments-container {
  margin-bottom: -20px;
}

a.attachment-box {
  cursor: pointer;
}

.attachment-box:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  border-width: 0 20px 20px 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.15) #fff;
  transition: 0.3s;
  border-radius: 0 0 0 4px;
}

a.attachment-box:hover {
  background-color: #66676b;
  color: #fff;
}

a.attachment-box:hover:before {
  border-color: rgba(0, 0, 0, 0.25) #fff;
}

.attachment-box span {
  font-size: 14px;
  line-height: 20px;
  display: inline-block;
  flex: auto;
}

.attachment-box i {
  display: block;
  font-style: normal;
  font-size: 14px;
  color: #999;
  font-weight: 500;
  margin-top: 10px;
  position: absolute;
  bottom: 10px;
  transition: 0.3s;
}

a.attachment-box:hover i {
  color: rgba(255, 255, 255, 0.7);
}

/* Remove Attachment Button */
.attachment-box .remove-attachment {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: #fff;
  background-color: #dc3139;
  box-shadow: 0 3px 8px rgba(234, 65, 81, 0.15);
  height: 28px;
  width: 28px;
  line-height: 28px;
  border-radius: 3px;
  font-weight: 500;
  font-size: 14px;
  transition: 0.3s;
  opacity: 0;
  transform: translateY(3px);
}

.attachment-box .remove-attachment:before {
  content: "\e9e4";
  font-family: "Feather-Icons";
}

.attachment-box:hover .remove-attachment {
  opacity: 1;
  transform: translateY(0);
}

/* Profile Overview */
.profile-overview {
  display: flex;
  line-height: 22px;
  align-items: center;
  margin-bottom: 25px;
  width: calc(100% + 25px);
}

.profile-overview .overview-item {
  flex: 1;
  border-right: 1px solid #e0e0e0;
  margin-right: 20px;
  padding-right: 20px;
  flex-grow: 1;
}

.profile-overview .overview-item:last-child {
  border-right: 0;
  padding-right: 0;
}

.profile-overview .overview-item strong {
  display: block;
  font-weight: 600;
  color: #333;
  font-size: 18px;
  margin-bottom: 5px;
}

.profile-overview .overview-item span {
  color: #808080;
}

/* Freelancer Indicators */
.freelancer-indicators {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 30px);
  line-height: 24px;
  margin-bottom: -20px;
}

.freelancer-indicators .indicator {
  width: calc(50% - 31px);
  margin-right: 30px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.freelancer-indicators .indicator strong {
  color: #333;
  font-weight: 600;
}

.freelancer-indicators .indicator-bar span,
.freelancer-indicators .indicator-bar {
  width: 100%;
  height: 4px;
  background-color: #e0e0e0;
  border-radius: 10px;
  position: relative;
  margin: 10px 0;
  transition: 1s;
}

.freelancer-indicators .indicator-bar span {
  width: 0;
  background-color: #66676b;
  position: absolute;
  top: 0;
  margin: 0;
}

/* Media Queriers */
@media (max-width: 1240px) {
  .profile-overview,
  .profile-overview .overview-item {
    display: block;
    width: 100%;
  }
  .profile-overview .overview-item {
    border-right: 0;
    border-bottom: 1px solid #e0e0e0;
    padding: 15px 0;
    margin: 0;
  }
  .profile-overview .overview-item:last-child {
    border-right: none;
    border-bottom: none;
  }
}

@media (max-width: 768px) {
  .boxed-list-item .item-details,
  .attachments-container,
  .attachments-container .attachment-box,
  .freelancer-indicators,
  .freelancer-indicators .indicator {
    display: block;
    width: 100%;
  }

  .boxed-list-item .item-image {
    display: none;
  }
}

/* -------------------------------------------------------------- */
/*  07. Dashboard
----------------------------------------------------------------- */
/* Full Page Adjustments */
.full-page-container,
.dashboard-container {
  height: calc(100vh - 82px);
}

@media (max-width: 1099px) {
  .full-page-container,
  .dashboard-container {
    height: calc(100vh - 76px);
  }
}

/* Dashboard Basis */
.dashboard-container {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  overflow: hidden;
}

.dashboard-sidebar {
  flex: 0 0 280px;
  background-color: #fff;
  position: relative;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  z-index: 100;
}

.dashboard-sidebar .dashboard-sidebar-inner {
  overflow: auto;
  height: 100%;
}

.dashboard-content-container {
  flex: 1;
  background-color: #fafafa;
  padding: 0;
  overflow: auto;
  position: relative;
  z-index: 99;
}

.dashboard-content-inner {
  padding: 50px;
  padding-bottom: 0;
  position: relative;
}

/* Dashboard Header Logo Adjustent */
@media (min-width: 1099px) {
  #header-container.dashboard-header .container {
    padding-right: 35px;
    padding-left: 0;
  }

  #header-container.dashboard-header #logo {
    width: 281px;
    padding-left: 35px;
  }
}

@media (max-width: 1240px) {
  #header-container.dashboard-header #logo {
    width: auto;
  }
}

/* ---------------------------------- */
/* Dashboard Navigation
------------------------------------- */
.dashboard-nav {
  display: block;
  z-index: 11;
  padding-bottom: 30px;
}

a.dashboard-responsive-nav-trigger {
  display: none;
}

.dashboard-nav ul {
  list-style: none;
  padding: 0;
  margin: 25px 0 0 0;
}

.dashboard-nav ul:first-child {
  margin: 30px 0 0 0;
}

.dashboard-nav ul:before {
  content: attr(data-submenu-title);
  padding: 0 35px;
  margin-bottom: 5px;
  display: block;
  color: #66676b;
  font-weight: 600;
  font-size: 14px;
}

/* 1st Level */
.dashboard-nav ul li {
  display: block;
  border-left: 3px solid transparent;
  transition: 0.3s;
  line-height: 25px;
  font-size: 15px;
}

.dashboard-nav ul li a {
  color: #707070;
  display: block;
  padding: 11px 32px;
  transition: 0.3s;
  cursor: pointer;
  position: relative;
}

.dashboard-nav ul li a i {
  padding-right: 8px;
  width: 20px;
  font-size: 20px;
  color: #909090;
  transition: 0.3s;
  position: relative;
  top: 2px;
}

/* Hover State */
.dashboard-nav ul li ul li:hover a {
  padding-left: 4px;
}

.dashboard-nav ul li.active-submenu a i,
.dashboard-nav ul li.active a i,
.dashboard-nav ul li:hover a i {
  color: #66676b;
}

.dashboard-nav ul li.active-submenu,
.dashboard-nav ul li.active,
.dashboard-nav ul li:hover {
  border-color: #66676b;
  background-color: rgba(102, 103, 107, 0.04);
}

/*.dashboard-nav ul li:hover {
	background-color: #f8f8f8;
}*/

.dashboard-nav ul li.active-submenu a,
.dashboard-nav ul li:hover a,
.dashboard-nav ul li.active a {
  color: #66676b;
}

/* Nav Tag */
.dashboard-nav ul li span.nav-tag {
  display: inline-block;
  font-weight: 700;
  height: 19px;
  width: 19px;
  line-height: 19px;
  text-align: center;
  color: #fff;
  font-size: 11px;
  background-color: #66676b;
  border-radius: 50%;
  position: relative;
  margin: 0 0 0 4px;
  top: -2px;
}

/* Dropdown Styles */
.dashboard-nav ul li ul {
  padding: 0;
  margin: 0;
  visibility: hidden;
  max-height: 0;
  opacity: 0;
  line-height: 0;
  transition: 0.3s;
  pointer-events: none;
}

.dashboard-nav ul li.active-submenu ul {
  visibility: visible;
  padding: 0 0 18px 0;
  line-height: 24px;
  opacity: 1;
  max-height: 200px;
  pointer-events: all;
}

.dashboard-nav ul li ul li {
  background-color: transparent;
  border: none;
  padding-left: 63px;
  position: relative;
  margin: 0;
}

.dashboard-nav ul li ul li a {
  padding: 5px 0;
}

.dashboard-nav ul li ul li:hover {
  border: none;
  background-color: transparent;
}

/* Indicator Arrow */
.dashboard-nav ul li a:after {
  font-family: "Material-Icons";
  content: "\e914";
  opacity: 1;
  font-size: 16px;
  transition: 0.3s;
  opacity: 1;
  line-height: 17px;
  width: 17px;
  height: 17px;
  background-color: #f0f0f0;
  color: #a8a8a8;
  display: inline-block;
  border-radius: 3px;
  margin-left: 7px;
  text-indent: 0;
  top: 1px;
  position: absolute;
  right: 35px;
  top: 15px;
}

.dashboard-nav ul li.active-submenu a:after,
.dashboard-nav ul li.active a:after,
.dashboard-nav ul li:hover a:after {
  color: #66676b;
  background: rgba(102, 103, 107, 0.13);
  opacity: 0.8;
}

.dashboard-nav ul li ul:before,
.dashboard-nav ul li a:only-child:after {
  content: "";
  display: none;
}

/* ---------------------------------- */
/* Dashboard Elements
------------------------------------- */

/* Dashboard Headline
------------------------------------- */
.dashboard-headline {
  display: block;
  position: relative;
  margin-bottom: 50px;
}

.dashboard-headline h3 {
  display: block;
  font-size: 26px;
  color: #333;
}

.dashboard-headline span {
  display: block;
  font-size: 18px;
  color: #888;
  line-height: 30px;
  margin-top: 5px;
}

.dashboard-headline #breadcrumbs {
  right: 0;
}

/* Fun Facts
------------------------------------- */
.fun-facts-container {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 30px);
  margin-bottom: -30px;
}

.fun-fact {
  background-color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  padding: 25px;
  margin-right: 30px;
  margin-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex: 1 1 calc(25% - 30px);
  height: auto;
  cursor: default;
  transition: 0.3s;
}

/* IE 11 Fixes */
@media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
  .fun-fact {
    flex: 1;
  }
}
/* IE 11 Fixes - End */

.fun-fact-icon {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.04);
  flex: 0 0 100px;
  height: 100px;
  display: flex;
  text-align: center;
}

.fun-fact-icon i {
  font-size: 48px;
  align-self: center;
  margin: 0 auto;
  color: #666;
}

.fun-fact-icon i.icon-material-outline-gavel {
  transform: scale(0.91);
}

.fun-fact-text {
  flex: 1;
  margin-right: 20px;
}

.fun-fact span {
  font-size: 18px;
  color: #888;
  line-height: 22px;
}

.fun-fact h4 {
  font-size: 38px;
  color: #333;
  line-height: 38px;
  margin-top: 5px;
}

/* Dashboard Box
------------------------------------- */
.dashboard-box {
  display: block;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  margin-top: 30px;
  position: relative;
}

.dashboard-box .headline {
  display: block;
  padding: 20px 30px;
  border-bottom: 1px solid #e4e4e4;
  position: relative;
}

.dashboard-box .headline h3 {
  font-size: 16px;
  font-weight: 600;
  color: #333;
  line-height: 26px;
}

.dashboard-box .headline h3 i {
  color: #66676b;
  font-size: 21px;
  line-height: 0;
  position: relative;
  top: 2px;
  margin-right: 4px;
}

/* Pixel Perfect Adjustment */
.dashboard-box .headline h3 i.icon-material-outline-gavel {
  font-size: 20px;
}
.dashboard-box .headline h3 i.icon-material-outline-supervisor-account {
  font-size: 24px;
  top: 3px;
}
.dashboard-box .headline h3 i.icon-feather-folder-plus {
  font-size: 20px;
  top: 2px;
  margin-right: 5px;
}
.button.big i.icon-feather-plus {
  font-size: 20px;
  top: 2px;
  margin-left: -4px;
  margin-right: 5px;
}
/* Pixel Perfect Adjustment - End */

.dashboard-box .content.with-padding {
  padding: 30px;
}

.dashboard-box .chart {
  padding: 30px 20px 17px 18px;
}

.dashboard-box .headline .sort-by {
  float: none;
  display: inline-block;
  position: absolute;
  top: calc(50% + 3px);
  transform: translateY(-50%);
  right: 30px;
  z-index: 100;
  height: 33px;
}

.dashboard-box
  .headline
  .bootstrap-select.btn-group
  .dropdown-toggle
  .filter-option {
  font-weight: 500;
  color: #666;
}

/* Note
------------------------------------- */
.dashboard-note {
  border-radius: 4px;
  background-color: #f8f8f8;
  padding: 25px;
  padding-top: 20px;
  margin-bottom: 20px;
  position: relative;
}

.dashboard-note:last-child {
  margin-bottom: 65px;
}

.dashboard-note:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  border-width: 0 20px 20px 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.15) #fff;
  transition: 0.3s;
  border-radius: 0 0 0 4px;
}

.dashboard-note p {
  line-height: 24px;
  margin-bottom: 20px;
  color: #777;
  font-size: 14.7px;
}

.note-priority {
  background-color: #333;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  padding: 5px 9px;
  display: inline-block;
  line-height: 20px;
}

.note-priority.high {
  background-color: #dc3139;
}
.note-priority.medium {
  background-color: #f59110;
}
.note-priority.low {
  background-color: #3fb660;
}

.note-footer {
  display: flex;
}

.note-buttons {
  align-self: center;
  margin-left: auto;
}

.note-buttons a {
  font-size: 16px;
  padding-left: 3px;
  margin-top: 2px;
  display: inline-block;
  color: #888;
}

.note-buttons a:hover {
  color: #444;
}

.add-note-button {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  right: 0;
  padding: 30px;
  padding-top: 10px;
  background: #fff;
  border-radius: 4px;
  width: calc(100% - 15px);
}

.add-note-button a.button {
  width: calc(100% + 15px) !important;
  max-width: calc(100% + 15px) !important;
}

/* Dashboard Box List
------------------------------------- */
ul.dashboard-box-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

ul.dashboard-box-list > li {
  border-bottom: 1px solid #e4e4e4;
  padding: 0;
  line-height: 26px;
  padding: 22px 30px;
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  flex-flow: row wrap;
  transition: 0.3s;
}

ul.dashboard-box-list > li:last-child {
  border-bottom: none;
}

ul.dashboard-box-list > li:hover {
  background-color: #fcfcfc;
}

.dashboard-box-list .notification-text {
  font-size: 14.7px;
  flex: 1;
}
.dashboard-box-list .notification-icon {
  flex: 0 0 40px;
}
.dashboard-box-list .notification-text .star-rating {
  position: relative;
  top: -18px;
  line-height: 0;
  height: 0;
  display: inline-block;
  margin: 0 3px;
  transform: scale(0.93);
}

/* Dashboard Box List Buttons
------------------------------------- */
.buttons-to-right,
.dashboard-box-list .button.to-right {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  transition: 0.3s;
  box-shadow: 0px 0px 10px 15px #fff;
  background-color: #fff;
}

.dashboard-box-list .buttons-to-right.always-visible,
.dashboard-box-list li:hover .buttons-to-right {
  opacity: 1;
}

.dashboard-box-list .buttons-to-right.always-visible:not(.single-right-button) {
  position: relative;
  transform: none;
  top: 0;
  margin: 5px 0 10px -2px;
  opacity: 1;
  right: auto;
  display: inline-block;
  box-shadow: none;
  width: 100%;
  background-color: transparent;
  box-shadow: none;
}

.dashboard-box-list .button {
  padding: 6px 15px;
  line-height: 20px;
  font-size: 13px;
  font-weight: 600;
  margin: 0;
  display: inline-block;
  box-shadow: 0 3px 8px rgba(102, 103, 107, 0.15);
}

.dashboard-box-list .button i[class*="icon-material"] {
  font-size: 18px;
  top: 3px;
  margin-right: 2px;
  margin-left: -2px;
}

.dashboard-box-list .buttons-to-right .button {
  margin: 0 0 -6px 2px;
}

.dashboard-box-list .buttons-to-right.always-visible .button {
  margin: 0 3px 0 0;
}

.dashboard-box .button.red {
  background-color: #dc3139;
  box-shadow: 0 3px 8px rgba(234, 65, 81, 0.15);
}
.dashboard-box .button.green {
  background-color: #40b660;
  box-shadow: 0 3px 8px rgba(64, 182, 96, 0.15);
}
.dashboard-box .button.dark {
  background-color: #333333;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
}
.dashboard-box .button.gray {
  background-color: #eee;
  color: #666;
  box-shadow: none;
}

/* Hovers */
.dashboard-box .dashboard-box-list .button:not(.ico):hover,
.dashboard-box .button.red:not(.ico):hover,
.dashboard-box .button.green:not(.ico):hover,
.dashboard-box .button.gray:not(.ico):hover {
  color: #fff;
  background-color: #333333;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
}

.dashboard-box .button.dark:not(.ico):hover {
  background-color: #66676b;
  box-shadow: 0 3px 8px rgba(102, 103, 107, 0.15);
}

.dashboard-box .button.ico {
  width: 32px;
  text-align: center;
}

.dashboard-box .button.ico i {
  font-size: 15px;
  margin-left: -7px;
  top: 2px;
  position: relative;
}

.dashboard-box .button i.icon-material-outline-check {
  margin-left: -5px;
}

.dashboard-box .button.ico i[class*="icon-material"] {
  font-size: 18px;
  margin-left: -8px;
  top: 3px;
  position: relative;
}

.dashboard-box-list {
  box-shadow: none;
}

.dashboard-box-list a.button i {
  margin: 0;
  padding: 0;
  position: relative;
  top: 1px;
  font-size: 14px;
  line-height: 0;
  margin-left: -2px;
}

.dashboard-box .mark-as-read {
  float: none;
  background-color: #f4f4f4;
  position: absolute;
  right: 30px;
  top: 18px;
}

.button-info {
  background: rgba(255, 255, 255, 0.25);
  color: #fff;
  width: 19px;
  height: 19px;
  display: inline-block;
  text-align: center;
  line-height: 19px;
  border-radius: 50%;
  font-size: 11px;
  font-weight: 700;
  margin-left: 3px;
  margin-right: -2px;
}

/* Listing Status*/
.dashboard-status-button {
  color: #fff;
  border-radius: 4px;
  font-size: 14px;
  line-height: 21px;
  padding: 2px 8px;
  display: inline-block;
  position: relative;
  top: -2px;
  margin: 0 0 4px 4px;
}

.dashboard-status-button.green {
  background: #e0f5d7;
  color: #449626;
}

.dashboard-status-button.yellow {
  background-color: #fbf6dd;
  color: #a18d29;
}

.dashboard-status-button.red {
  background-color: #ffe6e6;
  color: #ea5252;
}

/* Dashboard Invoices List
------------------------------------- */
.invoice-list-item ul {
  line-height: 22px;
  padding-bottom: 2px;
  color: #909090;
  list-style: none;
  padding: 0;
  margin: 0;
}

.invoice-list-item ul li {
  padding: 0;
  display: inline-block;
  font-size: 14.7px;
  margin-bottom: 5px;
}

.invoice-list-item ul li:after {
  content: "";
  display: inline-block;
  width: 1px;
  height: 11px;
  background-color: #e0e0e0;
  position: relative;
  margin: 0 10px;
}

.invoice-list-item ul li:last-child:after {
  display: none;
}

.invoice-list-item strong {
  font-weight: 600;
  color: #333;
  margin-bottom: 5px;
  margin-top: -2px;
  display: block;
}

.paid,
.unpaid {
  background-color: #333;
  color: #fff;
  border-radius: 4px;
  line-height: 20px;
  padding: 4px 8px;
  font-size: 13px;
  position: relative;
  top: -1px;
}

.paid {
  background-color: #40b660;
}
.unpaid {
  background-color: #dc3139;
}

/* Listings in Dashboard
------------------------------------- */
/* Freelancers */
.dashboard-box .job-listing-details {
  box-shadow: none;
  padding: 10px 0;
  align-items: center;
  flex: auto;
}

.dashboard-box .job-listing {
  box-shadow: none;
  padding: 0;
  margin: 0;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.dashboard-box .job-listing.width-adjustment {
  max-width: 60%;
}

.dashboard-box .job-listing h3 a {
  color: #333;
  font-size: 18px;
}

.dashboard-box .job-listing .job-listing-company-logo {
  max-width: 50px;
}

.dashboard-box .job-listing .job-listing-description {
  padding-top: 0;
}

.dashboard-box .job-listing:hover {
  transform: none;
}

.dashboard-box .job-listing .job-listing-footer {
  background-color: transparent;
  padding: 0;
  border-radius: none;
}

/* Jobs */
.dashboard-box .freelancer-overview {
  padding: 15px 0;
}
.dashboard-box .freelancer-overview-inner {
  flex: auto;
  display: flex;
  align-items: center;
}

.dashboard-box .freelancer-overview .freelancer-name {
  text-align: left;
  margin: 0 0 0 30px;
  width: 100%;
}

.dashboard-box .freelancer-overview .freelancer-avatar {
  margin: 0;
  width: 90px;
}

.dashboard-box .freelancer-overview.manage-candidates .freelancer-avatar {
  align-self: flex-start;
}

/* Dashboard Task Info */
.dashboard-task-info {
  list-style: none;
  padding: 0;
  margin: 0;
  background-color: #f4f4f4;
  text-align: center;
  border-radius: 4px;
  padding: 14px 25px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  align-self: flex-end;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.3s;
  justify-content: center;
}

.dashboard-box li:hover .dashboard-task-info {
  background-color: #f0f0f0;
}

.dashboard-task-info li {
  display: inline-block;
  border-right: 1px solid #d0d0d0;
  padding-right: 22px;
  margin-right: 22px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dashboard-task-info li:last-child {
  margin-right: 0;
  padding-right: 0;
  border: none;
}

.dashboard-task-info li strong {
  display: block;
  font-size: 16px;
  font-weight: 600;
  color: #333;
  line-height: 22px;
}

.dashboard-task-info li span {
  display: block;
  font-size: 14px;
  color: #888;
  line-height: 20px;
}

/* Bid Acceptance */
.bid-acceptance {
  display: flex;
  color: #289c41;
  background-color: #e4f6e9;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  border-radius: 4px;
  padding: 12px 18px;
  display: inline-block;
  margin: 0 auto;
  line-height: 20px;
}

.bid-acceptance span {
  font-size: 14px;
  opacity: 0.86;
  line-height: 20px;
}

/* Media Queries */
@media (max-width: 1366px) {
  .dashboard-box .job-listing.width-adjustment {
    max-width: 100%;
  }
  .dashboard-task-info {
    position: relative;
    right: 0;
    top: 0;
    transform: none;
    margin: 10px 0 20px 0;
  }
  .dashboard-task-info.bid-info {
    margin-bottom: 0;
    margin-top: 20px;
  }
}

@media (max-width: 768px) {
  .dashboard-box .freelancer-overview .freelancer-avatar {
    width: 60px;
  }
  .dashboard-box .freelancer-overview .freelancer-avatar .verified-badge {
    transform: scale(0.8);
    bottom: -5px;
    right: -5px;
  }
  .dashboard-box .freelancer-overview .freelancer-name {
    margin: 0 0 0 25px;
  }
  .dashboard-box .job-listing .job-listing-company-logo {
    display: none;
  }

  .dashboard-task-info.bid-info,
  .dashboard-task-info:not(.bid-info) {
    flex-direction: column;
    width: 100%;
  }

  .dashboard-task-info li {
    border-right: none;
    border-bottom: 1px solid #d0d0d0;
    padding: 0 0 10px 0;
    margin: 0 0 10px 0;
    width: 100%;
  }
  .dashboard-task-info li:last-child {
    border-right: none;
    border-bottom: none;
    padding: 0;
    margin: 0;
  }
}

/* Reviews
------------------------------------- */
.dashboard-box .boxed-list-item {
  padding: 10px 0;
  width: 100%;
}

/* Submit Forms
------------------------------------- */
.submit-field {
  margin-bottom: 28px;
  display: block;
}

.submit-field .pac-container {
  box-shadow: none;
  border: 1px solid #e0e0e0;
  border-top: 1px solid #fff;
  padding-top: 0;
  z-index: 9;
  left: 0 !important;
  top: 47px !important;
  border-radius: 0 0 4px 4px;
}

.submit-field h5 {
  font-size: 16px;
  font-weight: 600;
  color: #333;
  margin-bottom: 12px;
}

.submit-field h5 span {
  color: #888;
  font-weight: 500;
}

.help-icon {
  color: #66676b;
  display: inline-block;
  font-size: 19px;
  font-weight: 400;
  line-height: 18px;
  height: 16px;
  position: relative;
  top: 3px;
  margin: 0 3px;
  font-style: normal;
  cursor: help;
}

.help-icon:before {
  content: "\e97c";
  font-family: "Feather-Icons";
}

/* Avatar Switcher */
.avatar-wrapper {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: none;
  margin: 0 10px 30px 0;
  transition: all 0.3s ease;
}
.avatar-wrapper:hover {
  transform: scale(1.05);
  cursor: pointer;
}

.avatar-wrapper .profile-pic {
  height: 100%;
  width: 100%;
  transition: all 0.3s ease;
  object-fit: cover;
}

.avatar-wrapper .profile-pic:after {
  font-family: Feather-Icons;
  content: "\e9f1";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  line-height: 120px;
  position: absolute;
  font-size: 60px;
  background: #f0f0f0;
  color: #aaa;
  text-align: center;
}

.avatar-wrapper .upload-button {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.avatar-wrapper .file-upload {
  opacity: 0;
  pointer-events: none;
  position: absolute;
}

/* Small Footer Styles
------------------------------------- */
.dashboard-content-inner .small-footer {
  width: 100%;
  left: 0px;
  padding: 25px 50px;
  position: absolute;
  bottom: 0;
}

.dashboard-footer-spacer {
  padding-top: 125px;
}

/* Dashbaord Media Queries 
------------------------------------- */
@media (min-width: 1365px) and (max-width: 1600px) {
  .fun-fact:last-child {
    display: none;
  }
}

@media (max-width: 1365px) {
  .fun-fact {
    flex: 1 1 calc(50% - 30px);
    max-width: calc(50% - 30px);
  }
}

@media (max-width: 992px) {
  .dashboard-content-inner {
    min-height: auto !important;
  }
  /* Dashboard Mobile Basis */
  .dashboard-sidebar .dashboard-nav-container,
  .dashboard-content-inner {
    padding: 15px;
  }
  .dashboard-sidebar-inner {
    padding-bottom: 30px;
  }
  .dashboard-sidebar .simplebar-content {
    overflow-x: hidden;
  }

  .dashboard-nav-inner {
    padding: 30px 0;
    margin-bottom: 0;
  }

  .dashboard-sidebar {
    flex: 1;
    width: 100%;
    background-color: transparent;
    box-shadow: none;
    height: auto !important;
  }

  .full-page-sidebar .simplebar-track,
  .dashboard-sidebar .simplebar-track {
    display: none;
  }

  .full-page-sidebar-inner,
  .dashboard-sidebar-inner {
    height: auto !important;
  }

  .dashboard-container {
    display: block;
    width: 100%;
    flex-wrap: wrap;
    height: auto !important;
  }

  .dashboard-content-container {
    height: auto !important;
  }
  .dashboard-content-container .simplebar-scrollbar {
    display: none;
  }

  /* Dashboard Mobile Basis - End */

  /* Dashboard Nav */
  .dashboard-nav {
    width: 100%;
    margin: 0;
    border-radius: 0 0 4px 4px;
    position: relative;
    min-height: auto;
    opacity: 1;
    visibility: hidden;
    max-height: 0;
    padding: 0;
    transition: all 0.3s;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background: #fff;
  }

  .dashboard-nav ul:first-child {
    margin: 0;
  }
  .dashboard-nav ul {
    margin-top: 15px;
  }

  .dashboard-nav.active ul,
  .dashboard-nav.active {
    opacity: 1;
    visibility: visible;
    max-height: 900px;
  }

  .dashboard-nav ul:before {
    padding: 0 29px;
  }
  .dashboard-nav ul li a {
    padding: 10px 25px;
  }

  .dashboard-nav ul,
  .dashboard-nav.active ul li ul,
  .dashboard-nav ul li ul {
    visibility: hidden;
    opacity: 0;
    max-height: 0;
    transition: all 0.3s;
  }

  .dashboard-nav.active ul li.active-submenu ul {
    visibility: visible;
    opacity: 1;
    max-height: 300px;
  }

  /* Trigger */
  a.dashboard-responsive-nav-trigger {
    width: 100%;
    margin: 0;
    margin-top: 15px;
    background-color: #333;
    border-radius: 5px;
    color: #fff;
    padding: 16px 30px;
    font-weight: 600;
    transition: 0.2s;
    display: block;
    line-height: 26px;
  }

  a.dashboard-responsive-nav-trigger span.trigger-title {
    padding-left: 33px;
  }

  a.dashboard-responsive-nav-trigger .hamburger-inner,
  a.dashboard-responsive-nav-trigger .hamburger-inner::before,
  a.dashboard-responsive-nav-trigger .hamburger-inner::after {
    background-color: #fff;
  }

  a.dashboard-responsive-nav-trigger .hamburger {
    top: 2px;
    left: 0;
    transform: scale(0.66);
  }

  a.dashboard-responsive-nav-trigger i {
    margin-right: 8px;
    margin-left: -2px;
    font-size: 28px;
    line-height: 0;
    position: relative;
    top: 6px;
  }

  a.dashboard-responsive-nav-trigger.active {
    border-radius: 4px 4px 0 0;
  }
  /* Dashboard Nav - End */

  .buttons-to-right:not(.single-right-button) {
    opacity: 1;
  }
  .fun-facts-container {
    width: calc(100% + 15px);
    margin-bottom: -15px;
  }
  .fun-fact {
    flex: 1 1 calc(50% - 15px);
    max-width: calc(50% - 15px);
    margin: 0 15px 15px 0;
  }
}

@media (max-width: 768px) {
  .fun-fact {
    flex: 1 1 100%;
    max-width: 100%;
  }
  #titlebar #breadcrumbs,
  .dashboard-headline #breadcrumbs {
    right: 0;
    position: relative;
    top: 0;
    transform: none;
    margin-top: 30px;
    max-width: 100%;
  }
  .buttons-to-right:not(.single-right-button) {
    position: relative;
    transform: none;
    top: 0;
    margin: 10px 0 0 0;
    opacity: 1;
    right: auto;
    display: inline-block;
    box-shadow: none;
    width: 100%;
  }
  .dashboard-box .headline .sort-by {
    float: none;
    display: inline-block;
    position: relative;
    top: 0;
    transform: none;
    right: 0px;
    top: 5px;
    left: -8px;
  }
  .dashboard-box .headline .sort-by .bootstrap-select .dropdown-menu {
    left: 0;
    right: auto;
  }
}

/* ---------------------------------- */
/* Dashboard Messages
------------------------------------- */
.messages-container {
  margin: 0;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  background-color: #fff;
}

/* Scrollbar Adjustments */
.messages-inbox ul {
  max-height: 875px;
}
.message-content-inner {
  max-height: 750px;
}

.message-content,
.messages-container-inner .messages-inbox {
  overflow: hidden;
}

.message-content-inner,
.messages-container-inner .messages-inbox ul {
  overflow-y: scroll;
  width: calc(100% + 18px);
}
.message-content-inner::-webkit-scrollbar,
.messages-container-inner .messages-inbox ul::-webkit-scrollbar {
  width: 17px;
}
/*  Scrollbar Hide - End */

.messages-headline {
  max-height: 82px;
  font-size: 16px;
  margin: 0;
  padding: 22px 30px;
  color: #333;
  background-color: #fff;
  display: block;
  border-bottom: 1px solid #eaeaea;
  border-radius: 4px 4px 0 0;
  position: relative;
}

.messages-headline input {
  background-color: #f6f6f6;
  box-shadow: none;
  height: 38px;
  padding: 14px;
  font-size: 14px;
  margin: 0;
}

.messages-headline input:focus {
  box-shadow: none;
}

.messages-headline .input-with-icon i {
  font-size: 18px;
  right: 14px;
}

.messages-headline h4 {
  font-size: 16px;
  color: #333;
  padding: 6px 0;
  margin: 0;
  font-weight: 600;
  display: inline-block;
}

.message-action {
  border-radius: 50px;
  font-size: 13px;
  color: #666;
  font-weight: 500;
  display: inline-block;
  transition: 0.3s;
  color: #666;
  padding: 0;
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
}

.message-action i {
  font-size: 14px;
  position: relative;
  top: 1px;
  margin-right: 1px;
}

.message-action:hover {
  color: #ee3535;
}

/* List */
.messages-inbox ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.message-avatar img {
  display: inline-block;
  width: 54px;
  height: 54px;
  border-radius: 50%;
}

.messages-inbox ul li a {
  position: relative;
  display: block;
  padding: 30px;
}

.messages-inbox ul li {
  transition: 0.2s;
  position: relative;
}

.messages-inbox ul li:nth-child(2n) {
  background-color: #fafafa;
}

.messages-inbox ul li:last-child {
  border-bottom: none;
}

.messages-inbox ul li.active-message {
  background-color: #fafafa;
}

.messages-inbox ul li:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 3px;
  height: 100%;
  background-color: #66676b;
  display: block;
  content: "";
  opacity: 0;
  transition: 0.3s;
}

.messages-inbox ul li:hover:before,
.messages-inbox ul li.active-message:before {
  opacity: 1;
}

.messages-inbox .message-avatar {
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translateY(-50%);
}

.messages-inbox .message-by {
  margin-left: 85px;
  font-size: 15px;
  color: #666;
}

.messages-inbox .message-by p {
  margin: 0;
  padding: 0;
  line-height: 25px;
  font-size: 15px;
}

.message-by-headline h5,
.message-by-headline span {
  display: inline-block;
}

.message-by-headline span {
  float: right;
  color: #888;
  font-size: 14px;
}

.message-by-headline h5 {
  font-weight: 600;
  color: #333;
  margin: 0 0 7px 0;
  padding: 0;
}

.message-by-headline:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.message-by h5 i {
  background-color: #66676b;
  border-radius: 4px;
  line-height: 20px;
  font-size: 12px;
  color: #fff;
  font-style: normal;
  padding: 3px 8px;
  margin-left: 3px;
}

/* Single Message */
.messages-container-inner {
  display: flex;
}

.messages-container-inner .messages-inbox,
.messages-container-inner .message-content {
  flex: 1;
}

.messages-container-inner .message-content-inner {
  padding: 30px;
  padding-bottom: 0;
  flex-grow: 1;
}

.messages-container-inner .message-content {
  display: flex;
  flex-direction: column;
}

.messages-container-inner .messages-inbox {
  max-width: 340px;
  border-right: 1px solid #eaeaea;
}

.messages-container-inner .messages-inbox ul li a {
  padding: 25px;
}

.messages-container-inner .messages-inbox .message-by p {
  height: 26px;
  max-width: 205px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
  color: #888;
}

.messages-container-inner .message-avatar img {
  width: 40px;
  height: 40px;
}

.messages-container-inner .messages-inbox .message-avatar {
  left: 25px;
}
.messages-container-inner .messages-inbox .message-by {
  margin-left: 60px;
}

.messages-container-inner .message-by-headline h5 {
  font-size: 14px;
  margin-bottom: 3px;
  max-width: 160px;
  line-height: 23px;
}

.messages-container-inner .message-by-headline span {
  font-size: 13px;
  position: absolute;
  top: 25px;
  right: 25px;
}

/* Message Bubbles */
.message-bubble {
  display: block;
  position: relative;
  margin-bottom: 25px;
}

.message-bubble .message-avatar {
  position: absolute;
  left: 0;
  top: 0;
}

.message-bubble .message-avatar img {
  width: 50px;
  height: 50px;
}

.message-bubble .message-text {
  margin-left: 70px;
  background-color: #f4f4f4;
  border-radius: 4px;
  padding: 12px 18px;
  position: relative;
  display: inline-block;
  float: left;
  line-height: 25px;
}

.message-bubble.me .message-text {
  float: right;
}

.message-bubble .message-text p {
  font-size: 15px;
  padding: 0;
  margin: 0;
  line-height: 25px;
}

/* Message Bubble "me" */
.message-bubble.me .message-avatar {
  left: auto;
  right: 0;
}

.message-bubble.me .message-text {
  margin-left: 0;
  margin-right: 70px;
  background-color: #66676b;
  color: #fff;
  position: relative;
}

/* Arrow */
.message-bubble .message-text:before {
  content: "";
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: 6px solid #f4f4f4;
  left: -6px;
  right: auto;
  top: 19px;
  position: absolute;
}

.message-bubble.me .message-text:before {
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid #66676b;
  border-right: none;
  right: -6px;
  left: auto;
}

/* Message Time Sign */
.message-time-sign {
  text-align: center;
  display: block;
  position: relative;
  margin-bottom: 25px;
}

.message-time-sign:before {
  content: "";
  height: 1px;
  background-color: #eaeaea;
  display: block;
  max-width: 30%;
  margin: 0 auto;
  position: relative;
  top: 14px;
  z-index: 10;
}

.message-time-sign span {
  background-color: #fff;
  font-size: 14px;
  color: #888;
  z-index: 11;
  position: relative;
  padding: 0 10px;
}

/* Reply */
.message-reply {
  border-top: 1px solid #eaeaea;
  padding: 30px;
  display: flex;
  align-items: flex-start;
  margin-top: 15px;
}

.message-reply textarea {
  min-width: auto;
  height: 48px;
  min-height: 48px;
  padding: 10px 0;
  margin: 0 15px 0 0;
  flex: 1;
  font-size: 15px;
  font-weight: 400;
  line-height: 26px;
  box-shadow: none;
  border: none;
  box-sizing: border-box;
  resize: none;
}

.message-reply button {
  border-radius: 4px;
  flex: auto;
  flex-grow: 0;
  height: 44px;
  text-align: center;
  align-self: center;
  padding: 0 25px;
}

.message-reply i {
  padding: 0;
  margin: 0;
}

/* Typing Indicastor */
.typing-indicator {
  width: auto;
  height: 24px;
  padding-top: 8px;
}

.typing-indicator span {
  height: 8px;
  width: 8px;
  float: left;
  margin: 0 1px;
  background-color: #a0a0a0;
  display: block;
  border-radius: 50%;
  opacity: 0.4;
}

.typing-indicator span:nth-of-type(1) {
  animation: 1s blink infinite 0.33333s;
}
.typing-indicator span:nth-of-type(2) {
  animation: 1s blink infinite 0.66666s;
}
.typing-indicator span:nth-of-type(3) {
  animation: 1s blink infinite 0.99999s;
}

@keyframes blink {
  50% {
    opacity: 1;
  }
}

/* Messages Media Queries */
@media (max-width: 992px) {
  .messages-container-inner .messages-inbox {
    max-width: 300px;
  }
}
@media (max-width: 768px) {
  .messages-container-inner {
    display: block;
  }

  .messages-container-inner .messages-inbox {
    max-width: 100%;
    border-bottom: 1px solid #e4e4e4;
    border-right: none;
  }
  .messages-inbox ul {
    max-height: 500px;
  }

  .messages-container-inner .message-by-headline span {
    position: relative;
    top: 0;
    right: 0;
    display: block;
    float: none;
    padding: 0;
    margin: -4px 0 0 0;
  }

  .message-action {
    display: block;
    position: relative;
    right: 0;
    top: 0;
    transform: none;
    margin-top: -5px;
  }

  .messages-headline {
    max-height: 120px;
  }
}

/* -------------------------------------------------------------- */
/*  08. Others
----------------------------------------------------------------- */

/* ---------------------------------- */
/* Common Styles
	------------------------------------- */

body {
  color: #666;
  font-size: 16px;
  line-height: 27px;
  background-color: #fff;
}

body.gray {
  background-color: #fbfbfb;
}
body p {
  font-size: 16px;
  line-height: 27px;
}

/* Main Font */
body,
h1,
h2,
h3,
h4,
h5,
h6,
input[type="text"],
input[type="password"],
input[type="email"],
textarea,
select,
input[type="button"],
input[type="submit"],
button,
body .pac-container {
  font-family: "Nunito", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  text-transform: none;
}

/* Headlines */
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #333;
  font-weight: 500;
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 32px;
}
h2 {
  font-size: 26px;
}
h3 {
  font-size: 22px;
}
h4 {
  font-size: 16px;
}
h5 {
  font-size: 14px;
}
h6 {
  font-size: 12px;
}

h3.page-title {
  font-size: 20px;
  line-height: 32px;
}

.section {
  display: block;
}
.section.white {
  background: #fff;
}
.section.gray {
  background: #f9f9f9;
}
.section.border-top {
  border-top: 1px solid #e0e0e0;
}
.section.border-bottom {
  border-bottom: 1px solid #e0e0e0;
}

/* Section Headline */
.section-headline {
  display: block;
  position: relative;
  padding-right: 20%;
}

.section-headline.centered {
  text-align: center;
  padding: 0;
}

.section-headline span {
  font-size: 18px;
  line-height: 26px;
  font-weight: 300;
  color: #888;
  margin-top: 0;
  display: block;
}

.section-headline h1,
.section-headline h2,
.section-headline h3,
.section-headline h4 {
  font-size: 26px;
  line-height: 36px;
  font-weight: 500;
  color: #333;
}

.section-headline h4 {
  font-size: 22px;
  line-height: 34px;
}

.section-headline.border-top {
  border-top: 1px solid #e0e0e0;
}

h1.in-a-box,
h2.in-a-box,
h2.in-a-box,
h3.in-a-box,
h5.in-a-box {
  display: inline-block;
  font-size: 18px;
  background-color: #333;
  color: #fff;
  border-radius: 4px;
  padding: 8px 20px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
}

.section-headline h5 {
  font-size: 16px;
  font-weight: 600;
  line-height: 30px;
}

/* Headline Link */
a.headline-link {
  position: absolute;
  right: 0;
  bottom: 0;
}

a.headline-link.bottom-icon:after {
  content: "\e957";
  top: 3px;
}

a.headline-link:after {
  content: "\e995";
  font-family: "Material-Icons";
  font-size: 21px;
  line-height: 0;
  position: relative;
  top: 4px;
  margin-left: 5px;
}

@media (max-width: 768px) {
  a.headline-link {
    position: relative;
    margin-top: 10px;
    display: inline-block;
  }
}

/* Classes for disabling things on mobile devices */
@media (max-width: 992px) {
  .hide-under-992px {
    display: none;
  }
}
@media (max-width: 768px) {
  .hide-under-768px {
    display: none;
  }
}
@media (max-width: 480px) {
  .hide-under-480px {
    display: none;
  }
}

/* ---------------------------------- */
/* Icons Cheatsheet Styles
	------------------------------------- */
.icon-set-container {
  display: block;
  width: calc(100% + 30px);
  left: -15px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}

.glyph.fs1 {
  position: relative;
  font-size: 20px;
  margin: 10px 15px;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  flex: 1 1 calc(25% - 30px);
  align-content: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 15px 15px;
  transition: 0.3s;
  color: #666;
}

.glyph.fs1:hover {
  transform: translateY(-5px);
  background-color: #333;
  color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.glyph.fs1:hover span:not([class*="mls"]) {
  background-color: #fff;
  color: #333;
}

.glyph.fs1 .fs0,
.glyph.fs1 fieldset {
  display: none;
}

.glyph.fs1 span:not([class*="mls"]) {
  vertical-align: middle;
  top: -4px;
  height: 40px;
  width: 40px;
  background: rgba(0, 0, 0, 0.04);
  display: inline-block;
  line-height: 40px;
  text-align: center;
  border-radius: 4px;
  font-size: 24px;
  transition: 0.3s;
  position: relative;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.glyph.fs1 .mls:not([class*="icon-"]) {
  max-width: 80%;
  display: inline-block;
  overflow: hidden;
  font-size: 14px;
  line-height: 20px;
  top: 0;
  vertical-align: middle;
  padding-left: 55px;
}

/* ---------------------------------- */
/* Offsets
	------------------------------------- */

/* Content & Sidebar Offsets */
.content-left-offset {
  padding-left: 30px;
}
.content-right-offset {
  padding-right: 30px;
}

@media (max-width: 992px) {
  .content-left-offset {
    padding-left: 15px;
  }
  .content-right-offset {
    padding-right: 15px;
  }
}

/* Margin Top */
.margin-top-0 {
  margin-top: 0px !important;
}
.margin-top-1 {
  margin-top: 1px !important;
}
.margin-top-2 {
  margin-top: 2px !important;
}
.margin-top-3 {
  margin-top: 3px !important;
}
.margin-top-4 {
  margin-top: 4px !important;
}
.margin-top-5 {
  margin-top: 5px !important;
}
.margin-top-6 {
  margin-top: 6px !important;
}
.margin-top-7 {
  margin-top: 7px !important;
}
.margin-top-8 {
  margin-top: 8px !important;
}
.margin-top-9 {
  margin-top: 9px !important;
}
.margin-top-10 {
  margin-top: 10px !important;
}
.margin-top-11 {
  margin-top: 11px !important;
}
.margin-top-12 {
  margin-top: 12px !important;
}
.margin-top-13 {
  margin-top: 13px !important;
}
.margin-top-14 {
  margin-top: 14px !important;
}
.margin-top-15 {
  margin-top: 15px !important;
}
.margin-top-16 {
  margin-top: 16px !important;
}
.margin-top-17 {
  margin-top: 17px !important;
}
.margin-top-18 {
  margin-top: 18px !important;
}
.margin-top-19 {
  margin-top: 19px !important;
}
.margin-top-20 {
  margin-top: 20px !important;
}
.margin-top-21 {
  margin-top: 21px !important;
}
.margin-top-22 {
  margin-top: 22px !important;
}
.margin-top-23 {
  margin-top: 23px !important;
}
.margin-top-24 {
  margin-top: 24px !important;
}
.margin-top-25 {
  margin-top: 25px !important;
}
.margin-top-26 {
  margin-top: 26px !important;
}
.margin-top-27 {
  margin-top: 27px !important;
}
.margin-top-28 {
  margin-top: 28px !important;
}
.margin-top-29 {
  margin-top: 29px !important;
}
.margin-top-30 {
  margin-top: 30px !important;
}
.margin-top-31 {
  margin-top: 31px !important;
}
.margin-top-32 {
  margin-top: 32px !important;
}
.margin-top-33 {
  margin-top: 33px !important;
}
.margin-top-34 {
  margin-top: 34px !important;
}
.margin-top-35 {
  margin-top: 35px !important;
}
.margin-top-36 {
  margin-top: 36px !important;
}
.margin-top-37 {
  margin-top: 37px !important;
}
.margin-top-38 {
  margin-top: 38px !important;
}
.margin-top-39 {
  margin-top: 39px !important;
}
.margin-top-40 {
  margin-top: 40px !important;
}
.margin-top-41 {
  margin-top: 41px !important;
}
.margin-top-42 {
  margin-top: 42px !important;
}
.margin-top-43 {
  margin-top: 43px !important;
}
.margin-top-44 {
  margin-top: 44px !important;
}
.margin-top-45 {
  margin-top: 45px !important;
}
.margin-top-46 {
  margin-top: 46px !important;
}
.margin-top-47 {
  margin-top: 47px !important;
}
.margin-top-48 {
  margin-top: 48px !important;
}
.margin-top-49 {
  margin-top: 49px !important;
}
.margin-top-50 {
  margin-top: 50px !important;
}
.margin-top-51 {
  margin-top: 51px !important;
}
.margin-top-52 {
  margin-top: 52px !important;
}
.margin-top-53 {
  margin-top: 53px !important;
}
.margin-top-54 {
  margin-top: 54px !important;
}
.margin-top-55 {
  margin-top: 55px !important;
}
.margin-top-56 {
  margin-top: 56px !important;
}
.margin-top-57 {
  margin-top: 57px !important;
}
.margin-top-58 {
  margin-top: 58px !important;
}
.margin-top-59 {
  margin-top: 59px !important;
}
.margin-top-60 {
  margin-top: 60px !important;
}
.margin-top-61 {
  margin-top: 61px !important;
}
.margin-top-62 {
  margin-top: 62px !important;
}
.margin-top-63 {
  margin-top: 63px !important;
}
.margin-top-64 {
  margin-top: 64px !important;
}
.margin-top-65 {
  margin-top: 65px !important;
}
.margin-top-66 {
  margin-top: 66px !important;
}
.margin-top-67 {
  margin-top: 67px !important;
}
.margin-top-68 {
  margin-top: 68px !important;
}
.margin-top-69 {
  margin-top: 69px !important;
}
.margin-top-70 {
  margin-top: 70px !important;
}
.margin-top-71 {
  margin-top: 71px !important;
}
.margin-top-72 {
  margin-top: 72px !important;
}
.margin-top-73 {
  margin-top: 73px !important;
}
.margin-top-74 {
  margin-top: 74px !important;
}
.margin-top-75 {
  margin-top: 75px !important;
}
.margin-top-76 {
  margin-top: 76px !important;
}
.margin-top-77 {
  margin-top: 77px !important;
}
.margin-top-78 {
  margin-top: 78px !important;
}
.margin-top-79 {
  margin-top: 79px !important;
}
.margin-top-80 {
  margin-top: 80px !important;
}
.margin-top-81 {
  margin-top: 81px !important;
}
.margin-top-82 {
  margin-top: 82px !important;
}
.margin-top-83 {
  margin-top: 83px !important;
}
.margin-top-84 {
  margin-top: 84px !important;
}
.margin-top-85 {
  margin-top: 85px !important;
}
.margin-top-86 {
  margin-top: 86px !important;
}
.margin-top-87 {
  margin-top: 87px !important;
}
.margin-top-88 {
  margin-top: 88px !important;
}
.margin-top-89 {
  margin-top: 89px !important;
}
.margin-top-90 {
  margin-top: 90px !important;
}
.margin-top-91 {
  margin-top: 91px !important;
}
.margin-top-92 {
  margin-top: 92px !important;
}
.margin-top-93 {
  margin-top: 93px !important;
}
.margin-top-94 {
  margin-top: 94px !important;
}
.margin-top-95 {
  margin-top: 95px !important;
}
.margin-top-96 {
  margin-top: 96px !important;
}
.margin-top-97 {
  margin-top: 97px !important;
}
.margin-top-98 {
  margin-top: 98px !important;
}
.margin-top-99 {
  margin-top: 99px !important;
}
.margin-top-100 {
  margin-top: 100px !important;
}

/* Margin Bottom */
.margin-bottom-0 {
  margin-bottom: 0px !important;
}
.margin-bottom-1 {
  margin-bottom: 1px !important;
}
.margin-bottom-2 {
  margin-bottom: 2px !important;
}
.margin-bottom-3 {
  margin-bottom: 3px !important;
}
.margin-bottom-4 {
  margin-bottom: 4px !important;
}
.margin-bottom-5 {
  margin-bottom: 5px !important;
}
.margin-bottom-6 {
  margin-bottom: 6px !important;
}
.margin-bottom-7 {
  margin-bottom: 7px !important;
}
.margin-bottom-8 {
  margin-bottom: 8px !important;
}
.margin-bottom-9 {
  margin-bottom: 9px !important;
}
.margin-bottom-10 {
  margin-bottom: 10px !important;
}
.margin-bottom-11 {
  margin-bottom: 11px !important;
}
.margin-bottom-12 {
  margin-bottom: 12px !important;
}
.margin-bottom-13 {
  margin-bottom: 13px !important;
}
.margin-bottom-14 {
  margin-bottom: 14px !important;
}
.margin-bottom-15 {
  margin-bottom: 15px !important;
}
.margin-bottom-16 {
  margin-bottom: 16px !important;
}
.margin-bottom-17 {
  margin-bottom: 17px !important;
}
.margin-bottom-18 {
  margin-bottom: 18px !important;
}
.margin-bottom-19 {
  margin-bottom: 19px !important;
}
.margin-bottom-20 {
  margin-bottom: 20px !important;
}
.margin-bottom-21 {
  margin-bottom: 21px !important;
}
.margin-bottom-22 {
  margin-bottom: 22px !important;
}
.margin-bottom-23 {
  margin-bottom: 23px !important;
}
.margin-bottom-24 {
  margin-bottom: 24px !important;
}
.margin-bottom-25 {
  margin-bottom: 25px !important;
}
.margin-bottom-26 {
  margin-bottom: 26px !important;
}
.margin-bottom-27 {
  margin-bottom: 27px !important;
}
.margin-bottom-28 {
  margin-bottom: 28px !important;
}
.margin-bottom-29 {
  margin-bottom: 29px !important;
}
.margin-bottom-30 {
  margin-bottom: 30px !important;
}
.margin-bottom-31 {
  margin-bottom: 31px !important;
}
.margin-bottom-32 {
  margin-bottom: 32px !important;
}
.margin-bottom-33 {
  margin-bottom: 33px !important;
}
.margin-bottom-34 {
  margin-bottom: 34px !important;
}
.margin-bottom-35 {
  margin-bottom: 35px !important;
}
.margin-bottom-36 {
  margin-bottom: 36px !important;
}
.margin-bottom-37 {
  margin-bottom: 37px !important;
}
.margin-bottom-38 {
  margin-bottom: 38px !important;
}
.margin-bottom-39 {
  margin-bottom: 39px !important;
}
.margin-bottom-40 {
  margin-bottom: 40px !important;
}
.margin-bottom-41 {
  margin-bottom: 41px !important;
}
.margin-bottom-42 {
  margin-bottom: 42px !important;
}
.margin-bottom-43 {
  margin-bottom: 43px !important;
}
.margin-bottom-44 {
  margin-bottom: 44px !important;
}
.margin-bottom-45 {
  margin-bottom: 45px !important;
}
.margin-bottom-46 {
  margin-bottom: 46px !important;
}
.margin-bottom-47 {
  margin-bottom: 47px !important;
}
.margin-bottom-48 {
  margin-bottom: 48px !important;
}
.margin-bottom-49 {
  margin-bottom: 49px !important;
}
.margin-bottom-50 {
  margin-bottom: 50px !important;
}
.margin-bottom-51 {
  margin-bottom: 51px !important;
}
.margin-bottom-52 {
  margin-bottom: 52px !important;
}
.margin-bottom-53 {
  margin-bottom: 53px !important;
}
.margin-bottom-54 {
  margin-bottom: 54px !important;
}
.margin-bottom-55 {
  margin-bottom: 55px !important;
}
.margin-bottom-56 {
  margin-bottom: 56px !important;
}
.margin-bottom-57 {
  margin-bottom: 57px !important;
}
.margin-bottom-58 {
  margin-bottom: 58px !important;
}
.margin-bottom-59 {
  margin-bottom: 59px !important;
}
.margin-bottom-60 {
  margin-bottom: 60px !important;
}
.margin-bottom-61 {
  margin-bottom: 61px !important;
}
.margin-bottom-62 {
  margin-bottom: 62px !important;
}
.margin-bottom-63 {
  margin-bottom: 63px !important;
}
.margin-bottom-64 {
  margin-bottom: 64px !important;
}
.margin-bottom-65 {
  margin-bottom: 65px !important;
}
.margin-bottom-66 {
  margin-bottom: 66px !important;
}
.margin-bottom-67 {
  margin-bottom: 67px !important;
}
.margin-bottom-68 {
  margin-bottom: 68px !important;
}
.margin-bottom-69 {
  margin-bottom: 69px !important;
}
.margin-bottom-70 {
  margin-bottom: 70px !important;
}
.margin-bottom-71 {
  margin-bottom: 71px !important;
}
.margin-bottom-72 {
  margin-bottom: 72px !important;
}
.margin-bottom-73 {
  margin-bottom: 73px !important;
}
.margin-bottom-74 {
  margin-bottom: 74px !important;
}
.margin-bottom-75 {
  margin-bottom: 75px !important;
}
.margin-bottom-76 {
  margin-bottom: 76px !important;
}
.margin-bottom-77 {
  margin-bottom: 77px !important;
}
.margin-bottom-78 {
  margin-bottom: 78px !important;
}
.margin-bottom-79 {
  margin-bottom: 79px !important;
}
.margin-bottom-80 {
  margin-bottom: 80px !important;
}
.margin-bottom-81 {
  margin-bottom: 81px !important;
}
.margin-bottom-82 {
  margin-bottom: 82px !important;
}
.margin-bottom-83 {
  margin-bottom: 83px !important;
}
.margin-bottom-84 {
  margin-bottom: 84px !important;
}
.margin-bottom-85 {
  margin-bottom: 85px !important;
}
.margin-bottom-86 {
  margin-bottom: 86px !important;
}
.margin-bottom-87 {
  margin-bottom: 87px !important;
}
.margin-bottom-88 {
  margin-bottom: 88px !important;
}
.margin-bottom-89 {
  margin-bottom: 89px !important;
}
.margin-bottom-90 {
  margin-bottom: 90px !important;
}
.margin-bottom-91 {
  margin-bottom: 91px !important;
}
.margin-bottom-92 {
  margin-bottom: 92px !important;
}
.margin-bottom-93 {
  margin-bottom: 93px !important;
}
.margin-bottom-94 {
  margin-bottom: 94px !important;
}
.margin-bottom-95 {
  margin-bottom: 95px !important;
}
.margin-bottom-96 {
  margin-bottom: 96px !important;
}
.margin-bottom-97 {
  margin-bottom: 97px !important;
}
.margin-bottom-98 {
  margin-bottom: 98px !important;
}
.margin-bottom-99 {
  margin-bottom: 99px !important;
}
.margin-bottom-100 {
  margin-bottom: 100px !important;
}

/* Margin Left */
.margin-left-0 {
  margin-left: 0 !important;
}
.margin-left-1 {
  margin-left: 1px !important;
}
.margin-left-2 {
  margin-left: 2px !important;
}
.margin-left-3 {
  margin-left: 3px !important;
}
.margin-left-4 {
  margin-left: 4px !important;
}
.margin-left-5 {
  margin-left: 5px !important;
}
.margin-left-6 {
  margin-left: 6px !important;
}
.margin-left-7 {
  margin-left: 7px !important;
}
.margin-left-8 {
  margin-left: 8px !important;
}
.margin-left-9 {
  margin-left: 9px !important;
}
.margin-left-10 {
  margin-left: 10px !important;
}
.margin-left-15 {
  margin-left: 15px !important;
}
.margin-left-20 {
  margin-left: 20px !important;
}
.margin-left-25 {
  margin-left: 25px !important;
}
.margin-left-30 {
  margin-left: 30px !important;
}
.margin-left-35 {
  margin-left: 35px !important;
}
.margin-left-40 {
  margin-left: 40px !important;
}
.margin-left-45 {
  margin-left: 45px !important;
}
.margin-left-50 {
  margin-left: 50px !important;
}
.margin-left-55 {
  margin-left: 55px !important;
}
.margin-left-60 {
  margin-left: 60px !important;
}
.margin-left-65 {
  margin-left: 65px !important;
}
.margin-left-70 {
  margin-left: 70px !important;
}
.margin-left-75 {
  margin-left: 75px !important;
}
.margin-left-80 {
  margin-left: 80px !important;
}
.margin-left-85 {
  margin-left: 85px !important;
}
.margin-left-90 {
  margin-left: 90px !important;
}
.margin-left-95 {
  margin-left: 95px !important;
}
.margin-left-100 {
  margin-left: 100px !important;
}

/* Margin Right */
.margin-right-0 {
  margin-right: 0 !important;
}
.margin-right-1 {
  margin-right: 1px !important;
}
.margin-right-2 {
  margin-right: 2px !important;
}
.margin-right-3 {
  margin-right: 3px !important;
}
.margin-right-4 {
  margin-right: 4px !important;
}
.margin-right-5 {
  margin-right: 5px !important;
}
.margin-right-6 {
  margin-right: 6px !important;
}
.margin-right-7 {
  margin-right: 7px !important;
}
.margin-right-8 {
  margin-right: 8px !important;
}
.margin-right-9 {
  margin-right: 9px !important;
}
.margin-right-10 {
  margin-right: 10px !important;
}
.margin-right-15 {
  margin-right: 15px !important;
}
.margin-right-20 {
  margin-right: 20px !important;
}
.margin-right-25 {
  margin-right: 25px !important;
}
.margin-right-30 {
  margin-right: 30px !important;
}
.margin-right-35 {
  margin-right: 35px !important;
}
.margin-right-40 {
  margin-right: 40px !important;
}
.margin-right-45 {
  margin-right: 45px !important;
}
.margin-right-50 {
  margin-right: 50px !important;
}
.margin-right-55 {
  margin-right: 55px !important;
}
.margin-right-60 {
  margin-right: 60px !important;
}
.margin-right-65 {
  margin-right: 65px !important;
}
.margin-right-70 {
  margin-right: 70px !important;
}
.margin-right-75 {
  margin-right: 75px !important;
}
.margin-right-80 {
  margin-right: 80px !important;
}
.margin-right-85 {
  margin-right: 85px !important;
}
.margin-right-90 {
  margin-right: 90px !important;
}
.margin-right-95 {
  margin-right: 95px !important;
}
.margin-right-100 {
  margin-right: 100px !important;
}

/* Padding Top */
.padding-top-0 {
  padding-top: 0px !important;
}
.padding-top-1 {
  padding-top: 1px !important;
}
.padding-top-2 {
  padding-top: 2px !important;
}
.padding-top-3 {
  padding-top: 3px !important;
}
.padding-top-4 {
  padding-top: 4px !important;
}
.padding-top-5 {
  padding-top: 5px !important;
}
.padding-top-6 {
  padding-top: 6px !important;
}
.padding-top-7 {
  padding-top: 7px !important;
}
.padding-top-8 {
  padding-top: 8px !important;
}
.padding-top-9 {
  padding-top: 9px !important;
}
.padding-top-10 {
  padding-top: 10px !important;
}
.padding-top-11 {
  padding-top: 11px !important;
}
.padding-top-12 {
  padding-top: 12px !important;
}
.padding-top-13 {
  padding-top: 13px !important;
}
.padding-top-14 {
  padding-top: 14px !important;
}
.padding-top-15 {
  padding-top: 15px !important;
}
.padding-top-16 {
  padding-top: 16px !important;
}
.padding-top-17 {
  padding-top: 17px !important;
}
.padding-top-18 {
  padding-top: 18px !important;
}
.padding-top-19 {
  padding-top: 19px !important;
}
.padding-top-20 {
  padding-top: 20px !important;
}
.padding-top-21 {
  padding-top: 21px !important;
}
.padding-top-22 {
  padding-top: 22px !important;
}
.padding-top-23 {
  padding-top: 23px !important;
}
.padding-top-24 {
  padding-top: 24px !important;
}
.padding-top-25 {
  padding-top: 25px !important;
}
.padding-top-26 {
  padding-top: 26px !important;
}
.padding-top-27 {
  padding-top: 27px !important;
}
.padding-top-28 {
  padding-top: 28px !important;
}
.padding-top-29 {
  padding-top: 29px !important;
}
.padding-top-30 {
  padding-top: 30px !important;
}
.padding-top-31 {
  padding-top: 31px !important;
}
.padding-top-32 {
  padding-top: 32px !important;
}
.padding-top-33 {
  padding-top: 33px !important;
}
.padding-top-34 {
  padding-top: 34px !important;
}
.padding-top-35 {
  padding-top: 35px !important;
}
.padding-top-36 {
  padding-top: 36px !important;
}
.padding-top-37 {
  padding-top: 37px !important;
}
.padding-top-38 {
  padding-top: 38px !important;
}
.padding-top-39 {
  padding-top: 39px !important;
}
.padding-top-40 {
  padding-top: 40px !important;
}
.padding-top-41 {
  padding-top: 41px !important;
}
.padding-top-42 {
  padding-top: 42px !important;
}
.padding-top-43 {
  padding-top: 43px !important;
}
.padding-top-44 {
  padding-top: 44px !important;
}
.padding-top-45 {
  padding-top: 45px !important;
}
.padding-top-46 {
  padding-top: 46px !important;
}
.padding-top-47 {
  padding-top: 47px !important;
}
.padding-top-48 {
  padding-top: 48px !important;
}
.padding-top-49 {
  padding-top: 49px !important;
}
.padding-top-50 {
  padding-top: 50px !important;
}
.padding-top-51 {
  padding-top: 51px !important;
}
.padding-top-52 {
  padding-top: 52px !important;
}
.padding-top-53 {
  padding-top: 53px !important;
}
.padding-top-54 {
  padding-top: 54px !important;
}
.padding-top-55 {
  padding-top: 55px !important;
}
.padding-top-56 {
  padding-top: 56px !important;
}
.padding-top-57 {
  padding-top: 57px !important;
}
.padding-top-58 {
  padding-top: 58px !important;
}
.padding-top-59 {
  padding-top: 59px !important;
}
.padding-top-60 {
  padding-top: 60px !important;
}
.padding-top-61 {
  padding-top: 61px !important;
}
.padding-top-62 {
  padding-top: 62px !important;
}
.padding-top-63 {
  padding-top: 63px !important;
}
.padding-top-64 {
  padding-top: 64px !important;
}
.padding-top-65 {
  padding-top: 65px !important;
}
.padding-top-66 {
  padding-top: 66px !important;
}
.padding-top-67 {
  padding-top: 67px !important;
}
.padding-top-68 {
  padding-top: 68px !important;
}
.padding-top-69 {
  padding-top: 69px !important;
}
.padding-top-70 {
  padding-top: 70px !important;
}
.padding-top-71 {
  padding-top: 71px !important;
}
.padding-top-72 {
  padding-top: 72px !important;
}
.padding-top-73 {
  padding-top: 73px !important;
}
.padding-top-74 {
  padding-top: 74px !important;
}
.padding-top-75 {
  padding-top: 75px !important;
}
.padding-top-76 {
  padding-top: 76px !important;
}
.padding-top-77 {
  padding-top: 77px !important;
}
.padding-top-78 {
  padding-top: 78px !important;
}
.padding-top-79 {
  padding-top: 79px !important;
}
.padding-top-80 {
  padding-top: 80px !important;
}
.padding-top-81 {
  padding-top: 81px !important;
}
.padding-top-82 {
  padding-top: 82px !important;
}
.padding-top-83 {
  padding-top: 83px !important;
}
.padding-top-84 {
  padding-top: 84px !important;
}
.padding-top-85 {
  padding-top: 85px !important;
}
.padding-top-86 {
  padding-top: 86px !important;
}
.padding-top-87 {
  padding-top: 87px !important;
}
.padding-top-88 {
  padding-top: 88px !important;
}
.padding-top-89 {
  padding-top: 89px !important;
}
.padding-top-90 {
  padding-top: 90px !important;
}
.padding-top-91 {
  padding-top: 91px !important;
}
.padding-top-92 {
  padding-top: 92px !important;
}
.padding-top-93 {
  padding-top: 93px !important;
}
.padding-top-94 {
  padding-top: 94px !important;
}
.padding-top-95 {
  padding-top: 95px !important;
}
.padding-top-96 {
  padding-top: 96px !important;
}
.padding-top-97 {
  padding-top: 97px !important;
}
.padding-top-98 {
  padding-top: 98px !important;
}
.padding-top-99 {
  padding-top: 99px !important;
}
.padding-top-100 {
  padding-top: 100px !important;
}

/* Padding Bottom */
.padding-bottom-0 {
  padding-bottom: 0px !important;
}
.padding-bottom-1 {
  padding-bottom: 1px !important;
}
.padding-bottom-2 {
  padding-bottom: 2px !important;
}
.padding-bottom-3 {
  padding-bottom: 3px !important;
}
.padding-bottom-4 {
  padding-bottom: 4px !important;
}
.padding-bottom-5 {
  padding-bottom: 5px !important;
}
.padding-bottom-6 {
  padding-bottom: 6px !important;
}
.padding-bottom-7 {
  padding-bottom: 7px !important;
}
.padding-bottom-8 {
  padding-bottom: 8px !important;
}
.padding-bottom-9 {
  padding-bottom: 9px !important;
}
.padding-bottom-10 {
  padding-bottom: 10px !important;
}
.padding-bottom-11 {
  padding-bottom: 11px !important;
}
.padding-bottom-12 {
  padding-bottom: 12px !important;
}
.padding-bottom-13 {
  padding-bottom: 13px !important;
}
.padding-bottom-14 {
  padding-bottom: 14px !important;
}
.padding-bottom-15 {
  padding-bottom: 15px !important;
}
.padding-bottom-16 {
  padding-bottom: 16px !important;
}
.padding-bottom-17 {
  padding-bottom: 17px !important;
}
.padding-bottom-18 {
  padding-bottom: 18px !important;
}
.padding-bottom-19 {
  padding-bottom: 19px !important;
}
.padding-bottom-20 {
  padding-bottom: 20px !important;
}
.padding-bottom-21 {
  padding-bottom: 21px !important;
}
.padding-bottom-22 {
  padding-bottom: 22px !important;
}
.padding-bottom-23 {
  padding-bottom: 23px !important;
}
.padding-bottom-24 {
  padding-bottom: 24px !important;
}
.padding-bottom-25 {
  padding-bottom: 25px !important;
}
.padding-bottom-26 {
  padding-bottom: 26px !important;
}
.padding-bottom-27 {
  padding-bottom: 27px !important;
}
.padding-bottom-28 {
  padding-bottom: 28px !important;
}
.padding-bottom-29 {
  padding-bottom: 29px !important;
}
.padding-bottom-30 {
  padding-bottom: 30px !important;
}
.padding-bottom-31 {
  padding-bottom: 31px !important;
}
.padding-bottom-32 {
  padding-bottom: 32px !important;
}
.padding-bottom-33 {
  padding-bottom: 33px !important;
}
.padding-bottom-34 {
  padding-bottom: 34px !important;
}
.padding-bottom-35 {
  padding-bottom: 35px !important;
}
.padding-bottom-36 {
  padding-bottom: 36px !important;
}
.padding-bottom-37 {
  padding-bottom: 37px !important;
}
.padding-bottom-38 {
  padding-bottom: 38px !important;
}
.padding-bottom-39 {
  padding-bottom: 39px !important;
}
.padding-bottom-40 {
  padding-bottom: 40px !important;
}
.padding-bottom-41 {
  padding-bottom: 41px !important;
}
.padding-bottom-42 {
  padding-bottom: 42px !important;
}
.padding-bottom-43 {
  padding-bottom: 43px !important;
}
.padding-bottom-44 {
  padding-bottom: 44px !important;
}
.padding-bottom-45 {
  padding-bottom: 45px !important;
}
.padding-bottom-46 {
  padding-bottom: 46px !important;
}
.padding-bottom-47 {
  padding-bottom: 47px !important;
}
.padding-bottom-48 {
  padding-bottom: 48px !important;
}
.padding-bottom-49 {
  padding-bottom: 49px !important;
}
.padding-bottom-50 {
  padding-bottom: 50px !important;
}
.padding-bottom-51 {
  padding-bottom: 51px !important;
}
.padding-bottom-52 {
  padding-bottom: 52px !important;
}
.padding-bottom-53 {
  padding-bottom: 53px !important;
}
.padding-bottom-54 {
  padding-bottom: 54px !important;
}
.padding-bottom-55 {
  padding-bottom: 55px !important;
}
.padding-bottom-56 {
  padding-bottom: 56px !important;
}
.padding-bottom-57 {
  padding-bottom: 57px !important;
}
.padding-bottom-58 {
  padding-bottom: 58px !important;
}
.padding-bottom-59 {
  padding-bottom: 59px !important;
}
.padding-bottom-60 {
  padding-bottom: 60px !important;
}
.padding-bottom-61 {
  padding-bottom: 61px !important;
}
.padding-bottom-62 {
  padding-bottom: 62px !important;
}
.padding-bottom-63 {
  padding-bottom: 63px !important;
}
.padding-bottom-64 {
  padding-bottom: 64px !important;
}
.padding-bottom-65 {
  padding-bottom: 65px !important;
}
.padding-bottom-66 {
  padding-bottom: 66px !important;
}
.padding-bottom-67 {
  padding-bottom: 67px !important;
}
.padding-bottom-68 {
  padding-bottom: 68px !important;
}
.padding-bottom-69 {
  padding-bottom: 69px !important;
}
.padding-bottom-70 {
  padding-bottom: 70px !important;
}
.padding-bottom-71 {
  padding-bottom: 71px !important;
}
.padding-bottom-72 {
  padding-bottom: 72px !important;
}
.padding-bottom-73 {
  padding-bottom: 73px !important;
}
.padding-bottom-74 {
  padding-bottom: 74px !important;
}
.padding-bottom-75 {
  padding-bottom: 75px !important;
}
.padding-bottom-76 {
  padding-bottom: 76px !important;
}
.padding-bottom-77 {
  padding-bottom: 77px !important;
}
.padding-bottom-78 {
  padding-bottom: 78px !important;
}
.padding-bottom-79 {
  padding-bottom: 79px !important;
}
.padding-bottom-80 {
  padding-bottom: 80px !important;
}
.padding-bottom-81 {
  padding-bottom: 81px !important;
}
.padding-bottom-82 {
  padding-bottom: 82px !important;
}
.padding-bottom-83 {
  padding-bottom: 83px !important;
}
.padding-bottom-84 {
  padding-bottom: 84px !important;
}
.padding-bottom-85 {
  padding-bottom: 85px !important;
}
.padding-bottom-86 {
  padding-bottom: 86px !important;
}
.padding-bottom-87 {
  padding-bottom: 87px !important;
}
.padding-bottom-88 {
  padding-bottom: 88px !important;
}
.padding-bottom-89 {
  padding-bottom: 89px !important;
}
.padding-bottom-90 {
  padding-bottom: 90px !important;
}
.padding-bottom-91 {
  padding-bottom: 91px !important;
}
.padding-bottom-92 {
  padding-bottom: 92px !important;
}
.padding-bottom-93 {
  padding-bottom: 93px !important;
}
.padding-bottom-94 {
  padding-bottom: 94px !important;
}
.padding-bottom-95 {
  padding-bottom: 95px !important;
}
.padding-bottom-96 {
  padding-bottom: 96px !important;
}
.padding-bottom-97 {
  padding-bottom: 97px !important;
}
.padding-bottom-98 {
  padding-bottom: 98px !important;
}
.padding-bottom-99 {
  padding-bottom: 99px !important;
}
.padding-bottom-100 {
  padding-bottom: 100px !important;
}

/* Padding Right */
.padding-right-0 {
  padding-right: 0 !important;
}
.padding-right-5 {
  padding-right: 5px !important;
}
.padding-right-10 {
  padding-right: 10px !important;
}
.padding-right-15 {
  padding-right: 15px !important;
}
.padding-right-20 {
  padding-right: 20px !important;
}
.padding-right-25 {
  padding-right: 25px !important;
}
.padding-right-30 {
  padding-right: 30px !important;
}
.padding-right-35 {
  padding-right: 35px !important;
}
.padding-right-40 {
  padding-right: 40px !important;
}
.padding-right-45 {
  padding-right: 45px !important;
}
.padding-right-50 {
  padding-right: 50px !important;
}
.padding-right-55 {
  padding-right: 55px !important;
}
.padding-right-55 {
  padding-right: 55px !important;
}
.padding-right-55 {
  padding-right: 55px !important;
}
.padding-right-55 {
  padding-right: 55px !important;
}
.padding-right-60 {
  padding-right: 60px !important;
}
.padding-right-65 {
  padding-right: 65px !important;
}
.padding-right-70 {
  padding-right: 70px !important;
}
.padding-right-75 {
  padding-right: 75px !important;
}
.padding-right-80 {
  padding-right: 80px !important;
}
.padding-right-85 {
  padding-right: 85px !important;
}
.padding-right-90 {
  padding-right: 90px !important;
}
.padding-right-95 {
  padding-right: 95px !important;
}
.padding-right-100 {
  padding-right: 100px !important;
}

/* Padding Left */
.padding-left-0 {
  padding-left: 0 !important;
}
.padding-left-5 {
  padding-left: 5px !important;
}
.padding-left-10 {
  padding-left: 10px !important;
}
.padding-left-15 {
  padding-left: 15px !important;
}
.padding-left-20 {
  padding-left: 20px !important;
}
.padding-left-25 {
  padding-left: 25px !important;
}
.padding-left-30 {
  padding-left: 30px !important;
}
.padding-left-35 {
  padding-left: 35px !important;
}
.padding-left-40 {
  padding-left: 40px !important;
}
.padding-left-45 {
  padding-left: 45px !important;
}
.padding-left-50 {
  padding-left: 50px !important;
}
.padding-left-55 {
  padding-left: 55px !important;
}
.padding-left-55 {
  padding-left: 55px !important;
}
.padding-left-55 {
  padding-left: 55px !important;
}
.padding-left-55 {
  padding-left: 55px !important;
}
.padding-left-60 {
  padding-left: 60px !important;
}
.padding-left-65 {
  padding-left: 65px !important;
}
.padding-left-70 {
  padding-left: 70px !important;
}
.padding-left-75 {
  padding-left: 75px !important;
}
.padding-left-80 {
  padding-left: 80px !important;
}
.padding-left-85 {
  padding-left: 85px !important;
}
.padding-left-90 {
  padding-left: 90px !important;
}
.padding-left-95 {
  padding-left: 95px !important;
}
.padding-left-100 {
  padding-left: 100px !important;
}
